import moment from 'moment';

import { FilterTypes } from '../types';

export const parseQueryFilters = (filters?: FilterTypes) => {
  let resultFiltersQueryString = '';

  if (filters) {
    Object.entries(filters).map(([key, value]) => {
      if (key === 'date' && typeof value === 'object' && value.from && value.to) {
        resultFiltersQueryString += `&filter[date][from]=${value.from}&filter[date][to]=${value.to}`;
      } else if (value !== '') {
        if (Array.isArray(value) && key === 'statuses') {
          resultFiltersQueryString += `&filter[${key}][]=${value}`;
        } else {
          resultFiltersQueryString += `&filter[${key}]=${value}`;
        }
      }
    });
  }

  return resultFiltersQueryString;
};

export const parseClientBankingFilters = (filters: FilterTypes) => {
  let resultFiltersQueryString = '';

  if (filters) {
    Object.entries(filters).map(([key, value]) => {
      if (value !== '' && moment(value).isValid()) {
        resultFiltersQueryString += `&filter[lastUpdatedOn][${key}]=${value}`;
      } else if (value !== '') {
        resultFiltersQueryString += `&filter[${key}]=${value}`;
      }
    });
  }

  return resultFiltersQueryString;
};
