import { Fragment, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import AddButton from '../buttons/AddButton';
import { FormSnippetEditFormSubmitProps } from '../../views/FormSnippetsView/types';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import { QuestionnaireItemFormat, SelectOption } from '../../types';
import useWindowWidth from '../../hooks/useWindowWidth';
import Popup from '../Popup';

import Item from './Item';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 1024px)': {
      gap: '20px',
    },
  },
  divider: {
    background: theme.palette.secondary.dark,
  },
}));

interface FormSnippetBuilderProps {
  readonly booleanItems: SelectOption[];
  readonly handleUpdateBooleanItems: (items: SelectOption[]) => void;
}

const FormSnippetBuilder = ({
  booleanItems,
  handleUpdateBooleanItems,
}: FormSnippetBuilderProps) => {
  const { classes } = useStyles();
  const windowWidth = useWindowWidth();

  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<FormSnippetEditFormSubmitProps>();

  const {
    fields: items,
    append,
    remove,
    swap,
  } = useFieldArray({
    name: 'data.items',
    control,
  });

  const handleAddNewItemButtonClick = () => {
    append({
      id: uuidv4(),
      code: '',
      systemName: '',
      questionText: '',
      immutable: false,
      format: QuestionnaireItemFormat.Text,
      dependsOnItem: {
        booleanItemId: '',
        expectedValue: '',
      },
      placeholder: '',
    });
  };

  const moveItem = (indexA: number, indexB: number) => {
    swap(indexA, indexB);
  };

  const removeItem = (index?: number | number[] | undefined) => {
    remove(index);
  };

  const openWarningPopup = () => setIsOpen(true);

  return (
    <Box className={classes.container}>
      <Droppable droppableId={'snippet-box'}>
        {(provider) => {
          return (
            <Box className={classes.container} ref={provider.innerRef} {...provider.droppableProps}>
              {items.map((item, itemIdx) => {
                if (windowWidth > 1024) {
                  return (
                    <Draggable key={item.id} draggableId={`item-box-${itemIdx}`} index={itemIdx}>
                      {(provider) => {
                        return (
                          <Box
                            ref={provider.innerRef}
                            {...provider.dragHandleProps}
                            {...provider.draggableProps}
                          >
                            <Item
                              booleanItems={booleanItems}
                              itemIdx={itemIdx}
                              numberOfItems={items.length}
                              handleUpdateBooleanItems={handleUpdateBooleanItems}
                              onDeleteClick={removeItem}
                              onMove={moveItem}
                              openWarningPopup={openWarningPopup}
                            />
                          </Box>
                        );
                      }}
                    </Draggable>
                  );
                } else {
                  return (
                    <Fragment key={item.id}>
                      <Item
                        booleanItems={booleanItems}
                        itemIdx={itemIdx}
                        numberOfItems={items.length}
                        handleUpdateBooleanItems={handleUpdateBooleanItems}
                        onDeleteClick={removeItem}
                        onMove={moveItem}
                        openWarningPopup={openWarningPopup}
                      />
                      <Divider className={classes.divider} />
                    </Fragment>
                  );
                }
              })}
              {provider.placeholder}
            </Box>
          );
        }}
      </Droppable>
      <AddButton buttonText="Add New Item" handleClick={handleAddNewItemButtonClick} />

      <Popup
        isOpen={isOpen}
        headlineText={POPUP_DESCRIPTIONS.formBuilder.headlineText}
        contentText={POPUP_DESCRIPTIONS.formBuilder.contentText}
        onMainButtonClick={() => setIsOpen(false)}
      />
    </Box>
  );
};

export default FormSnippetBuilder;
