import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import { Box, Theme, Typography } from '@mui/material';

import FirmTrustAccountPopup from './FirmTrustAccountPopup';

import FirmBankingAddAccountButton from '../FirmBankingAddAccountButton';

import {
  FirmTrustAccountData,
  FirmTrustAccountSelectedTableItem,
  FirmTrustAccountSubmitFormProps,
  FirmTrustAccountTableData,
} from '../types';

import {
  deleteFirmTrustAccount,
  getFirmTrustAccount,
  getFirmTrustAccounts,
  updateFirmTrustAccount,
} from '../../../api/firmsApi/trustAccountsApi';
import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import Popup from '../../../components/Popup';
import TableContainer from '../../../components/Table/TableContainer';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { FIRM_BANKING_TRUST_ACCOUNTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import useSnackBar from '../../../hooks/useSnackBar';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, ErrorData } from '../../../types';
import FirmTrustAccountTableRow from './FirmTrustAccountTableRow';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsLabel: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
  },
  tableHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
      maxHeight: 'auto',
      gap: '12px',
    },
  },
}));

const FirmTrustAccountsView = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [trustAccounts, setTrustAccounts] = useState<FirmTrustAccountTableData[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [accountIdToDelete, setAccountIdToDelete] = useState<number | null>(null);
  const [selectedTableItem, setSelectedTableItem] = useState<FirmTrustAccountData | null>(null);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);

  const firmId = localStorage.getItem('firmId');
  const sortableFields = {
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const fetchTrustAccounts = useCallback(() => {
    getFirmTrustAccounts(
      { page: pageNumber, size: rowsPerPage, count: true, activeSortField },
      firmId as string,
    )
      .then((res) => {
        if (res.data) {
          setTotalCount && setTotalCount(res.data.totalCount as number);

          const parsedAccountData = res.data.resultSet.map((resultItem) => ({
            id: resultItem.id,
            createdAt: moment(resultItem.createdAt).format('M/D/YYYY'),
            name: resultItem.name,
            accountNumber: resultItem.accountNumber,
            routingNumber: resultItem.routingNumber,
            // caseTypes: resultItem.caseTypes,
            isVerified: resultItem.isVerified,
          }));

          setTrustAccounts(parsedAccountData);
          setIsDataLoading(false);
        }
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  }, [pageNumber, rowsPerPage, activeSortField]);

  const handleEditAccount = (accountFormData: FirmTrustAccountSubmitFormProps) => {
    if (!selectedTableItem) return;

    const submitData = {
      accountNumber: accountFormData.accountNumber,
      routingNumber: accountFormData.routingNumber,
      name: accountFormData.name,
      isVerified: accountFormData.isVerified,
    };

    updateFirmTrustAccount(selectedTableItem.id, submitData)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Trust account updated successfully.',
          type: 'success',
        }));

        fetchTrustAccounts();
        handleClosePopup();
        setSelectedTableItem(null);
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleDeleteAccount = () => {
    if (!accountIdToDelete) return;

    deleteFirmTrustAccount(accountIdToDelete)
      .then(() => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();
        fetchTrustAccounts();

        setAlert((prev) => ({
          ...prev,
          message: 'Trust account deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();

        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onEditButtonClick = (tableItem: FirmTrustAccountSelectedTableItem) => {
    handleOpenPopup();

    getFirmTrustAccount(tableItem.id)
      .then(({ data }) => {
        setSelectedTableItem({
          createdAt: data.createdAt,
          id: data.id,
          name: data.name,
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          isVerified: data.isVerified,
          wasUsedForPayment: data.wasUsedForPayment,
        });
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const onDeleteButtonClick = (accountId: number) => {
    getFirmTrustAccount(accountId)
      .then((response) => {
        if (!response.data.wasUsedForPayment) {
          setAccountIdToDelete(response.data.id);
          handleOpenDeletePopup();
        } else {
          setAlert((prev) => ({
            ...prev,
            message:
              // eslint-disable-next-line quotes
              `You cannot delete this trust account since it's connected to the cases for which settlement process has been started.`,
            type: 'info',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const handleResetFormState = () => {
    setSelectedTableItem(null);
    handleClosePopup();
  };

  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    fetchTrustAccounts();
  }, [activeSortField, pageNumber, rowsPerPage]);

  return (
    <Box mt="40px">
      <Box className={classes.tableHeadline}>
        <Typography className={classes.systemAccountsLabel}>Trust Accounts</Typography>
        <FirmBankingAddAccountButton buttonText="Add Trust Account" handleClick={handleOpenPopup} />
      </Box>

      {isDataLoading && !trustAccounts.length ? (
        <Loader colorType="warning"></Loader>
      ) : (
        <>
          {!isDataLoading && trustAccounts.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={FIRM_BANKING_TRUST_ACCOUNTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              hasBigData={trustAccounts.length > 3}
              handleSortTableData={handleSortTableData}
            >
              <FirmTrustAccountTableRow
                accountsData={trustAccounts}
                onEditButtonClick={onEditButtonClick}
                onDeleteButtonClick={onDeleteButtonClick}
              />
            </TableContainer>
          )}

          <FirmTrustAccountPopup
            isOpen={isPopupOpen}
            headlineText={`${selectedTableItem ? 'Edit' : 'Add'} Trust Account`}
            handleEditAccount={handleEditAccount}
            selectedTableItem={selectedTableItem}
            handleResetFormState={handleResetFormState}
            handleClosePopup={handleClosePopup}
            fetchTrustAccounts={fetchTrustAccounts}
          />

          <Popup
            isOpen={isDeletePopupOpen}
            onMainButtonClick={handleDeleteAccount}
            onSecondaryButtonClick={handleCloseDeletePopup}
            headlineText={POPUP_DESCRIPTIONS.trustAccountDelete.headlineText}
            contentText={POPUP_DESCRIPTIONS.trustAccountDelete.contentText}
          />
        </>
      )}
    </Box>
  );
};

export default FirmTrustAccountsView;
