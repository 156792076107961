import { Navigate } from 'react-router';

import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { token, isAuth } = useAuth();
  const role = localStorage.getItem('role');

  if (!isAuth) {
    return <Navigate to="/sign-in" replace />;
  }

  if (!token) {
    const { search, pathname } = window.location;
    sessionStorage.setItem('backUrl', btoa(pathname + search));

    return <Navigate to="/" replace />;
  }

  const baseRouteName = location.pathname.split('/')[1];
  if (baseRouteName === 'users' && role !== 'FirmAdmins') {
    return <Navigate to="/" replace />;
  }

  return token ? children : null;
};

export default ProtectedRoute;
