import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { ClientData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

interface ClientsTableDataRowProps {
  tableData: ClientData[];
}

const ClientsTableDataRow = ({ tableData }: ClientsTableDataRowProps) => {
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/clients/${String(id)}/home`);
  };

  const formattedClientsList =
    tableData &&
    tableData.map((client) => ({
      id: client.id,
      rowFields: {
        name: `${client.firstName} ${client.middleName || ''} ${client.lastName}`,
        email: client.email,
        createdAt: formatDate(client.createdAt),
        // status: client.status,
        activeCases: client.additonalInfo.activeCasesCount,
        grossSettlements: numberToUSDCurrency(client?.additonalInfo.grossSettlements),

        netSettlements: numberToUSDCurrency(client?.additonalInfo.netSettlements),

        walletBalance: '-',
      },
    }));

  return (
    <>
      {formattedClientsList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default ClientsTableDataRow;
