import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { requestsAccountTabs } from '../../constants/tabRoutes';

const RequestsAccountView = () => {
  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel tabsList={requestsAccountTabs} locationSlicePosition={2} />
      </Box>
    </>
  );
};

export default RequestsAccountView;
