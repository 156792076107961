import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Typography } from '@mui/material';

import { ReactComponent as WelcomeHeroOneIcon } from './assets/welcome-hero-1.svg';
import { ReactComponent as WelcomeHeroTwoIcon } from './assets/welcome-hero-2.svg';
import RequestDemoButton from './RequestDemoComponents/RequestDemoButton';

const useStyles = makeStyles()(() => ({
  welcomeContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    color: '#283645',
    fontFamily: 'Inter',
    gap: '14px',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '160px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
    },

    '@media (max-width: 768px)': {
      marginTop: 0,

      '& .hero-one': {
        svg: {
          width: '225px',
          height: '211px',
        },
      },
      '& .hero-two': {
        marginTop: 0,
        svg: {
          width: '250px',
          height: '211px',
        },
      },
    },

    '@media (max-width: 500px)': {
      padding: '0 20px',
    },
  },
  welcomeMiddleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '20px',
    maxWidth: '410px',
    width: '100%',
    alignItems: 'center',
    paddingTop: '60px',

    '@media (max-width: 1000px)': {
      paddingTop: 0,
      maxWidth: '688px',
    },
  },
  welcomeMiddleContainerHeadline: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '64px',
    fontSize: '48px',
    color: '#283645',
    textAlign: 'center',

    '@media (max-width: 768px)': {
      lineHeight: '43.2px',
      fontSize: '36px',
    },

    '@media (max-width: 375px)': {
      maxWidth: '335px',
    },
  },
  welcomeMiddleContainerText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    textAlign: 'center',
    color: '#5D6068',

    '@media (max-width: 768px)': {
      maxWidth: '450px',
    },
  },
  tabletWelcomeImagesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',

    '@media (max-width: 768px)': {
      '& .hero-one': {
        svg: {
          width: '225px',
          height: '211px',
        },
      },
      '& .hero-two': {
        marginTop: 0,
        svg: {
          width: '250px',
          height: '211px',
        },
      },
    },
  },
  imageContainer: {
    '@keyframes floating': {
      '0%': { transform: 'translate(0,  0px)' },
      '50%': { transform: 'translate(0, 15px)' },
      '100%': { transform: 'translate(0, -0px)' },
    },
    animation: 'floating 3s infinite  ease',
    display: 'flex',
    minWidth: 0,

    '@media (max-width: 500px)': {
      animation: 'none',
    },
  },
}));

interface WelcomeBlockProps {
  handleOpenPopup: () => void;
  windowWidth: number;
}

const WelcomeBlock = ({ handleOpenPopup, windowWidth }: WelcomeBlockProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.welcomeContainer}>
      {windowWidth > 1000 || windowWidth <= 500 ? (
        <>
          <Box className={clsx(styles.classes.imageContainer, 'hero-one')}>
            <WelcomeHeroOneIcon />
          </Box>

          <Box className={styles.classes.welcomeMiddleContainer}>
            <Typography className={styles.classes.welcomeMiddleContainerHeadline}>
              Automate, communicate and accelerate.
            </Typography>
            <Typography className={styles.classes.welcomeMiddleContainerText}>
              From intake to lien resolution, settlement to payment, <strong>v.</strong> simplifies
              the business of law.
            </Typography>
            <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="16px" />
          </Box>

          <Box className={clsx(styles.classes.imageContainer, 'hero-two')} mt="40px">
            <WelcomeHeroTwoIcon />
          </Box>
        </>
      ) : (
        <>
          <Box className={styles.classes.welcomeMiddleContainer}>
            <Typography className={styles.classes.welcomeMiddleContainerHeadline}>
              Automate, communicate and accelerate.
            </Typography>
            <Typography className={styles.classes.welcomeMiddleContainerText}>
              From intake to lien resolution, settlement to payment, <strong>v.</strong> simplifies
              the business of law.
            </Typography>
            <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="16px" />
          </Box>

          <Box className={styles.classes.tabletWelcomeImagesContainer}>
            <Box className="hero-one" display="flex" minWidth={0}>
              <WelcomeHeroOneIcon />
            </Box>
            <Box className="hero-two" mt="40px" display="flex" minWidth={0}>
              <WelcomeHeroTwoIcon />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WelcomeBlock;
