import { InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import FormTextField from '../formFields/FormTextField';
import { MAX_SEARCH_VALUE_LENGTH } from '../../constants/constants';

interface FormSearchFieldProps {
  readonly name: string;
  readonly searchValue: string;
  readonly handleChange?: (value: string) => void;
  readonly handleResetSearch?: () => void;
}

const FormSearchField = ({
  name,
  searchValue,
  handleChange,
  handleResetSearch,
}: FormSearchFieldProps) => {
  return (
    <FormTextField
      name={name}
      label="Search"
      maxWidth="320px"
      handleChange={handleChange}
      inputProps={{
        autoComplete: 'new-password',
        maxLength: MAX_SEARCH_VALUE_LENGTH,
        form: {
          autoComplete: 'off',
        },
      }}
      InputProps={{
        endAdornment: searchValue ? (
          <InputAdornment position="end">
            <IconButton onClick={handleResetSearch} sx={{ padding: 0, color: 'white' }}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <SearchIcon sx={{ color: 'white' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormSearchField;
