import api from '../service/api.service';

import {
  PaginationQueryParams,
  QuestionnaireShortData,
  QuestionnaireForm,
  QuestionnaireData,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';

const BASE_URL_V1 = '/v1/questionnaires';
const BASE_URL_V2 = '/v2/questionnaires';

export const getGlobalQuestionnaires = async ({
  size,
  page,
  count,
  match,
  filters,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const targetUrl = `${BASE_URL_V1}/global?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}`;

  return await api.get<{ resultSet: Array<QuestionnaireShortData>; totalCount: number | null }>(
    targetUrl,
  );
};

export const getQuestionnaires = async ({
  size,
  page,
  count,
  match,
  filters,
  includeGlobal,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const targetUrl = `${BASE_URL_V1}?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }&includeGlobal=${includeGlobal}${queryFilters || ''}`;

  return await api.get<{ resultSet: Array<QuestionnaireShortData>; totalCount: number | null }>(
    targetUrl,
  );
};

export const getQuestionnaire = async (id: string) => {
  const targetUrl = `${BASE_URL_V2}/${id}`;

  return await api.get<QuestionnaireData>(targetUrl);
};

export const updateQuestionnaire = async (
  id: string,
  data: { form?: QuestionnaireForm; name?: string },
) => {
  const targetUrl = `${BASE_URL_V2}/${id}`;

  return await api.put<QuestionnaireData>(targetUrl, data);
};

export const createQuestionnaire = async (data: {
  form: QuestionnaireForm;
  firmId?: number | null;
  name: string;
  caseTypeId: number;
}) => {
  return await api.post<QuestionnaireData>(BASE_URL_V2, data);
};

export const deleteQuestionnaire = async (id: string) => {
  const targetUrl = `${BASE_URL_V1}/${id}`;

  return await api.delete<void>(targetUrl);
};
