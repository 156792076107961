import AuthProvider from './providers/AuthProvider';
import PageTitleProvider from './providers/PageTitleProvider';

import MainLayoutView from './views/MainLayoutView/MainLayoutView';

function App() {
  return (
    <AuthProvider>
      <PageTitleProvider>
        <MainLayoutView />
      </PageTitleProvider>
    </AuthProvider>
  );
}

export default App;
