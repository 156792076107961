import { useEffect, useState } from 'react';
import { Box, Typography, Theme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';

import FormSearchField from '../formFields/FormSearchField';
import ViewCommonButton from '../buttons/ViewCommonButton';
import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import usePagination from '../../hooks/usePagination';

const useStyles = makeStyles()((theme: Theme) => ({
  middleScreenActions: {
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'space-between',

    '@media (max-width: 950px)': {
      flexDirection: 'column-reverse',
      gap: '32px',
    },
    '@media (max-width: 550px)': {
      marginBottom: '12px',
      marginRight: 0,
      gap: '12px',
    },
  },
  filterButtonText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.common.white,
  },
  tableActionsResponsive: {
    gap: '20px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  toggleFiltersWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    svg: {
      color: theme.palette.common.white,
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    '@media (max-width: 550px)': {
      justifyContent: 'space-between',
      borderRadius: '12px',
      backgroundColor: theme.palette.primary.light,
      padding: '12px',
    },
  },
}));

interface TableActionsProps {
  createLink?: string;
  hasParams?: boolean;
  match?: string;
  searchDebounce?: number;
  showFilters?: boolean;
  exportList?: () => void;
  setMatch?: (arg: string) => void;
  setShowFilters?: (arg: boolean) => void;
}

const TableActions = ({
  createLink,
  hasParams,
  match,
  searchDebounce = 300,
  showFilters,
  exportList,
  setMatch,
  setShowFilters,
}: TableActionsProps) => {
  const styles = useStyles();
  const { setPageNumber } = usePagination();

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchQuery: '',
    },
  });

  const { setValue, reset } = formMethods;

  const handleSearchCase = (value: string) => {
    if (value.length > 2) {
      setPageNumber && setPageNumber(1);
      setMatch && setMatch(value);
    } else {
      setMatch && setMatch('');
    }
  };

  const handleResetSearch = () => {
    setPageNumber && setPageNumber(1);
    setMatch && setMatch('');
    setSearchInputValue('');
    reset();
  };

  const handleToggleShowFilters = () => {
    setShowFilters && setShowFilters(!showFilters);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSearchCase(searchInputValue.trimEnd());
    }, searchDebounce);

    return () => clearTimeout(timeout);
  }, [searchInputValue]);

  useEffect(() => {
    !match?.length && handleResetSearch();
  }, [match]);

  return (
    <Box className={styles.classes.middleScreenActions} mt={hasParams ? '38px' : 0}>
      <Box className={styles.classes.searchContainer}>
        {setMatch && (
          <Box>
            <FormProvider {...formMethods}>
              <form style={{ width: '100%' }}>
                <FormSearchField
                  name="searchQuery"
                  searchValue={searchInputValue}
                  handleChange={(value) => {
                    setValue('searchQuery', validateTextFieldValue(value));
                    setSearchInputValue(validateTextFieldValue(value));
                  }}
                  handleResetSearch={handleResetSearch}
                />
              </form>
            </FormProvider>
          </Box>
        )}

        {setShowFilters && (
          <Box onClick={handleToggleShowFilters} className={styles.classes.toggleFiltersWrapper}>
            <FilterListIcon />
            <Typography className={styles.classes.filterButtonText}>Filters</Typography>
          </Box>
        )}
      </Box>

      <Box className={styles.classes.tableActionsResponsive} display="flex">
        {!!createLink?.length && <ViewCommonButton path={createLink} type="create" />}

        {exportList ? <ViewCommonButton onClick={exportList} type="export" /> : null}
      </Box>
    </Box>
  );
};

export default TableActions;
