import { CognitoUserPool } from 'amazon-cognito-identity-js';

const CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIEND_ID;
const USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

const poolData = {
  UserPoolId: USER_POOL_ID as string,
  ClientId: CLIENT_ID as string,
};

export const cognitoPool = new CognitoUserPool(poolData);
