import Timeline from '../../../components/Timeline';
import { useEffect, useState } from 'react';
import { TimelineType } from '../../../types';
import { useParams } from 'react-router';
import { getSettlementTimeline } from '../../../api/paymentsApi/settlementsApi';

const SettlementsTimeline = () => {
  const { settlementId } = useParams<string>();
  const [settlementTimeline, setSettlementTimeline] = useState<TimelineType>([]);

  useEffect(() => {
    getSettlementTimeline(settlementId as string).then((res) => {
      if (res.data) {
        setSettlementTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={settlementTimeline} />;
};

export default SettlementsTimeline;
