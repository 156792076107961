import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Theme, Button, Typography, Box } from '@mui/material';
import { ReactElement } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '12px',
    width: '100%',
    padding: '10px 16px',
    minHeight: '48px',
  },
  buttonDisabled: {
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  buttonActive: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
}));

interface FormSubmitButtonProps {
  disabled: boolean;
  buttonText: string;
  buttonIcon?: ReactElement;
  onClick: () => void;
  isLoading?: boolean;
  fontFamily?: string;
}

const FormSubmitButton = ({ disabled, buttonText, onClick, fontFamily }: FormSubmitButtonProps) => {
  const styles = useStyles();

  return (
    <Button
      disableRipple
      className={clsx(
        styles.classes.default,
        disabled ? styles.classes.buttonDisabled : styles.classes.buttonActive,
      )}
      variant="text"
      disabled={disabled}
      onClick={onClick}
      type={'submit'}
    >
      <Box display="flex" alignItems="center" gap="8px">
        <Typography fontWeight="500" fontSize="16px" lineHeight="16px" fontFamily={fontFamily}>
          {buttonText}
        </Typography>
      </Box>
    </Button>
  );
};

export default FormSubmitButton;
