import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import { getFormSnippets } from '../api/questionnairesApi/formSnippetsApi';
import { ErrorData, FormSnippetShortData, PaginationQueryParams } from '../types';
import useSnackBar from '../hooks/useSnackBar';

import FormTextField from './formFields/FormTextField';
import FormToggleField from './formFields/FormToggleField';
import Loader from './Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',

      '&::-webkit-scrollbar': {
        width: '5px',
        borderRadius: '20px',
        background: theme.palette.secondary.dark,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        background: theme.palette.secondary.dark,
      },
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    padding: '0px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  dialogContent: {
    overflowY: 'hidden',
    padding: '32px 0px 0px 0px',
  },
  toggleWrapper: {
    marginLeft: '2px',
  },
  emptyDialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '300px',
    overflowY: 'auto',

    '@media (max-width: 550px)': {
      paddingRight: '10px',
    },

    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  searchListItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(144, 202, 249, 0.08)',
    },
  },
  activeSearchListItem: {
    backgroundColor: 'rgba(144, 202, 249, 0.08)',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '0px',
    marginTop: '32px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  buttonDefault: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  selectButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface SearchFormSnippetPopupProps {
  readonly firmId: string | null;
  readonly isOpen: boolean;
  readonly onMainButtonClick: (selectedFormSnippet: FormSnippetShortData) => void;
  readonly onSecondaryButtonClick: () => void;
}

interface FormSubmitProps {
  includeGlobal: boolean;
  name: string;
}

const SearchFormSnippetPopup = ({
  firmId,
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: SearchFormSnippetPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [snippets, setSnippets] = useState<Array<FormSnippetShortData>>([]);
  const [selectedSnippet, setSelectedSnippet] = useState<FormSnippetShortData | null>(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 10;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { includeGlobal: false, name: '' },
  });

  const { reset, setValue, watch } = formMethods;

  const searchValue = watch('name');
  const includeGlobal = watch('includeGlobal');

  const getFetchParams = ({
    page,
    count,
    match,
    includeGlobal,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
    includeGlobal?: boolean;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match || '',
      includeGlobal,
    };

    return queryParams;
  };

  const handleClosePopup = () => {
    onSecondaryButtonClick();
  };

  const handleSelectButtonClick = () => {
    onMainButtonClick(selectedSnippet as FormSnippetShortData);
  };

  const fetchFormSnippets = (queryParams: PaginationQueryParams) => {
    if (queryParams.page === 1) setIsLoading(true);
    else setIsFetching(true);

    getFormSnippets({
      page: queryParams.page,
      size: PAGE_SIZE,
      count: queryParams.count,
      match: queryParams.match,
      ...(firmId && { includeGlobal: queryParams.includeGlobal }),
      ...(firmId && { filters: { firmId } }),
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setSnippets(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setSnippets(Array.from(new Set([...snippets, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleSearchInputChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      fetchFormSnippets(getFetchParams({ match: value, includeGlobal }));
    }
  };

  const loadMoreResults = () => {
    if (totalCount !== snippets.length) {
      fetchFormSnippets(getFetchParams({ page: page + 1, match: searchValue, includeGlobal }));
    }
  };

  const handleToggle = (value: boolean) => {
    fetchFormSnippets(
      getFetchParams({
        match: searchValue,
        includeGlobal: value,
      }),
    );
  };

  const handleSelectItem = (item: FormSnippetShortData) => {
    setSelectedSnippet(item);
  };

  const handleScroll = (event: BaseSyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const getDialogContent = () => {
    if (isLoading) {
      return <Loader colorType="info" />;
    }

    if (!snippets.length) {
      return <Typography fontSize="20px">{'No results.'}</Typography>;
    }

    return (
      <Box className={classes.searchResultsBlock} onScroll={handleScroll}>
        {snippets.map((snippet) => (
          <Box
            key={snippet.id}
            className={clsx({
              [classes.searchListItem]: true,
              [classes.activeSearchListItem]: snippet.id === selectedSnippet?.id,
            })}
            onClick={() => handleSelectItem(snippet)}
          >
            <Typography className={classes.text}>{snippet.name}</Typography>
          </Box>
        ))}

        {isFetching && (
          <Box className={classes.searchListItem}>
            <Loader colorType="info" size={25} />
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (!isOpen) return;

    reset();
    setSelectedSnippet(null);
    fetchFormSnippets(getFetchParams({}));
  }, [isOpen]);

  return (
    <Dialog open={isOpen} className={classes.dialogContainer} onClose={handleClosePopup} fullWidth>
      <DialogTitle className={classes.dialogTitle}>{'Form Snippet'}</DialogTitle>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          {firmId && (
            <Box className={classes.toggleWrapper}>
              <FormToggleField
                name="includeGlobal"
                label="Include Global"
                customHandleChange={handleToggle}
              />
            </Box>
          )}
          <FormTextField name="name" label="Name" handleChange={handleSearchInputChange} />
        </form>
      </FormProvider>
      <DialogContent
        className={clsx({
          [classes.dialogContent]: true,
          [classes.emptyDialogContent]: isLoading || !snippets.length,
        })}
      >
        {getDialogContent()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.buttonDefault, classes.selectButton)}
          onClick={handleSelectButtonClick}
          disabled={!selectedSnippet}
        >
          {'Save'}
        </Button>
        <Button
          className={clsx(classes.buttonDefault, classes.cancelButton)}
          onClick={handleClosePopup}
        >
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchFormSnippetPopup;
