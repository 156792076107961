import axios from 'axios';
import { cognitoPool } from '../../views/SignInView/cognitoPool';
import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const refreshAccessTokenCognito = (cognitoUser: CognitoUser, refreshToken: string) => {
  return new Promise<string>((resolve, reject) => {
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        reject(err);
      } else {
        const accessToken = session.getAccessToken().getJwtToken();
        localStorage.setItem('access_token', accessToken);
        resolve(accessToken);
      }
    });
  });
};

const refreshAccessToken = async (refreshToken: string, email: string): Promise<string> => {
  const response = await instance.put('/v1/fo/auth/refresh-token', {
    refreshToken: refreshToken,
    email,
  });

  const newAccessToken = response.data.accessToken;
  localStorage.setItem('access_token', newAccessToken);

  return newAccessToken;
};

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const cognitoUser = cognitoPool.getCurrentUser();
          const refreshToken = localStorage.getItem('refresh_token');
          const email = localStorage.getItem('email') || '';

          if (cognitoUser && refreshToken) {
            try {
              await refreshAccessTokenCognito(cognitoUser, refreshToken);
            } catch (error) {
              console.error('Token cognito refresh failed:', error);
            }
          } else if (refreshToken) {
            try {
              await refreshAccessToken(refreshToken, email);
            } catch (error) {
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
            }
          } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
          }

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 401) {
        const { search, pathname } = window.location;
        sessionStorage.setItem('backUrl', btoa(pathname + search));
        window.location.href = '/sign-in';

        return instance(originalConfig);
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
