import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Tabs, Tab, Theme } from '@mui/material';

import { getTabIdByLocation } from '../helpers/getTabIdByLocation';
import StatusPillBar from './StatusPillBar';
import { casesAccountTabsType } from '../constants/tabRoutes';

const useStyles = makeStyles()((theme: Theme) => ({
  defaultTab: {
    marginBottom: '12px',

    '& .MuiTabs-scroller': {
      overflowX: 'auto !important' as 'scroll',

      '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 20,
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    '& .MuiTab-root': {
      color: theme.palette.text.disabled,

      '&.Mui-selected': {
        color: theme.palette.common.white,
      },
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.info.main,
    },

    a: {
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: 'Inter',
      textTransform: 'capitalize',
    },
  },
}));

interface TabsPanelProps {
  tabsList: Array<casesAccountTabsType>;
  locationSlicePosition?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contextData?: { [key: string]: any };
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

const TabsPanel = ({ tabsList, locationSlicePosition, contextData }: TabsPanelProps) => {
  const styles = useStyles();

  const location = useLocation();

  const [selectedTabId, setSelectedTabId] = useState(
    getTabIdByLocation(tabsList, location.pathname),
  );

  const handleActiveTabChange = (newValue: number) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    handleActiveTabChange(getTabIdByLocation(tabsList, location.pathname, locationSlicePosition));
  }, [location.pathname]);

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" flexDirection="column">
        <Box display="flex" width="100%" flexDirection="column">
          <Tabs className={styles.classes.defaultTab} value={selectedTabId}>
            {tabsList.map((tab, idx) => (
              <Tab
                key={tab.path}
                component={Link}
                label={tab.name}
                to={tab.path}
                disabled={tab.isDraft}
                style={{ opacity: `${tab.isDraft ? 0.3 : 1}` }}
                {...a11yProps(idx)}
              />
            ))}
          </Tabs>
        </Box>
        {contextData?.status && <StatusPillBar status={contextData?.status} />}
      </Box>
      <Outlet
        context={{
          entityData: contextData,
        }}
      />
    </Box>
  );
};

export default TabsPanel;
