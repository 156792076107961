import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, LinearProgress } from '@mui/material';

import App from './App';
import theme from './theme';

import './index.css';
import SnackBarProvider from './providers/SnackBarProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={<LinearProgress />}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackBarProvider>
          <App />
        </SnackBarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Suspense>,
);
