import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

// import ArchiveIcon from '@mui/icons-material/Archive';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { CreateUserFormSubmitProps } from './types';

import { getUser, resetUserAccount, updateUser } from '../../api/usersApi/usersApi';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormTextField from '../../components/formFields/FormTextField';
import Loader from '../../components/Loader';
import { ROLE_OPTIONS } from '../../constants/constants';
import { CREATE_USER_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { createUserFormSchema } from '../../helpers/formSchemas/formSchemas';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import CommonButton from '../../components/buttons/CommonButton';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 800px)': {
      flexBasis: '100%',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 1000px)': {
      flexBasis: '416px',
    },

    '@media (max-width: 800px)': {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 1000px)': {
      flexBasis: 0,
    },
  },
  formActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '200px',

    '@media (max-width: 800px)': {
      flexBasis: 0,
    },
  },
}));

const UsersEditFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { lawyerId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createUserFormSchema),
    defaultValues: CREATE_USER_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: CreateUserFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
    };

    updateUser(lawyerId as string, submitData)
      .then((res) => {
        if (res.data) {
          setIsFormSubmitting(false);
          navigate('/users');

          setAlert((prev) => ({
            ...prev,
            message: 'User account updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleResetUserAccount = () => {
    handleClosePopup();

    resetUserAccount(lawyerId as string)
      .then((res) => {
        if (res.status === 204) {
          navigate('/users');

          setAlert((prev) => ({
            ...prev,
            message: 'User account has been reset successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response?.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    getUser(lawyerId as string).then((res) => {
      if (res.data) {
        reset({
          id: res.data.id,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          phone: res.data.phone,
          role: res.data.role,
          firmId: res.data.firm.id,
        });

        setPageTitle && setPageTitle(`${res.data.firstName} ${res.data.lastName}`);
        setIsDataLoading(false);
      }
    });

    return () => setPageTitle && setPageTitle('');
  }, [lawyerId]);

  return (
    <>
      {isDataLoading ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.pageContainer}>
            <FormProvider {...formMethods}>
              <form className={styles.classes.formContainer}>
                <Box className={styles.classes.formInfoContainer}>
                  <Box className={styles.classes.formInnerWrapper}>
                    <Box className={styles.classes.formWrapper}>
                      <Box className={styles.classes.formInfoBlock}>
                        <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                        <Box mt="16px">
                          <FormCopyTextField entityName="User" name="id" label="Id" isReadOnly />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="firstName" label="First Name *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="lastName" label="Last Name *" />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Credentials
                        </Typography>

                        <Box mt="16px">
                          <FormTextField name="email" label="Email Address *" disabled />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="phone" label="Mobile Number *" disabled />
                        </Box>
                      </Box>

                      <Box className={styles.classes.formInfoBlock}>
                        <Typography className={styles.classes.fieldHeadline}>Role</Typography>

                        <Box mt="16px">
                          <FormSelectField
                            options={ROLE_OPTIONS}
                            name="role"
                            label="Role *"
                            width="100%"
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" width="100%" justifyContent="center">
                      <Box
                        display="flex"
                        mt="32px"
                        maxWidth="416px"
                        flexDirection="column"
                        gap="16px"
                        width="100%"
                      >
                        <FormSubmitButton
                          disabled={!isValid || !isDirty || isFormSubmitting}
                          buttonIcon={<SaveIcon />}
                          buttonText="Save"
                          onClick={handleSubmit(onSubmitForm)}
                        />
                        <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                      </Box>
                    </Box>
                  </Box>

                  <Box className={styles.classes.formActionsWrapper}>
                    <Typography className={styles.classes.fieldHeadline}>Actions</Typography>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="reset account"
                        buttonIcon={<LockResetIcon />}
                        onButtonClick={handleOpenPopup}
                      />
                    </Box>
                    {/* <Box mt="16px">
                      <CommonButton
                        type="error"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box> */}
                  </Box>
                </Box>
              </form>
            </FormProvider>
            <Popup
              isOpen={isPopupOpen}
              headlineText={POPUP_DESCRIPTIONS.lawyersPage.headlineText}
              contentText={POPUP_DESCRIPTIONS.lawyersPage.contentText}
              onSecondaryButtonClick={handleClosePopup}
              onMainButtonClick={handleResetUserAccount}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default UsersEditFormContainer;
