import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

interface FindCaseButtonProps {
  onButtonClick: () => void;
}

const FindCaseButton = ({ onButtonClick }: FindCaseButtonProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="4px"
      bgcolor={theme.palette.info.main}
      padding="10px 16px"
      borderRadius="12px"
      maxWidth="220px"
      boxSizing="border-box"
      justifyContent="center"
      height="47px"
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={onButtonClick}
    >
      <IconButton
        disableRipple
        sx={{
          padding: 0,
          color: theme.palette.common.white,
        }}
      >
        <ContentPasteSearchIcon />
      </IconButton>
      <Typography
        textTransform="uppercase"
        fontSize="14px"
        fontWeight="500"
        color={theme.palette.common.white}
      >
        Find case
      </Typography>
    </Box>
  );
};

export default FindCaseButton;
