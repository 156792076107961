import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputLabelProps, TextField, Theme, IconButton } from '@mui/material';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { useState } from 'react';

export const useStyles = makeStyles<{ maxWidth?: string }>()((theme: Theme, { maxWidth }) => ({
  defaultTextField: {
    ...(maxWidth && { maxWidth }),
    background: 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',
    input: {
      padding: '12px 16px',
      color: theme.palette.common.white,

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        WebkitTextFillColor: theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.disabled,
      borderRadius: '12px',

      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.error.main,
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        borderRadius: '12px',
      },

      '&:hover': {
        border: 'none',

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
          borderColor: 'none',
        },
      },
    },

    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,

      '&[data-shrink="false"]': {
        top: '-3px',
      },

      '&[data-shrink="true"]': {
        color: theme.palette.text.disabled,
      },

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: '14px',
    },
  },
  inputIcon: {
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
}));

interface FormPasswordFieldProps {
  name: string;
  label: string;
  maxWidth?: string;
  disabled?: boolean;
  inputLabelProps?: Partial<InputLabelProps>;
}

const FormPasswordField = ({
  name,
  label,
  disabled,
  maxWidth,
  inputLabelProps,
}: FormPasswordFieldProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const passwordValue = watch(name);

  const styles = useStyles({ maxWidth });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const helperText = errors[name] && errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            fullWidth
            className={clsx(styles.classes.defaultTextField)}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            label={label}
            onChange={(e) => onChange(validateTextFieldValue(e.target.value))}
            helperText={helperText as string}
            value={value}
            error={!!errors[name]}
            disabled={disabled}
            InputLabelProps={inputLabelProps}
            InputProps={{
              endAdornment: (
                <IconButton
                  disableRipple
                  disabled={!passwordValue}
                  className={styles.classes.inputIcon}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default FormPasswordField;
