import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ReactNode, createContext, useState } from 'react';
import { useNavigate } from 'react-router';

import { cognitoPool } from '../views/SignInView/cognitoPool';

/* eslint-disable  @typescript-eslint/no-explicit-any */

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  refreshToken: null,
  onLogOut: undefined,
  handleSendForgotPasswordEmail: undefined,
  handleResetPassword: undefined,
  isAuth: false,
});

export function getSession() {
  const cognitoUser = cognitoPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error('No user found'));
      return;
    }
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    });
  });
}

interface AuthContextProps {
  token: string | null;
  refreshToken: string | null;
  isAuth: boolean;
  changeAuth?: (isAuth: boolean) => void;
  onLogOut?: () => void;
  handleSendForgotPasswordEmail?: (email: string) => Promise<any>;
  handleResetPassword?: (code: string, newPassword: string) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const handleSendForgotPasswordEmail = (email: string) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: cognitoPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: function () {
          localStorage.setItem('username', email);
          resolve('new password has been sent');
          navigate('resetPassword');
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  const handleResetPassword = (code: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
      const username = localStorage.getItem('username');

      if (!username) {
        return reject('Username not found');
      }

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoPool,
      });

      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          resolve('Password has been reset successfully.');
          navigate('/sign-in');
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsAuth(false);
    navigate('/sign-in');
  };

  const changeAuth = (isAuth: boolean) => {
    setIsAuth(isAuth);
  };

  const value = {
    token: localStorage.getItem('access_token'),
    refreshToken: localStorage.getItem('refresh_token'),
    onLogOut: handleLogOut,
    handleSendForgotPasswordEmail,
    handleResetPassword,
    isAuth,
    changeAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
