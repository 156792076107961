import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    fontWeight: '500',
    fontFamily: 'Inter',
    padding: '7px',
    width: '100%',
    borderRadius: '4px',
  },
  buttonsGroupLabel: {
    color: theme.palette.secondary.main,
    textAlign: 'start',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '3px',
  },
  buttonDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
    background: 'rgba(255, 255, 255, 0.12)',
  },
}));

interface ClientActivationsButtonGroupProps {
  buttonGroupLabel: string;
  buttonsList: { buttonLabel: string; buttonType: string }[];
  activeButtonName?: string;
  maxWidth?: string;
}

const ClientActivationsButtonGroup = ({
  buttonGroupLabel,
  buttonsList,
  activeButtonName,
  maxWidth,
}: ClientActivationsButtonGroupProps) => {
  const theme = useTheme();
  const styles = useStyles();

  const buttonColors = {
    info: theme.palette.info.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography className={styles.classes.buttonsGroupLabel}>{buttonGroupLabel}</Typography>
      <Box className={styles.classes.buttonsWrapper}>
        {buttonsList.map((button, idx) => (
          <Box
            maxWidth={maxWidth}
            className={clsx(
              styles.classes.buttonContainer,
              !activeButtonName && styles.classes.buttonDisabled,
            )}
            key={idx}
            bgcolor={buttonColors[button.buttonType as keyof typeof buttonColors]}
          >
            {button.buttonLabel}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ClientActivationsButtonGroup;
