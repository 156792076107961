import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from 'tss-react/mui';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import RequestDemoForm from './RequestDemoForm';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '32px',
      maxWidth: '480px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  popupTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '8px',
  },
  popupContentContainer: {
    padding: 0,
    paddingTop: '10px',
  },
  popupSecondaryText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#C3C4C8',
    marginBottom: '22px',
  },
}));

interface RequestDemoPopupProps {
  isOpen: boolean;
  onClose: () => void;
  setIsSuccessPopupOpen: Dispatch<SetStateAction<boolean>>;
}

const RequestDemoPopup = ({ isOpen, onClose, setIsSuccessPopupOpen }: RequestDemoPopupProps) => {
  const styles = useStyles();

  return (
    <Dialog open={isOpen} className={styles.classes.popupContainer} onClose={onClose}>
      <Box display="flex" justifyContent="flex-end" height="24px">
        <IconButton
          disableRipple
          onClick={onClose}
          sx={{
            color: 'white',
            width: '24px',
            height: '24px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle className={styles.classes.popupTitle}>Request a Demo</DialogTitle>
      <Typography className={styles.classes.popupSecondaryText}>
        Drive exceptional outcomes for your clients. Complete the form below to get connected with a
        v. product specialist.
      </Typography>
      <DialogContent className={styles.classes.popupContentContainer}>
        <RequestDemoForm onClose={onClose} setIsSuccessPopupOpen={setIsSuccessPopupOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default RequestDemoPopup;
