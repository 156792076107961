import { Dispatch, SetStateAction } from 'react';
import { ActiveSortField, SortableFieldsObject } from '../types';

export const handleSortTableData = (
  sortField: string,
  sortableFields: SortableFieldsObject,
  activeSortField: ActiveSortField | null,
  setActiveSortField?: Dispatch<SetStateAction<ActiveSortField | null>>,
) => {
  let currentFieldOrder = 'asc';

  if (!activeSortField) {
    setActiveSortField &&
      setActiveSortField({
        order: currentFieldOrder,
        fieldName: sortableFields[sortField].fieldName,
      });
  }

  if (activeSortField) {
    if (activeSortField.fieldName !== sortField) {
      setActiveSortField &&
        setActiveSortField({
          order: currentFieldOrder,
          fieldName: sortableFields[sortField].fieldName,
        });
    } else {
      if (activeSortField.order === 'asc') {
        currentFieldOrder = 'desc';
        setActiveSortField &&
          setActiveSortField({
            order: currentFieldOrder,
            fieldName: sortableFields[sortField].fieldName,
          });
      }
      if (activeSortField.order === 'desc') {
        setActiveSortField && setActiveSortField(null);
      }
    }
  }
};
