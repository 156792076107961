export const printCheck = (inlineStyles: string) => {
  const printContent = document.getElementById('printContent') as HTMLElement;
  const WinPrint = window.open('', '', 'width=600,height=auto,toolbar=0,scrollbars=0,status=0');

  WinPrint?.document.write(
    '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />',
  );

  WinPrint?.document.write(`<style>${inlineStyles}</style>`);

  WinPrint?.document.write(printContent.innerHTML);

  WinPrint?.document.close();

  WinPrint?.setTimeout(function () {
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }, 100);
};
