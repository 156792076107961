import { useFormContext } from 'react-hook-form';

import { formatWithCurrencyMask } from '../../helpers/formatWithCurrencyMask';

import FormTextField from './FormTextField';

interface FormCurrencyFieldProps {
  readonly digitsAfterDecimal?: number;
  readonly disabled?: boolean;
  readonly label: string;
  readonly name: string;
  readonly valueDefinition?: string;
}

const FormCurrencyField = ({
  digitsAfterDecimal,
  disabled,
  label,
  name,
  valueDefinition,
}: FormCurrencyFieldProps) => {
  const { setValue } = useFormContext();

  const handleValueChange = (value: string) => {
    setValue(name, formatWithCurrencyMask(value, digitsAfterDecimal), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <FormTextField
      disabled={disabled}
      label={label}
      name={name}
      valueDefinition={valueDefinition || '$'}
      handleChange={handleValueChange}
    />
  );
};

export default FormCurrencyField;
