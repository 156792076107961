import { Dispatch, SetStateAction, createContext, useState } from 'react';

export const PageTitleContext = createContext<PageTitleContextProps>({
  setPageTitle: undefined,
  pageTitle: '',
});

interface PageTitleContextProps {
  setPageTitle?: Dispatch<SetStateAction<string>>;
  pageTitle: string;
}

interface PageTitleProviderProps {
  children: JSX.Element;
}

const PageTitleProvider = ({ children }: PageTitleProviderProps) => {
  const [pageTitle, setPageTitle] = useState<string>('');

  const value = {
    pageTitle,
    setPageTitle,
  };

  return <PageTitleContext.Provider value={value}>{children}</PageTitleContext.Provider>;
};

export default PageTitleProvider;
