import { ExpectedAnswer } from '../../../constants/constants';
import { QuestionnaireForm, QuestionnaireItemFormat } from '../../../types';
import { QuestionnaireSubmitForm } from '../../../views/QuestionnairesView/types';

export const mapQuestionnaireQueryResult = (form: QuestionnaireForm) => {
  const mappedForm: QuestionnaireSubmitForm = {
    sections: form.sections.map((section) => {
      return {
        title: section.title,
        items: section.items.map((item) => {
          return {
            id: item.id,
            code: item.code,
            format: item.format,
            systemName: item.systemName,
            questionText: item.questionText,
            immutable: item?.immutable || false,
            dependsOnItem: item?.dependsOnItem?.booleanItemId
              ? {
                  booleanItemId: item.dependsOnItem.booleanItemId,
                  expectedValue: item.dependsOnItem.expectedValue
                    ? ExpectedAnswer.Yes
                    : ExpectedAnswer.No,
                }
              : {
                  booleanItemId: '',
                  expectedValue: '',
                },
            ...(item.format === QuestionnaireItemFormat.DateRange && {
              allowPartial: item?.allowPartial || false,
            }),
            ...(item.format === QuestionnaireItemFormat.Text && {
              placeholder: item?.placeholder || '',
            }),
          };
        }),
        immutable: section?.immutable || false,
      };
    }),
  };

  return mappedForm;
};
