import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { formSnippetEditFormSchema } from '../helpers/formSchemas/formSchemas';
import { getFormSnippet } from '../api/questionnairesApi/formSnippetsApi';
import { ErrorData, FormSnippetShortData, SelectOption } from '../types';
import { FormSnippetEditFormSubmitProps } from '../views/FormSnippetsView/types';
import { FORM_SNIPPET_BUILDER_DEFAULT_VALUES } from '../helpers/formSchemas/formDefaultValues';
import { extractBooleanItems } from '../views/FormSnippetsView/helpers/extractBooleanItems';
import { mapFormSnippetQueryResult } from '../views/FormSnippetsView/helpers/mapFormSnippetQueryResult';
import useSnackBar from '../hooks/useSnackBar';

import FormSnippetBuilder from './FormSnippetBuilder/FormSnippetBuilder';
import FormTextField from './formFields/FormTextField';
import Loader from './Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      height: '90%',
      borderRadius: '16px',

      '@media (max-width: 550px)': {
        height: 'auto',
      },
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 0px 0px',
    gap: '20px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  buttonDefault: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  saveButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface FormSnippetBuilderPopupProps {
  readonly isOpen: boolean;
  readonly data: FormSnippetShortData | null;
  readonly isFormSubmitting?: boolean;
  readonly onMainButtonClick: (values: FormSnippetEditFormSubmitProps) => void;
  readonly onSecondaryButtonClick: () => void;
}

const FormSnippetBuilderPopup = ({
  isOpen,
  data,
  isFormSubmitting,
  onMainButtonClick,
  onSecondaryButtonClick,
}: FormSnippetBuilderPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [booleanItems, setBooleanItems] = useState<Array<SelectOption>>([{ id: '', label: '-' }]);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormSnippetEditFormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSnippetEditFormSchema),
    defaultValues: FORM_SNIPPET_BUILDER_DEFAULT_VALUES,
  });

  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = formMethods;

  const handleSaveButtonClick = (values: FormSnippetEditFormSubmitProps) => {
    onMainButtonClick({ name: values.name, data: values.data });
  };

  const handleCancelButtonClick = () => {
    onSecondaryButtonClick();
  };

  const handleUpdateBooleanItems = (items: SelectOption[]) => {
    if (items.length) {
      const { data } = getValues();

      for (let i = 0; i < data.items.length; i++) {
        const id = data.items[i].dependsOnItem?.booleanItemId;

        if (id && !items.some((el) => el.id === id)) {
          setValue(`data.items.${i}.dependsOnItem.booleanItemId`, '', {
            shouldDirty: true,
          });
          setValue(`data.items.${i}.dependsOnItem.expectedValue`, '', {
            shouldDirty: true,
          });
        }
      }
    }

    setBooleanItems(items);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { data } = getValues();

    const newSectionItems = Array.from(data.items);
    const [draggedItem] = newSectionItems.splice(result.source.index, 1);
    newSectionItems.splice(result.destination.index, 0, draggedItem);
    setValue('data.items', newSectionItems, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (!isOpen) return;

    if (data) {
      setIsLoading(true);

      getFormSnippet(data.id)
        .then((response) => {
          if (response.data) {
            const mappedData = mapFormSnippetQueryResult(response.data.data);

            setBooleanItems(extractBooleanItems(mappedData));
            reset({ name: response.data.name, data: mappedData });
          }
        })
        .catch((error: AxiosError<ErrorData>) => {
          setBooleanItems([{ id: '', label: '-' }]);
          reset(FORM_SNIPPET_BUILDER_DEFAULT_VALUES);
          setAlert((prev) => ({
            ...prev,
            message: error.response?.data.message || 'Error. Something went wrong...',
            type: 'error',
          }));
        })
        .finally(() => setIsLoading(false));
    } else {
      setBooleanItems([{ id: '', label: '-' }]);
      reset(FORM_SNIPPET_BUILDER_DEFAULT_VALUES);
    }
  }, [isOpen]);

  // TODO: Code duplication (DataRequestFormBuilder, QuestionnaireFormBuilder, FormSnippetBuilder)
  return (
    <Dialog
      className={classes.dialogContainer}
      open={isOpen}
      onClose={handleCancelButtonClick}
      fullWidth
      maxWidth={'xl'}
    >
      <DialogTitle className={classes.dialogTitle}>{'Edit Form Snippet'}</DialogTitle>
      <DialogContent>
        {isLoading || isFormSubmitting ? (
          <Loader colorType="warning" />
        ) : (
          <FormProvider {...formMethods}>
            <form className={classes.form}>
              <FormTextField name={'name'} label="Snippet Name *" maxWidth="480px" />
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <FormSnippetBuilder
                  booleanItems={booleanItems}
                  handleUpdateBooleanItems={handleUpdateBooleanItems}
                />
              </DragDropContext>
            </form>
          </FormProvider>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.buttonDefault, classes.saveButton)}
          onClick={handleSubmit(handleSaveButtonClick)}
          disabled={!isDirty || !isValid || isLoading || isFormSubmitting}
        >
          {'Save'}
        </Button>
        <Button
          className={clsx(classes.buttonDefault, classes.cancelButton)}
          onClick={handleCancelButtonClick}
        >
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormSnippetBuilderPopup;
