import { Fragment, useRef } from 'react';
import { Theme, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonGroup: {
    width: 'auto',
    maxWidth: '100%',
    borderRadius: '12px',
  },
  default: {
    display: 'flex',
    height: '48px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.08)',
    color: theme.palette.text.disabled,
  },
  mainButton: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
  deleteButton: {
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
  },
}));

interface FormSnippetButtonProps {
  readonly buttonText: string;
  readonly onClick?: () => void;
  readonly onDeleteClick?: () => void;
}

const FormSnippetButton = ({ buttonText, onClick, onDeleteClick }: FormSnippetButtonProps) => {
  const { classes } = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <ButtonGroup
        className={classes.buttonGroup}
        disableElevation
        ref={anchorRef}
        variant="contained"
      >
        <Button className={clsx(classes.default, classes.mainButton)} onClick={onClick}>
          <Typography className={classes.buttonText} noWrap>
            {buttonText}
          </Typography>
        </Button>
        <Button
          className={clsx(classes.default, classes.deleteButton)}
          size="small"
          onClick={onDeleteClick}
        >
          <DeleteForeverIcon />
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};

export default FormSnippetButton;
