import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import theme from '../../theme';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonDefault: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: theme.palette.info.dark,
    borderRadius: '12px',
    padding: '8px 12px',

    '@media (max-width: 800px)': {
      maxWidth: 'max-content',
    },

    '&:hover': {
      cursor: 'pointer',
    },

    svg: {
      color: theme.palette.common.white,
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

interface FirmBankingAddAccountButtonProps {
  disabled?: boolean;
  buttonText?: string;
  handleClick?: () => void;
}

const FirmBankingAddAccountButton = ({
  buttonText,
  handleClick,
}: FirmBankingAddAccountButtonProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.buttonDefault} onClick={handleClick}>
      <AddIcon
        sx={{
          fontSize: '18px',
          svg: {
            color: theme.palette.common.white,
          },
        }}
      />
      <Typography className={classes.buttonText}>{buttonText}</Typography>
    </Box>
  );
};

export default FirmBankingAddAccountButton;
