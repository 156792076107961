import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as WelcomeHeroTwoIcon } from '../assets/welcome-hero-2.svg';

import Header from '../Header';
import Footer from '../Footer';

import useWindowWidth from '../../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  pageMainContainer: {
    height: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19) 3.19%, rgba(37, 157, 168, 0.00) 37.39%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#283645',
    fontFamily: 'Inter',
  },
  footerBackground: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',

    '@media (max-width: 768px)': {
      paddingTop: '96px',
    },
  },
  pageHeadlineBlock: {
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    marginBottom: '66px',
    alignItems: 'flex-end',
    gap: '40px',
    marginTop: '40px',
    padding: '0 20px',

    '@media (max-width: 900px)': {
      justifyContent: 'center',
    },

    '@media (max-width: 768px)': {
      margin: '48px 0',
    },
  },
  pageHeadlineText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: '72px',
    color: '#283645',
    textAlign: 'center',

    '@media (max-width: 768px)': {
      fontSize: '36px',
      lineHeight: '120%',
    },
  },
  policyPageContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1200px',
    gap: '40px',
    margin: '0 auto',
    padding: '0 20px',
    paddingBottom: '40px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#5D6068',

    a: {
      color: '#00667C',
      textDecoration: 'none',
    },

    strong: {
      fontWeight: 600,
      color: '#283645',
    },
  },
}));

const PrivacyPolicyView = () => {
  const styles = useStyles();
  const windowWidth = useWindowWidth();

  const [isRequestDemoPopupOpen, setIsRequestDemoPopupOpen] = useState<boolean>(false);

  const handleClosePopup = () => setIsRequestDemoPopupOpen(false);
  const handleOpenPopup = () => setIsRequestDemoPopupOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  return (
    <Box className={styles.classes.pageMainContainer}>
      <Header
        windowWidth={windowWidth}
        handleOpenPopup={handleOpenPopup}
        isRequestDemoPopupOpen={isRequestDemoPopupOpen}
        handleClosePopup={handleClosePopup}
      />

      <Box className={styles.classes.pageHeadlineBlock}>
        {windowWidth > 900 && <WelcomeHeroTwoIcon />}
        <Typography className={styles.classes.pageHeadlineText}>Privacy Policy</Typography>
      </Box>

      <Box className={styles.classes.policyPageContentWrapper}>
        <section>
          <ol type="1" style={{ padding: '0 20px' }}>
            <li>
              <strong>Introduction.</strong>
              <p>
                v.law, LLC (<strong>“Company”</strong>, <strong>“Us”</strong> or{' '}
                <strong>“We”</strong>) respects your privacy and is committed to protecting it
                through our compliance with this policy.
              </p>
              <p>
                This policy describes the types of information we may collect from you or that you
                may provide when you visit the website v.law (our <strong>“Website”</strong>) and
                our practices for collecting, using, maintaining, protecting, and disclosing that
                information. Your privacy when using our software is protected by the terms of your
                agreement with v.law, LLC; this Privacy Policy does not apply to information
                collected during product usage.
              </p>
              <p>
                This policy applies to information we collect:
                <ul>
                  <li>on this Website (including any subdomains);</li>
                  <li>
                    in email, text, and other electronic messages between you and this Website; and
                  </li>
                  <li>from other websites and public sources.</li>
                </ul>
              </p>
              <p>
                It does not apply to information collected by:
                <ul>
                  <li>us offline or through any other means; or</li>
                  <li>
                    any third party, including through any application or content (including
                    advertising) that may link to or be accessible from the Website.
                  </li>
                </ul>
              </p>
              <p>
                Please read this policy carefully to understand our policies and practices regarding
                your information and how we will treat it. By accessing or using our Website, you
                agree to this privacy policy. This policy may change from time to time. Your
                continued use of our Website after we make changes is deemed to be acceptance of
                those changes, so please check the policy periodically for updates.
              </p>
            </li>
            <li>
              <strong>Information We Collect About You and How We Collect It.</strong>
              <p>
                We collect several types of information from and about users of our Website,
                including information:
                <ul>
                  <li>
                    by which you may be personally identified, such as name, postal address, e-mail
                    address, and telephone number (<strong>“personal information”</strong>);
                  </li>
                  <li>that is about you but individually does not identify you; and/or</li>
                  <li>
                    about your internet connection, the equipment you use to access our Website and
                    usage details.
                  </li>
                </ul>
              </p>
              <p>
                We collect this information:
                <ul>
                  <li>directly from you when you provide it to us; and/or</li>
                  <li>
                    automatically as you navigate through the site. Information collected
                    automatically may include usage details, IP addresses, and information collected
                    through cookies.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>‍Information You Provide to Us.</strong>
              <p>
                The information we collect on or through our Website may include:
                <ul>
                  <li>
                    information that you provide by filling in forms on our Website. This includes
                    information provided at the time of registering to use our Website, posting
                    material, or requesting further services. We may also ask you for information
                    when you report a problem with our Website; and/or
                  </li>
                  <li>
                    records and copies of your correspondence (including email addresses), if you
                    contact us.
                  </li>
                </ul>
              </p>
              <p>
                You may opt-out of providing us with information, by choosing to not fill out any
                forms on our Website, to include not registering to use our Website, not posting any
                materials, or not requesting further services. By choosing to opt-out, you may not
                be able to download materials from our Website and we will not be able to provide
                you with requested services or information from our Website.
              </p>
              <p>
                You also may provide information to be published or displayed (hereinafter,{' '}
                <strong>“posted”</strong>) on public areas of the Website, or transmitted to other
                users of the Website or third parties (collectively,
                <strong>“User Contributions”</strong>). Your User Contributions are posted and
                transmitted to others at your own risk. Additionally, we cannot control the actions
                of other users of the Website with whom you may choose to share your User
                Contributions. Therefore, we cannot and do not guarantee that your User
                Contributions will not be viewed by unauthorized persons.
              </p>
            </li>
            <li>
              <strong>
                Information We Collect Through Automatic Data Collection Technologies.
              </strong>
              <p>
                As you navigate through and interact with our Website, we may use automatic data
                collection technologies to collect certain information about your equipment,
                browsing actions, and patterns, including:
                <ul>
                  <li>
                    details of your visits to our Website, including traffic data, location data,
                    logs, and other communication data and the resources that you access and use on
                    the Website; and/or
                  </li>
                  <li>
                    information about your computer and internet connection, including your IP
                    address, operating system, and browser type.
                  </li>
                </ul>
              </p>
              <p>
                The information we collect automatically may include personal information, and we
                may maintain it or associate it with personal information we collect in other ways
                or receive from third parties. It helps us to improve our Website and to deliver a
                better and more personalized service, including by enabling us to:
                <ul>
                  <li>estimate our audience size and usage patterns;</li>
                  <li>
                    store information about your preferences, allowing us to customize our Website
                    according to your individual interests;
                  </li>
                  <li>speed up your searches; and/or</li>
                  <li>recognize you when you return to our Website.</li>
                </ul>
              </p>
              <p>
                The technologies we use for this automatic data collection may include:
                <ul>
                  <li>
                    cookies (or browser cookies). A cookie is a small file placed on the hard drive
                    of your computer. You may learn more about the cookies we use, refuse to accept
                    browser cookies by activating the appropriate setting on your browser, or you
                    may change your cookie preferences below. However, if you change your settings
                    or preferences you may be unable to access certain areas or functionalities of
                    our Website. Unless you have adjusted your browser settings so that it will
                    refuse cookies, or have adjusted your cookie preferences using the above link,
                    our system will issue cookies when you direct your browser to our Website;
                    and/or
                  </li>
                  <li>
                    web beacons. Pages of our Website and our e-mails may contain small electronic
                    files known as web beacons (also referred to as clear gifs, pixel tags, and
                    single-pixel gifs) that permit the Company, for example, to count users who have
                    visited those pages or opened an email and for other related website statistics
                    (for example, recording the popularity of certain website content and verifying
                    system and server integrity).
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Information We Collect from Public Sources.</strong>
              <p>
                We may collect information about you from public sources including without
                limitation LinkedIn, Facebook, or your company’s website. The information that we
                collect may include your name, email address, job title, telephone number, employer,
                and any additional information that you provide to us when communicating with us.
              </p>
            </li>
            <li>
              <strong>How We Use Your Information.</strong>
              <p>
                We use information that we collect about you or that you provide to us, including
                any personal information:
                <ul>
                  <li>to present our Website and its contents to you;</li>
                  <li>
                    to provide you with information, products, or services that you request from us;
                  </li>
                  <li>to fulfill any other purpose for which you provide it;</li>
                  <li>
                    to carry out our obligations and enforce our rights arising from any contracts
                    entered into between you and us, including for billing and collection;
                  </li>
                  <li>
                    to notify you about changes to our Website or any products or services we offer
                    or provide though it;
                  </li>
                  <li>
                    to contact you about products and services that we think may be of interest to
                    you;
                  </li>
                  <li>in any other way we may describe when you provide the information; and/or</li>
                  <li>for any other purpose with your consent.</li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Disclosure of Your Information.</strong>
              <p>
                We may disclose anonymized and aggregated information about our users without
                restriction.
              </p>
              <p>
                We may disclose personal information that we collect or you provide as described in
                this privacy policy:
                <ul>
                  <li>to our subsidiaries and affiliates;</li>
                  <li>
                    to contractors, service providers, and other third parties we use to support our
                    business;
                  </li>
                  <li>
                    to a buyer or other successor in the event of a merger, divestiture,
                    restructuring, reorganization, dissolution, or other sale or transfer of some or
                    all of our assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal information about our
                    Website users is among the assets transferred;
                  </li>
                  <li>to fulfill the purpose for which you provide it;</li>
                  <li>
                    for any other purpose disclosed by us when you provide the information; and/or
                  </li>
                  <li>with your consent.</li>
                </ul>
              </p>
              <p>
                We may also disclose your personal information:
                <ul>
                  <li>
                    to comply with any court order, law, or legal process, including to respond to
                    any government or regulatory request;
                  </li>
                  <li>
                    to enforce or apply our terms of use and other agreements, including for billing
                    and collection purposes; and/or
                  </li>
                  <li>
                    if we believe disclosure is necessary or appropriate to protect the rights,
                    property, or safety of v.law, LLC, our customers, or others.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <strong>Our Legal Basis for Collecting and Using your Information.</strong>
              <p>
                If you provide personal information to us through our website, or if you otherwise
                contact us (for example by email or telephone), our legal basis for processing your
                personal information is that it is necessary for our legitimate interest in
                conducting our business, in particular by providing any products, services and
                information that you request.
              </p>
              <p>
                If we collect your personal information from publicly available sources, our legal
                basis for processing your personal information is that it is necessary for our
                legitimate interest in promoting our business.
              </p>
            </li>
            <li>
              <strong>Choices About How We Use and Disclose Your Information.</strong>
              <p>
                We strive to provide you with choices regarding the personal information you provide
                to us. We have created mechanisms to provide you with the following control over
                your information:
                <ul>
                  <li>
                    <strong>Tracking Technologies and Advertising.</strong> You can set your browser
                    to refuse all or some browser cookies, or to alert you when cookies are being
                    sent. You may also change your cookie preferences using the link provided above
                    in Section 4. If you disable or refuse cookies, please note that some parts of
                    this site may then be inaccessible or not function properly.
                  </li>
                  <li>
                    <strong>Promotional Offers from the Company.</strong> If you do not wish to have
                    your email address/contact information used by the Company to promote our
                    products or services, you can opt-out by sending us an email stating your
                    request to <a href="mailto:webmaster@v.law">webmaster@v.law</a>. If we have sent
                    you a promotional email, you may send us a return email asking to be omitted
                    from future email distributions. This opt out does not apply to information
                    provided to the Company as a result of a product purchase.
                  </li>
                </ul>
              </p>
              <p>
                We do not control third parties’ collection or use of your information to serve
                interest-based advertising. However, these third parties may provide you with ways
                to choose not to have your information collected or used in this way. You can opt
                out of receiving targeted ads from members of the Network Advertising Initiative (
                <strong>“NAI”</strong>) on NAI’s website.
              </p>
            </li>
            <li>
              <strong>Your Rights.</strong>
              <p>
                You may have certain rights in relation to any personal information we collect about
                you. These may include the rights to request access to the personal information we
                hold about you, to obtain a copy of your personal information in machine readable
                format, or to request that it is erased or that any inaccurate personal information
                is rectified. You may also have the right to ask us to restrict how we process your
                personal information, to withdraw your consent to how we process your personal
                information, or to request that we do not use your personal information for the
                purposes of direct marketing.
              </p>
              <p>
                Although you have the right to make these requests, we may deny your requests, in
                whole or in part, under certain circumstances, such as if we are unable to
                authenticate that you are the individual for whom the personal information is being
                requested or providing you with access to personal information would necessarily
                violate the legitimate rights of another individual. If we deny access to you, we
                will communicate the denial to you and reasons for the denial in a timely manner
                unless prohibited from doing so by law or regulation. Further, as a consequence of
                you exercising these rights, you may not be able to use our Website, download
                materials from our Website and we will not be able to provide you with requested
                services or information from our Website.
              </p>
              <p>
                You also have the right to complain about the use of your personal information to
                your local supervisory authority.
              </p>
            </li>
            <li>
              <strong>Changes to Our Privacy Policy.</strong>
              <p>
                It is our policy to post any changes we make to our privacy policy on this page. The
                date the privacy policy was last revised is identified at the top or bottom of the
                page. You are responsible for ensuring we have an up-to-date active and deliverable
                email address for you, and for periodically visiting our Website and this privacy
                policy to check for any changes.
              </p>
            </li>
            <li>
              <strong>Contact Information.</strong>
              <p>
                To ask questions or comment about this privacy policy and our privacy practices,
                contact us at:
              </p>
              <p>
                <a href="mailto:privacy@v.law">privacy@v.law</a> and{' '}
                <a href="mailto:webmaster@v.law">webmaster@v.law</a>
              </p>
              <p>
                v.law, LLC
                <br />
                7950 Jones Branch Drive
                <br />
                8th Floor North
                <br />
                McLean, Virginia 22102
                <br />
              </p>
            </li>
          </ol>
        </section>
      </Box>

      <Box className={styles.classes.footerBackground}>
        <Footer />
      </Box>
    </Box>
  );
};

export default PrivacyPolicyView;
