import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, IconButton, Theme, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import clsx from 'clsx';
import { ReactComponent as LogoImage } from './sign-logo.svg';
import moment from 'moment';
import { SettlementAdditionalInfoData, SettlementsPayoutCheckData } from '../types';
import { getSettlementsCheck } from '../../../api/paymentsApi/settlementsApi';
import { printCheck } from '../../../helpers/printCheck';
import { printCheckInlineStyles } from '../../../helpers/print-check-inline-styles';
import Loader from '../../../components/Loader';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { FIRM_ROLES } from '../../CasesView/types';

const useStyles = makeStyles()((theme: Theme) => ({
  feesSummaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '425px',
    height: '100%',
  },
  feesSummaryHeadline: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: '10px',
  },
  feesSummaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    alignItems: 'center',
    color: theme.palette.common.white,

    button: {
      p: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
      },
    },
  },
  feesSummaryHeaderPrintBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  feesSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    background: theme.palette.primary.light,
    minHeight: '400px',
    borderRadius: '16px',

    '@media (max-width: 650px)': {
      padding: '16px',
    },
  },
  feesSummaryTotalGrossHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
  },
  feesSummarySectionHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionInnerHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 10px 34px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
  },
  feesSummaryBlockDivider: {
    height: '1px',
    background: theme.palette.secondary.dark,
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '10px',
  },
  firmNameText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.8px',
    color: theme.palette.common.white,
    flexBasis: 'auto',
    overflow: 'hidden',
    minWidth: 0,
    textOverflow: 'ellipsis',
    maxWidth: '130px',
    whiteSpace: 'nowrap',
  },
  printButton: {
    color: 'white',
    padding: 0,

    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: '80px',
  },
  secondaryFirmsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  noCheckTextList: {
    listStyleType: 'disc',
    padding: 0,
    paddingLeft: '30px',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    fontSize: '16px',
    gap: '3px',
    color: theme.palette.common.white,
  },
}));

interface SettlementsPayoutCheckProps {
  isPayoutCheckUpdating?: boolean;
  payoutCheck?: SettlementsPayoutCheckData;
  setPayoutCheck?: Dispatch<SetStateAction<SettlementsPayoutCheckData | undefined>>;
  setAdditionalSettlementInfo?: Dispatch<SetStateAction<SettlementAdditionalInfoData | undefined>>;
  secondaryFirmsData: { id: string; name: string; role: string }[] | unknown;
  caseSystemName: string | unknown;
  leadFirmData?: { id: string; name: string } | unknown;
  setIsPayoutCheckUpdating: Dispatch<SetStateAction<boolean>>;
  status?: string;
}

const SettlementsPayoutCheck = ({
  isPayoutCheckUpdating,
  payoutCheck,
  setPayoutCheck,
  setAdditionalSettlementInfo,
  caseSystemName,
  secondaryFirmsData,
  leadFirmData,
  setIsPayoutCheckUpdating,
  status,
}: SettlementsPayoutCheckProps) => {
  const styles = useStyles();
  const { settlementId } = useParams();

  const currentDateTime = moment().format('hh:mm A, MM/DD/YYYY').split(',');

  // TODO Define types for entity data
  const leadFirmName: { id: string; name: string } = leadFirmData as { id: string; name: string };
  const caseName: string = caseSystemName as string;
  const secondaryFirms: { id: string; name: string; role: string }[] = secondaryFirmsData as {
    id: string;
    name: string;
    role: string;
  }[];

  const sortedSecondaryFirms = secondaryFirms
    ?.sort((a, b) => a.role.localeCompare(b.role))
    .map((firm) => ({
      firmName: firm.name,
      firmRole: FIRM_ROLES[firm.role as keyof typeof FIRM_ROLES],
    }));

  useEffect(() => {
    getSettlementsCheck(settlementId as string)
      .then((res) => {
        if (res.data) {
          setPayoutCheck && setPayoutCheck(res.data);
          setAdditionalSettlementInfo && setAdditionalSettlementInfo(res.data.additionalInfo);
          setIsPayoutCheckUpdating(false);
        }
      })
      .catch(() => {
        setIsPayoutCheckUpdating(false);
        setPayoutCheck && setPayoutCheck(undefined);
      });
  }, []);

  if (isPayoutCheckUpdating) {
    return (
      <Box className={styles.classes.feesSummaryContainer}>
        <Box className={styles.classes.loaderContainer}>
          <Loader colorType="info" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={styles.classes.feesSummaryWrapper}>
        <Box className={styles.classes.feesSummaryHeader}>
          <Typography className={styles.classes.feesSummaryHeadline}>Settlement Preview</Typography>
          {payoutCheck !== undefined && (
            <Box className={styles.classes.feesSummaryHeaderPrintBlock}>
              <IconButton
                className={styles.classes.printButton}
                onClick={() => printCheck(printCheckInlineStyles)}
                disableRipple
                disabled={isPayoutCheckUpdating || payoutCheck === undefined}
              >
                <PrintIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={styles.classes.feesSummaryContainer} id="printContent">
          {payoutCheck === undefined ? (
            <Box display="flex" flexDirection="column" mt="130px" gap="5px" alignSelf="center">
              {status && status === 'Paid' ? (
                <Box display="flex" gap="5px" flexDirection="column">
                  <Typography color="white" fontFamily="Poppins" fontSize="16px" lineHeight="20px">
                    Payment preview unavailable.
                  </Typography>
                  <Typography color="white" fontFamily="Poppins" fontSize="16px" lineHeight="20px">
                    The settlement has been paid.
                  </Typography>
                </Box>
              ) : (
                <Typography color="white" fontFamily="Roboto" fontSize="16px" lineHeight="20px">
                  Payment preview unavailable. Please review and update:
                </Typography>
              )}

              {status && status !== 'Paid' && (
                <ul className={styles.classes.noCheckTextList}>
                  <li>Participating law firms</li>
                  <li>Defendants and gross settlement amounts</li>
                  <li>Attorneys’ fees</li>
                  <li>Trust account</li>
                </ul>
              )}
            </Box>
          ) : (
            <Box className="feesSummaryContainer">
              {/* {print header} */}
              <Box className="print-header" display="none">
                <Box className="logo-image-wrapper">
                  <LogoImage />
                </Box>
                <Typography className="print-case-system-name">{caseName}</Typography>
                <Typography>{`${leadFirmName} (Lead Counsel)`}</Typography>
                {sortedSecondaryFirms?.map((firm) => (
                  <Typography
                    key={firm.firmName}
                  >{`${firm.firmName} (${firm.firmRole})`}</Typography>
                ))}
              </Box>

              <Box
                className={clsx(
                  styles.classes.feesSummaryTotalGrossHeadline,
                  'print-feesSummaryTotalGrossHeadline',
                )}
              >
                <Typography>Settled Amount</Typography>
                <Typography>{numberToUSDCurrency(payoutCheck?.total)}</Typography>
              </Box>

              <Box
                className={clsx(
                  styles.classes.feesSummarySectionContainer,
                  'print-feesSummarySectionContainer',
                )}
                mt="20px"
              >
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionHeadline,
                    'print-feesSummarySectionHeadline',
                  )}
                >
                  <Typography>Attorneys’ Fees</Typography>
                  <Typography>{numberToUSDCurrency(payoutCheck?.attorneysFees?.total)}</Typography>
                </Box>

                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionInnerHeadline,
                    'print-feesSummarySectionInnerHeadline',
                  )}
                >
                  <Box className={styles.classes.secondaryFirmsInfoContainer}>
                    <Typography className={clsx(styles.classes.firmNameText, 'print-firmNameText')}>
                      {payoutCheck.attorneysFees?.leadFirm?.name}
                    </Typography>
                    <Typography>Lead Counsel</Typography>
                  </Box>
                  <Typography>
                    {numberToUSDCurrency(payoutCheck.attorneysFees?.leadFirm?.fee)}
                  </Typography>
                </Box>

                {!!payoutCheck?.attorneysFees?.secondaryFirms?.length && (
                  <>
                    {payoutCheck.attorneysFees.secondaryFirms.map((firmData, idx) => (
                      <Box
                        key={idx}
                        className={clsx(
                          styles.classes.feesSummarySectionInnerHeadline,
                          'print-feesSummarySectionInnerHeadline',
                        )}
                      >
                        <Box className={styles.classes.secondaryFirmsInfoContainer}>
                          <Typography
                            className={clsx(styles.classes.firmNameText, 'print-firmNameText')}
                          >
                            {firmData.name}
                          </Typography>
                          <Typography>
                            {FIRM_ROLES[firmData.role as keyof typeof FIRM_ROLES]}
                          </Typography>
                        </Box>
                        <Typography>{numberToUSDCurrency(firmData?.fees?.total)}</Typography>
                      </Box>
                    ))}
                  </>
                )}

                <Box
                  className={clsx(
                    styles.classes.feesSummaryBlockDivider,
                    'print-feesSummaryBlockDivider',
                  )}
                ></Box>
              </Box>

              {payoutCheck?.deductions && (
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionContainer,
                    'print-feesSummarySectionContainer',
                  )}
                >
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionHeadline,
                      'print-feesSummarySectionHeadline',
                    )}
                  >
                    <Typography>Deductions</Typography>
                    <Typography>{numberToUSDCurrency(payoutCheck?.deductions?.total)}</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionInnerHeadline,
                      'print-feesSummarySectionInnerHeadline',
                    )}
                  >
                    <Typography>Advances</Typography>
                    <Typography>
                      {numberToUSDCurrency(payoutCheck?.deductions?.advances)}
                    </Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionInnerHeadline,
                      'print-feesSummarySectionInnerHeadline',
                    )}
                  >
                    <Typography>Liens</Typography>
                    <Typography>{numberToUSDCurrency(payoutCheck?.deductions?.liens)}</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummaryBlockDivider,
                      'print-feesSummaryBlockDivider',
                    )}
                  ></Box>
                </Box>
              )}

              <Box
                className={clsx(
                  styles.classes.feesSummarySectionContainer,
                  'print-feesSummarySectionContainer',
                )}
              >
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionHeadline,
                    'print-feesSummarySectionHeadline',
                  )}
                >
                  <Typography>Net Settlement</Typography>
                  <Typography>{numberToUSDCurrency(payoutCheck?.netSettlement?.amount)}</Typography>
                </Box>
              </Box>

              <Box className="print-date-time" display="none">
                <Typography>{`Generated at ${currentDateTime[0]} on ${currentDateTime[1]}`}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SettlementsPayoutCheck;
