export const printCheckInlineStyles = `
  .print-feesSummaryTotalGrossHeadline {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px;
  }

  .print-feesSummaryTotalGrossHeadline p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }

  .print-feesSummarySectionContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }

  .print-feesSummarySectionHeadline {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .print-feesSummarySectionHeadline p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }

  .print-feesSummarySectionInnerHeadline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 34px;
  }

  .print-feesSummarySectionInnerHeadline p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }

  .print-firmNameText {
    flex-basis: auto;
    overflow: hidden;
    min-width: 0;
    margin: 0;
  }

  .print-feesSummaryBlockDivider {
    display: none;
  }

  .print-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .print-header p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }

  .logo-image-wrapper {
    margin-bottom: 20px;
  }

  .print-date-time {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .print-date-time p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }

  .print-case-system-name {
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    font-family: "Roboto";
    margin: 0;
  }
`;
