import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    background: '#ADDC7B14',
    color: theme.palette.success.main,

    '&:hover': {
      background: '#ADDC7B44',
    },
  },
  active: {
    background: theme.palette.success.main,
    color: theme.palette.common.black,

    '&:hover': {
      background: theme.palette.success.main,
      color: theme.palette.common.black,
    },
  },
}));

interface SelectButtonProps {
  readonly buttonText: string;
  readonly checked: boolean;
  readonly onClick?: () => void;
}

const SelectButton = ({ buttonText, checked, onClick }: SelectButtonProps) => {
  const { classes } = useStyles();

  return (
    <Chip
      className={clsx({
        [classes.default]: true,
        [classes.active]: checked,
      })}
      label={buttonText}
      onClick={onClick}
    />
  );
};

export default SelectButton;
