import { motion } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as FeatureBlockLeftImage } from './assets/feature-block-left.svg';
import { ReactComponent as FeatureBlockRightImage } from './assets/feature-block-right.svg';
import RequestDemoButton from './RequestDemoComponents/RequestDemoButton';

const useStyles = makeStyles()((theme: Theme) => ({
  featureBlockBackground: {
    width: '100%',
    backgroundColor: '#282B3A',
    marginTop: '120px',

    '@media (max-width: 768px)': {
      marginTop: '80px',
    },
  },
  featureBlockContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    color: '#283645',
    fontFamily: 'Inter',
    marginTop: '80px',
    gap: '40px',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '50px 0',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '80px 40px',
    },

    '@media (max-width: 425px)': {
      padding: '80px 20px',
    },
  },
  featureBlockMiddleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '410px',
    width: '100%',
    alignItems: 'center',

    '@media (max-width: 1000px)': {
      paddingTop: 0,
      maxWidth: '688px',
    },
  },
  featureBlockMiddleContainerHeadline: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
    fontSize: '34px',
    color: theme.palette.common.white,
    textAlign: 'center',

    '@media (max-width: 768px)': {
      lineHeight: '24px',
      fontSize: '20px',
    },

    '@media (max-width: 500px)': {
      maxWidth: '335px',
      textAlign: 'center',
    },
  },
  featureBlockMiddleContainerText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.text.secondary,

    '@media (max-width: 768px)': {
      lineHeight: '19.2px',
    },

    '@media (max-width: 500px)': {
      maxWidth: '335px',
      textAlign: 'center',
    },
  },
  tabletFeatureImagesContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',

    '@media (max-width: 768px)': {
      '& .slide-one': {
        svg: {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
  imageContainer: {
    '@keyframes floating': {
      '0%': { transform: 'translate(0,  0px)' },
      '50%': { transform: 'translate(0, 15px)' },
      '100%': { transform: 'translate(0, -0px)' },
    },
    animation: 'floating 3s infinite  ease',
    display: 'flex',
    minWidth: 0,

    '@media (max-width: 500px)': {
      animation: 'none',
      marginLeft: '25px',
    },
  },
  tabletWelcomeImagesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    alignItems: 'center',

    '@media (max-width: 1000px)': {
      '& .hero-one': {
        svg: {
          width: '225px',
          height: '300px',
        },
      },
      '& .hero-two': {
        marginTop: 0,
        svg: {
          width: '250px',
          height: '300px',
        },
      },
    },
  },
}));

interface FeatureDetailsBlockProps {
  handleOpenPopup: () => void;
  windowWidth: number;
}

const FeatureDetailsBlock = ({ handleOpenPopup, windowWidth }: FeatureDetailsBlockProps) => {
  const styles = useStyles();

  const leftImageAnimationProps = {
    initial: { opacity: 0, transform: 'translateX(-50%)' },
    whileInView: {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
    viewport: { once: true },
  };

  const rightImageAnimationProps = {
    initial: { opacity: 0, transform: 'translateX(50%)' },
    whileInView: {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
    viewport: { once: true },
  };

  return (
    <Box className={styles.classes.featureBlockBackground}>
      {windowWidth > 1000 || windowWidth <= 500 ? (
        <Box className={styles.classes.featureBlockContainer}>
          <motion.div
            className={styles.classes.imageContainer}
            {...(windowWidth > 1000 && { ...leftImageAnimationProps })}
          >
            <FeatureBlockLeftImage />
          </motion.div>

          <Box className={styles.classes.featureBlockMiddleContainer}>
            <Typography className={styles.classes.featureBlockMiddleContainerHeadline}>
              Be more than a law firm to your clients
            </Typography>
            <Typography className={styles.classes.featureBlockMiddleContainerText}>
              Make a deeper connection with your clients through available app and debit card
              customizations.
            </Typography>
            <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="14px" />
          </Box>

          <motion.div
            className={styles.classes.imageContainer}
            {...(windowWidth > 1000 && { ...rightImageAnimationProps })}
          >
            <FeatureBlockRightImage />
          </motion.div>
        </Box>
      ) : (
        <Box className={styles.classes.featureBlockContainer}>
          <Box className={styles.classes.featureBlockMiddleContainer}>
            <Typography className={styles.classes.featureBlockMiddleContainerHeadline}>
              Be more than a law firm to your clients
            </Typography>
            <Typography className={styles.classes.featureBlockMiddleContainerText}>
              Make a deeper connection with your clients through available app and debit card
              customizations.
            </Typography>
            <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="14px" />
          </Box>
          <Box className={styles.classes.tabletWelcomeImagesContainer}>
            <Box className="hero-one" display="flex" minWidth={0}>
              <FeatureBlockLeftImage />
            </Box>
            <Box className="hero-two" display="flex" minWidth={0}>
              <FeatureBlockRightImage />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FeatureDetailsBlock;
