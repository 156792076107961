import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Timeline from '../../../components/Timeline';
import { TimelineType } from '../../../types';
import { getDataRequestTimeline } from '../../../api/requestsApi/dataApi';

const DataTimeline = () => {
  const { dataId } = useParams<string>();
  const [dataTimeline, setDataTimeline] = useState<TimelineType>([]);

  useEffect(() => {
    if (!dataId) return;

    getDataRequestTimeline(dataId).then((res) => {
      if (res.data) {
        setDataTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={dataTimeline} />;
};

export default DataTimeline;
