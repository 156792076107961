import { Dispatch, ReactNode, SetStateAction } from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Table, TableBody, Theme } from '@mui/material';

import TableHeader from './TableHeader';

import TablePaginationContainer from './TablePaginationContainer';

import { ActiveSortField, SortableFieldsObject } from '../../types';

const useStyles = makeStyles<{ hasBigData?: boolean }>()((theme: Theme, { hasBigData }) => ({
  tableContainer: {
    overflow: 'auto',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 500,
    paddingBottom: '10px',
    minHeight: hasBigData ? '300px' : 'inherit',

    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  tableBodyRow: {
    '& .MuiTableRow-root': {
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.secondary.light,
      },
    },
  },
  tableBodyCell: {
    background: theme.palette.primary.light,

    '& .MuiTableRow-root': {
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.secondary.light,
      },
    },

    '& .MuiTableCell-root': {
      color: theme.palette.common.white,
      borderBottom: '1px solid #4A4E5D',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '16px',
      height: '65px',
      padding: 0,
      paddingLeft: '16px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

interface TableContainerProps {
  children: ReactNode;
  headerLabels: { accessor: string; isSortable: boolean; label: string }[];
  activeSortField: ActiveSortField | null;
  handleSortTableData?: (
    sortField: string,
    sortableFields: SortableFieldsObject,
    activeSortField: ActiveSortField | null,
    setActiveSortField?: Dispatch<SetStateAction<ActiveSortField | null>>,
  ) => void;
  sortableFields?: SortableFieldsObject;
  setActiveSortField: Dispatch<SetStateAction<ActiveSortField | null>>;
  hasBigData?: boolean;
}

const TableContainer = ({
  headerLabels,
  children,
  handleSortTableData,
  activeSortField,
  sortableFields,
  setActiveSortField,
  hasBigData,
}: TableContainerProps) => {
  const styles = useStyles({ hasBigData });

  return (
    <Box className={styles.classes.tableContainer}>
      <Table
        sx={{
          tableLayout: 'auto',
          minWidth: '1200px',
        }}
      >
        <TableHeader
          headerLabels={headerLabels}
          handleSortTableData={handleSortTableData}
          sortableFields={sortableFields}
          activeSortField={activeSortField}
          setActiveSortField={setActiveSortField}
        />
        <TableBody className={clsx(styles.classes.tableBodyCell, styles.classes.tableBodyRow)}>
          {children}
        </TableBody>
      </Table>
      <TablePaginationContainer />
    </Box>
  );
};

export default TableContainer;
