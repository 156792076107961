import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '802px',
      boxSizing: 'border-box',
      padding: '32px',
      gap: '32px',
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '16px',
      backgroundColor: theme.palette.primary.light,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '40.8px',
    padding: '32px 0px 0px 0px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '0px',
    overflowY: 'unset',
  },
  dialogContentText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.8px',
    color: theme.palette.text.disabled,
  },
  pinInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  defaultButtonStyle: {
    height: '48px',
    maxWidth: '416px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  submitButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface PopupProps {
  isOpen: boolean;
  headlineText: string;
  contentText: string;
  mainButtonText?: string;
  secondaryButtonText?: string;
  PopupContentComponent?: () => React.JSX.Element;
  onMainButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const Popup = ({
  isOpen,
  headlineText,
  contentText,
  mainButtonText,
  secondaryButtonText,
  PopupContentComponent,
  onMainButtonClick,
  onSecondaryButtonClick,
}: PopupProps) => {
  const { classes } = useStyles();

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={onSecondaryButtonClick}>
      <IconButton
        aria-label="close"
        onClick={onSecondaryButtonClick}
        sx={(theme) => ({
          position: 'absolute',
          top: 32,
          right: 32,
          color: theme.palette.common.white,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>{headlineText}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>{contentText}</DialogContentText>
        {PopupContentComponent && <PopupContentComponent />}
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.submitButton)}
          onClick={onMainButtonClick}
        >
          {mainButtonText || 'OK'}
        </Button>
        {onSecondaryButtonClick && (
          <Button
            className={clsx(classes.defaultButtonStyle, classes.cancelButton)}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonText || 'Cancel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
