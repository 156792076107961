import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import { createDataRequest } from '../../../api/requestsApi/dataApi';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import FormTextField from '../../../components/formFields/FormTextField';
import { DATA_CREATE_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { dataCreateFormSchema } from '../../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../../hooks/useSnackBar';
import SearchCasePopup from '../../../components/SearchCasePopup';
import {
  CaseShortData,
  ErrorData,
  PaginationQueryParams,
  QuestionnaireData,
  QuestionnaireSection,
  QuestionnaireShortData,
} from '../../../types';
import { DataCreateFormSubmitProps } from '../types';
import {
  getQuestionnaire,
  getQuestionnaires,
} from '../../../api/questionnairesApi/questionnairesApi';
import { FieldHeadline } from '../styled';
import CommonButton from '../../../components/buttons/CommonButton';
import CaseInfoPreview from '../../../components/CaseInfoPreview';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import Popup from '../../../components/Popup';
import Loader from '../../../components/Loader';
import FindCaseButton from '../../../components/buttons/FindCaseButton';
import { getCaseRecord } from '../../../api/casesApi/casesApi';

import SectionList from './SectionList';
import QuestionnaireList from './QuestionnaireList';
import ItemList from './ItemList';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: '32px',
    borderRadius: '16px',
    backgroundColor: theme.palette.primary.light,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '40px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: '64px',

    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '416px',
    gap: '32px',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  formButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '416px',
    gap: '16px',

    '@media (max-width: 768px)': {
      alignSelf: 'flex-start',
    },
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.common.white,
  },
}));

const CreateDataFormContainer = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSearchCasePopupOpen, setIsSearchCasePopupOpen] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [caseData, setCaseData] = useState<CaseShortData | null>(null);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<Array<QuestionnaireShortData>>([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireShortData | null>(
    null,
  );
  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData | null>(null);

  const PAGE_SIZE = 15;

  const caseId = new URLSearchParams(window.location.search).get('caseId');

  // TODO: use react hook form instead of this
  // TODO: performance
  const [selectedSections, setSelectedSections] = useState<Array<QuestionnaireSection>>([]);
  const [acceptedItems, setAcceptedItems] = useState<Array<string>>([]);
  const [numberOfItems, setNumberOfItems] = useState(0);

  const formMethods = useForm<DataCreateFormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataCreateFormSchema),
    defaultValues: DATA_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const fetchQuestionnaires = (queryParams: PaginationQueryParams) => {
    setIsFetching(true);

    getQuestionnaires({
      page: queryParams.page,
      size: queryParams.size,
      count: queryParams.count,
      filters: queryParams.filters,
      includeGlobal: true,
    })
      .then((response) => {
        if (response.status === 200) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setQuestionnaires(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setQuestionnaires(Array.from(new Set([...questionnaires, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFetching(false));
  };

  const handleSelectCase = (selectedCase: CaseShortData) => {
    setIsSearchCasePopupOpen(false);
    setCaseData(selectedCase);
    setValue('caseId', selectedCase.id, { shouldDirty: true, shouldValidate: true });

    const filters = {
      caseTypeId: selectedCase.type.id,
      firmId: selectedCase.firm.id,
    };

    fetchQuestionnaires({ page: 1, size: PAGE_SIZE, count: true, filters });
  };

  const handleLoadMoreButtonClick = () => {
    if (totalCount !== questionnaires.length && caseData) {
      fetchQuestionnaires({
        page: page + 1,
        size: PAGE_SIZE,
        count: false,
        filters: {
          caseTypeId: caseData.type.id,
          firmId: caseData.firm.id,
        },
      });
    }
  };

  const handleSelectQuestionnaire = (questionnaire: QuestionnaireShortData) => {
    if (questionnaireData?.id === questionnaire.id) return;

    setIsFetching(true);
    setSelectedQuestionnaire(questionnaire);
    setQuestionnaireData(null);
    setSelectedSections([]);
    setAcceptedItems([]);

    getQuestionnaire(questionnaire.id)
      .then((response) => {
        if (response.status === 200) {
          setQuestionnaireData(response.data);
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFetching(false));
  };

  const onSubmitForm = (values: DataCreateFormSubmitProps) => {
    if (!caseData) return;

    setIsFormSubmitting(true);

    const submitData = {
      form:
        acceptedItems.length > 0
          ? {
              sections: selectedSections
                .map((section) => {
                  const items = section.items.filter((item) => acceptedItems.includes(item.id));

                  return {
                    ...section,
                    items,
                  };
                })
                .filter((section) => section.items.length > 0),
            }
          : null,
      caseId: caseData.id,
      name: values.name,
      ...(values.notes && { notes: values.notes }),
      ...(values.message && { message: values.message }),
    };

    createDataRequest(submitData)
      .then((response) => {
        if (response.status === 201) {
          navigate(`/requests/data-request/${response.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Data request created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancelButtonClick = () => {
    if (!caseId) {
      setCaseData(null);
      setQuestionnaires([]);
    }

    setSelectedSections([]);
    setSelectedQuestionnaire(null);
    setQuestionnaireData(null);
    setAcceptedItems([]);
    setNumberOfItems(0);
    reset();

    caseId && setValue('caseId', caseData?.id as number);
  };

  const handleSelectSection = (item: QuestionnaireSection) => {
    setSelectedSections([...selectedSections, item]);
  };

  const handleSelectAllSections = () => {
    if (questionnaireData) setSelectedSections([...questionnaireData.form.sections]);
  };

  const handleUnselectSection = (item: QuestionnaireSection) => {
    setSelectedSections(selectedSections.filter((el) => el.items[0].id !== item.items[0].id));
  };

  const handleUnselectAllSections = () => {
    setSelectedSections([]);
  };

  useEffect(() => {
    const num = selectedSections.reduce(
      (accumulator, currentValue) => accumulator + currentValue.items.length,
      0,
    );
    setNumberOfItems(num);
  }, [selectedSections]);

  useEffect(() => {
    if (!caseId) return;

    getCaseRecord(Number(caseId)).then((res) => {
      handleSelectCase(res.data);
    });
  }, [caseId]);

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box className={classes.content}>
          <Box className={classes.section}>
            <Box className={classes.infoBlock}>
              <FieldHeadline>{'Case'}</FieldHeadline>
              {caseData && <CaseInfoPreview caseItem={caseData} />}
              {!caseId && <FindCaseButton onButtonClick={() => setIsSearchCasePopupOpen(true)} />}
            </Box>

            <Box className={classes.infoBlock}>
              <FieldHeadline>{'Plaintiff'}</FieldHeadline>
              <Typography className={classes.subtitle}>
                {caseData ? `${caseData.client.firstName} ${caseData.client.lastName}` : ''}
              </Typography>
            </Box>

            <Box className={classes.infoBlock}>
              <FieldHeadline>{'Case Type'}</FieldHeadline>
              <Typography className={classes.subtitle}>{caseData?.type.name}</Typography>
            </Box>

            <FormProvider {...formMethods}>
              <form className={classes.form}>
                <FieldHeadline>{'Info'}</FieldHeadline>
                <FormTextField name={'name'} label="Name *" />
                <FormTextField name={'notes'} label="Notes" isMultiline />
                <FormTextField name={'message'} label="Message" isMultiline />
              </form>
            </FormProvider>
          </Box>

          <Box className={classes.section}>
            <Box className={classes.infoBlock}>
              <FieldHeadline>{'Questionnaire Section Selections'}</FieldHeadline>

              {questionnaires.length ? (
                <>
                  <QuestionnaireList
                    data={questionnaires}
                    isFetching={isFetching}
                    selectedId={selectedQuestionnaire?.id}
                    totalCount={totalCount}
                    handleSelect={handleSelectQuestionnaire}
                    handleLoadMoreButtonClick={handleLoadMoreButtonClick}
                  />

                  {questionnaireData?.form.sections.length && (
                    <>
                      <Typography className={classes.subtitle}>{'Sections'}</Typography>
                      <SectionList
                        data={questionnaireData.form.sections}
                        selectedSections={selectedSections}
                        handleSelect={handleSelectSection}
                        handleSelectAll={handleSelectAllSections}
                        handleUnselect={handleUnselectSection}
                        handleUnselectAll={handleUnselectAllSections}
                      />

                      {selectedSections.length !== 0 && (
                        <>
                          <Typography className={classes.subtitle}>{'Items'}</Typography>
                          <ItemList
                            acceptedItems={acceptedItems}
                            numberOfItems={numberOfItems}
                            selectedSections={selectedSections}
                            setAcceptedItems={setAcceptedItems}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                !isFetching && (
                  <Typography className={classes.subtitle}>
                    {'No questionnaire available for this Case Type'}
                  </Typography>
                )
              )}
              {isFetching && <Loader colorType="warning" size={40} />}
            </Box>

            <Box className={classes.infoBlock}>
              <FieldHeadline>{'Custom Questions'}</FieldHeadline>

              <CommonButton
                type="infoDark"
                buttonText="Add Questions"
                maxWidth="220px"
                buttonIcon={<PostAddOutlinedIcon />}
                onButtonClick={() => setIsPopupOpen(true)}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.formButtonsWrapper}>
          <FormSubmitButton
            disabled={!isValid || isFormSubmitting}
            buttonIcon={<SaveIcon />}
            buttonText="Save"
            fontFamily="Inter"
            onClick={handleSubmit(onSubmitForm)}
          />
          <FormCancelButton onClick={handleCancelButtonClick} disabled={!isDirty} />
        </Box>
      </Box>

      <SearchCasePopup
        isOpen={isSearchCasePopupOpen}
        onMainButtonClick={handleSelectCase}
        onSecondaryButtonClick={() => setIsSearchCasePopupOpen(false)}
      />
      <Popup
        isOpen={isPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.createDataRequestPage.headlineText}
        contentText={POPUP_DESCRIPTIONS.createDataRequestPage.contentText}
        onMainButtonClick={() => setIsPopupOpen(false)}
      />
    </Box>
  );
};

export default CreateDataFormContainer;
