import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Theme } from '@mui/material';

import { ReactComponent as LogoIcon } from './assets/landing-logo.svg';
import RequestDemoButton from './RequestDemoComponents/RequestDemoButton';
import SideMenu from './SideMenu';

import useScrollPosition from '../../hooks/useScrollPosition';
import RequestDemoPopup from './RequestDemoComponents/RequestDemoPopup';
import RequestDemoSuccessPopup from './RequestDemoComponents/RequestDemoSuccessPopup';

const useStyles = makeStyles()((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    height: '72px',
    maxWidth: '1200px',
    margin: '0 auto',

    '@media (max-width: 900px)': {
      justifyContent: 'space-between',
    },
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '16px',

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  headerLink: {
    padding: '10px 16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    textDecoration: 'none',
    color: '#283645',

    p: {
      color: '#283645',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      fontFamily: 'Inter',
    },
  },
  rightHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginLeft: 'auto',

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  stickyHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    height: '72px',
  },
  headerWrapper: {
    background: 'none',
    position: 'static',

    '@media (max-width: 768px)': {
      marginBottom: 0,
    },
  },
  headerWrapperSticky: {
    position: 'sticky',
    zIndex: 200,
    background:
      'linear-gradient(252deg, rgba(37, 157, 168, 0.19) 14.11%, rgba(37, 157, 168, 0.00) 45%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    boxShadow: '0px 4px 4px 0px rgba(0, 102, 124, 0.10)',
    width: '100%',
    top: 0,
  },
}));

interface HeaderProps {
  handleOpenPopup: () => void;
  windowWidth: number;
  isRequestDemoPopupOpen: boolean;
  handleClosePopup: () => void;
}

const Header = ({
  handleOpenPopup,
  windowWidth,
  isRequestDemoPopupOpen,
  handleClosePopup,
}: HeaderProps) => {
  const styles = useStyles();
  const scrollPosition = useScrollPosition();
  const isLandingPageRoute = useLocation().pathname === '/';

  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean>(false);

  const handleNavigateToSection = (event: React.SyntheticEvent) => {
    setIsSideMenuOpen(false);

    event.preventDefault();
    const target = event.target as HTMLAnchorElement;
    const id = target.getAttribute('id')?.replace('#', '');
    const element = document.getElementById(String(id));

    const offset = id === 'howitworks' ? 200 : 150;

    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      });
    }
  };

  useEffect(() => {
    windowWidth > 900 && setIsSideMenuOpen(false);
  }, [windowWidth]);

  useEffect(() => {
    isSideMenuOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');
  }, [isSideMenuOpen]);

  return (
    <Box
      className={clsx(
        scrollPosition > 0 ? styles.classes.headerWrapperSticky : styles.classes.headerWrapper,
      )}
    >
      <Box className={styles.classes.headerContainer}>
        <Box paddingRight="130px">
          <Link to="/">
            <LogoIcon />
          </Link>
        </Box>

        {isLandingPageRoute && (
          <Box
            className={styles.classes.linksContainer}
            onClick={(e: React.SyntheticEvent) => handleNavigateToSection(e)}
          >
            <a className={styles.classes.headerLink} href="#whyv" id="#whyv">
              <p id="#whyv">
                Why <strong id="#whyv">v</strong>?
              </p>
            </a>
            <a className={styles.classes.headerLink} id="#features" href="#features">
              Features
            </a>
            <a className={styles.classes.headerLink} href="#howitworks" id="#howitworks">
              How It Works
            </a>
          </Box>
        )}

        <Box className={styles.classes.rightHeaderContainer}>
          <Link className={styles.classes.headerLink} to="/sign-in">
            Sign In
          </Link>
          <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="16px" />
        </Box>

        {windowWidth <= 900 && (
          <IconButton onClick={() => setIsSideMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}

        {windowWidth <= 900 && isSideMenuOpen && (
          <SideMenu
            handleOpenPopup={handleOpenPopup}
            handleCloseMenu={() => setIsSideMenuOpen(false)}
            handleNavigateToSection={handleNavigateToSection}
            handleCloseSideBar={() => setIsSideMenuOpen(false)}
            isLandingPageRoute={isLandingPageRoute}
          />
        )}
      </Box>

      <RequestDemoPopup
        isOpen={isRequestDemoPopupOpen}
        onClose={handleClosePopup}
        setIsSuccessPopupOpen={setIsSuccessPopupOpen}
      />
      <RequestDemoSuccessPopup isOpen={isSuccessPopupOpen} />
    </Box>
  );
};

export default Header;
