import { ExpectedAnswer } from '../../../constants/constants';
import { QuestionnaireForm, QuestionnaireItemFormat } from '../../../types';
import { QuestionnaireSubmitForm } from '../types';

export const mapQuestionnaireData = (form: QuestionnaireSubmitForm) => {
  const mappedForm: QuestionnaireForm = {
    sections: form.sections.map((section) => {
      return {
        title: section.title,
        items: section.items.map((item) => {
          return {
            id: item.id,
            code: item.code,
            format: item.format,
            systemName: item.systemName,
            questionText: item.questionText,
            immutable: item.immutable,
            dependsOnItem: item?.dependsOnItem?.booleanItemId
              ? {
                  booleanItemId: item.dependsOnItem.booleanItemId,
                  expectedValue: item.dependsOnItem.expectedValue === ExpectedAnswer.Yes,
                }
              : null,
            ...(item.format === QuestionnaireItemFormat.DateRange && {
              allowPartial: item.allowPartial,
            }),
            ...(item.format === QuestionnaireItemFormat.Text && {
              placeholder: item.placeholder || null,
            }),
          };
        }),
        immutable: section.immutable,
      };
    }),
  };

  return mappedForm;
};
