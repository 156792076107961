import { Box } from '@mui/material';

import { bankingAccountTabs } from '../../constants/tabRoutes';

import TabsPanel from '../../components/TabsPanel';

const FirmBankingView = () => {
  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel tabsList={bankingAccountTabs} locationSlicePosition={2} />
      </Box>
    </>
  );
};

export default FirmBankingView;
