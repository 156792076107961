import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

// import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { DeductionEditFormSubmitProps } from '../types';

import {
  deleteDeductionRecord,
  updateDeductionRecord,
} from '../../../api/paymentsApi/deductionsApi';
import CommonButton from '../../../components/buttons/CommonButton';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormToggleField from '../../../components/formFields/FormToggleField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormSelectField from '../../../components/formFields/FormSelectField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import { DEDUCTION_TYPES } from '../../../constants/constants';
import { deductionsEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../../types';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 900px)': {
      flexBasis: '100%',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 900px)': {
      flexBasis: '416px',
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formFirstInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 900px)': {
      flexBasis: 0,
    },
  },

  formSecondInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 900px)': {
      flexBasis: 0,
      marginTop: '32px',
    },
  },
  typeSectionHeadline: {
    marginTop: '40px',

    '@media (max-width: 900px)': {
      marginTop: '32px',
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 900px)': {
      flexBasis: 0,
    },
  },
  formActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '200px',

    '@media (max-width: 900px)': {
      flexBasis: 0,
    },
  },
}));

const DeductionsEditFormContainer = () => {
  const styles = useStyles();
  const { deductionId } = useParams();
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(deductionsEditFormSchema),
    defaultValues: DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const caseIdValue = watch('caseReferenceId');
  const defendantIdValue = watch('defendant.id');
  const isAssignedToggleValue = watch('isAssigned');

  const onDeleteButtonClick = () => handleOpenDeletePopup();

  const handleDeleteDeduction = () => {
    deleteDeductionRecord(deductionId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Deduction deleted successfully.',
          type: 'success',
        }));
        navigate('/payments/deductions');
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        handleCloseDeletePopup();
      });
  };

  const onSubmitForm = (values: DeductionEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      defendantId: !values.defendant.id?.length ? null : Number(values.defendant.id),
      amount: values.amount,
      type: values.type,
    };

    updateDeductionRecord(deductionId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/payments/deductions');

          setAlert((prev) => ({
            ...prev,
            message: 'Deduction updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);

  useEffect(() => {
    if (entityData) {
      setPageTitle && setPageTitle(entityData.type as string);

      reset({
        ...entityData,
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  useEffect(() => {
    if (!isAssignedToggleValue) {
      setValue('defendant', { id: '', label: '' });
    }
  }, [isAssignedToggleValue, setValue]);

  return (
    <>
      <Box className={styles.classes.pageContainer}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.formContainer}>
            <Box className={styles.classes.formInfoContainer}>
              <Box className={styles.classes.formInnerWrapper}>
                <Box className={styles.classes.formWrapper}>
                  <Box className={styles.classes.formFirstInfoBlock}>
                    <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                    <Box mt="16px">
                      <FormCopyTextField entityName="Deduction" name="id" label="Id" isReadOnly />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      mt="32px"
                    >
                      <Typography className={styles.classes.fieldHeadline}>Case</Typography>
                      <FormLinkButton path={`/cases/${caseIdValue}/home`} />
                    </Box>
                    <Box mt="16px">
                      <FormTextField
                        name="caseReferenceId"
                        label="ReferenceId"
                        readOnly
                        isReadOnly
                      />
                    </Box>
                    <Box mt="24px">
                      <FormTextField
                        name="caseSystemName"
                        label="System Name"
                        readOnly
                        isReadOnly
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt="32px"
                    >
                      <Typography className={styles.classes.fieldHeadline}>Defendant</Typography>
                      <Box display="flex" gap="16px" alignItems="center" paddingLeft="10px">
                        <FormToggleField name="isAssigned" label="Assigned" />
                        <FormLinkButton
                          path={defendantIdValue ? `/defendants/${defendantIdValue}/home` : '#'}
                          disabled={!defendantIdValue}
                        />
                      </Box>
                    </Box>
                    <Box mt="16px">
                      <FormAutocompleteField
                        name="defendant"
                        label="Defendant"
                        optionsQuery={handleGetDefendants}
                        mapQueryResults={mapDefendantsQueryResults}
                        disabled={!isAssignedToggleValue}
                        filters={{ caseId: caseIdValue }}
                      />
                    </Box>
                  </Box>

                  <Box className={styles.classes.formSecondInfoBlock}>
                    <Typography className={styles.classes.fieldHeadline}>Amount</Typography>
                    <Box mt="16px">
                      <FormTextField name="amount" label="Amount *" />
                    </Box>

                    <Typography className={styles.classes.fieldHeadline} mt="32px">
                      Type
                    </Typography>
                    <Box mt="16px">
                      <FormSelectField
                        name="type"
                        label="Type *"
                        options={DEDUCTION_TYPES}
                        width="100%"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" width="100%" justifyContent="center">
                  <Box
                    display="flex"
                    mt="32px"
                    maxWidth="416px"
                    flexDirection="column"
                    gap="16px"
                    width="100%"
                  >
                    <FormSubmitButton
                      disabled={
                        !isValid ||
                        !isDirty ||
                        (isAssignedToggleValue && !defendantIdValue) ||
                        isFormSubmitting
                      }
                      buttonIcon={<SaveIcon />}
                      buttonText="Save"
                      onClick={handleSubmit(onSubmitForm)}
                    />
                    <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                  </Box>
                </Box>
              </Box>

              <Box className={styles.classes.formActionsWrapper}>
                <Typography className={styles.classes.fieldHeadline}>Actions</Typography>
                <Box mt="16px">
                  <CommonButton
                    disabled={entityData?.status !== 'Unpaid'}
                    type="error"
                    buttonText="delete"
                    buttonIcon={<CancelIcon />}
                    onButtonClick={onDeleteButtonClick}
                  />
                </Box>
                {/* <Box mt="16px">
                  <CommonButton
                    disabled
                    type="info"
                    buttonText="archive"
                    buttonIcon={<ArchiveIcon />}
                  />
                </Box> */}
              </Box>
            </Box>
          </form>
        </FormProvider>
        <Popup
          isOpen={isDeletePopupOpen}
          headlineText={POPUP_DESCRIPTIONS.deductionDelete.headlineText}
          contentText={POPUP_DESCRIPTIONS.deductionDelete.contentText}
          onMainButtonClick={handleDeleteDeduction}
          onSecondaryButtonClick={handleCloseDeletePopup}
        />
      </Box>
    </>
  );
};

export default DeductionsEditFormContainer;
