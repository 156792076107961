import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FirmUnrestrictedAccountForm from './FirmUnrestrictedAccountForm';

import { FirmUnrestrictedAccountShortData, FirmUnrestrictedAccountSubmitFormProps } from '../types';

import { createFirmUnrestrictedAccount } from '../../../api/firmsApi/unrestrictedAccountsApi';
import { firmBankingCreateUnrestrictedAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../../hooks/useSnackBar';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      maxWidth: '800px',
      width: '100%',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '34px',
      fontWeight: 600,
      lineHeight: '120%',
      color: theme.palette.common.white,
      fontFamily: 'Poppins',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    margin: '0 auto',
    gap: '16px',
    padding: 0,
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FirmUnrestrictedAccountPopupProps {
  isOpen: boolean;
  headlineText: string;
  selectedTableItem: FirmUnrestrictedAccountShortData | null;
  fetchUnrestrictedAccounts: () => void;
  handleClosePopup: () => void;
  handleEditAccount: (data: FirmUnrestrictedAccountSubmitFormProps) => void;
  handleResetFormState: () => void;
}

const FirmUnrestrictedAccountPopup = ({
  isOpen,
  headlineText,
  selectedTableItem,
  fetchUnrestrictedAccounts,
  handleClosePopup,
  handleEditAccount,
  handleResetFormState,
}: FirmUnrestrictedAccountPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const firmId = localStorage.getItem('firmId');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmBankingCreateUnrestrictedAccountFormSchema),
    defaultValues: FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleAddNewAccount = (accountFormData: FirmUnrestrictedAccountSubmitFormProps) => {
    if (!firmId) return;

    setIsFormSubmitting(true);

    createFirmUnrestrictedAccount(firmId, accountFormData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Unresrticted account created successfully.',
            type: 'success',
          }));

          handleClosePopup();
          fetchUnrestrictedAccounts();
          reset(FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES);
          setIsFormSubmitting(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: FirmUnrestrictedAccountSubmitFormProps) => {
    const submitData = {
      accountNumber: values.accountNumber,
      routingNumber: values.routingNumber,
      name: values.name,
      isVerified: values.isVerified,
    };

    selectedTableItem ? handleEditAccount(submitData) : handleAddNewAccount(submitData);
  };

  const onPopupClose = () => {
    handleResetFormState();
    reset(FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES);
  };

  useEffect(() => {
    selectedTableItem
      ? reset({
          accountNumber: selectedTableItem.accountNumber,
          routingNumber: selectedTableItem.routingNumber,
          name: selectedTableItem.name,
          isVerified: selectedTableItem.isVerified,
        })
      : reset({ ...FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES });
  }, [selectedTableItem]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          onClick={onPopupClose}
          disableRipple
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogTitle>{headlineText}</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <FirmUnrestrictedAccountForm />
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.popupActions}>
        <FormSubmitButton
          disabled={!isValid || !isDirty || isFormSubmitting}
          buttonText="Submit"
          onClick={handleSubmit(onSubmitForm)}
        />

        <FormCancelButton onClick={onPopupClose} />
      </DialogActions>
    </Dialog>
  );
};

export default FirmUnrestrictedAccountPopup;
