import { QuestionnaireItemFormat, SelectOption } from '../../../types';
import { FormSnippetSubmitData } from '../types';

export const extractBooleanItems = (data: FormSnippetSubmitData) => {
  const booleanItems: SelectOption[] = [{ id: '', label: '-' }];

  for (const item of data.items) {
    if (item.format === QuestionnaireItemFormat.Boolean)
      booleanItems.push({ id: item.id, label: item.systemName });
  }

  return booleanItems;
};
