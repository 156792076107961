import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  timerText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '120%',
    color: theme.palette.text.secondary,
  },
}));

interface CountdownTimerProps {
  isCountdownActive: boolean;
  onCountdownEnd?: () => void;
  countdownTime?: number;
}

const CountdownTimer = ({
  isCountdownActive,
  onCountdownEnd,
  countdownTime,
}: CountdownTimerProps) => {
  const { classes } = useStyles();
  const initialTime = countdownTime || 30;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (isCountdownActive) {
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerInterval);
            onCountdownEnd && onCountdownEnd();
            return 0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [isCountdownActive]);

  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <Box>
      <Typography className={classes.timerText}>
        {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
