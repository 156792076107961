import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { CaseShortData } from '../types';

const useStyles = makeStyles()((theme: Theme) => ({
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '300px',
    overflowY: 'auto',

    '@media (max-width: 550px)': {
      paddingRight: '10px',
    },
  },
  resultItemContainer: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.19)',
    padding: '10px',
    borderRadius: '5px',
    gap: '20px',
    flexDirection: 'column',

    '@media (max-width: 550px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  },
  resultItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33%',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  resultItemColumnLabel: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#93959E',
    fontFamily: 'Inter',
  },
  resultItemColumnText: {
    fontSize: '16px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    color: theme.palette.common.white,
    maxWidth: '300px',

    '@media (max-width: 650px)': {
      maxWidth: '220px',
    },

    '@media (max-width: 550px)': {
      maxWidth: '240px',
    },
  },
  resultItemActiveBgColor: {
    backgroundColor: 'rgba(255, 255, 255, 0.19)',

    '@media (max-width: 550px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      flexBasis: '33%',
      whiteSpace: 'nowrap',
      minWidth: 0,
    },
  },
}));

interface CaseInfoPreviewProps {
  caseItem: CaseShortData;
}

const CaseInfoPreview = ({ caseItem }: CaseInfoPreviewProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.searchResultsBlock}>
      <Box className={clsx(styles.classes.resultItemContainer)}>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>Reference ID</Typography>
          <Typography className={styles.classes.resultItemColumnText}>
            {caseItem.referenceId}
          </Typography>
        </Box>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>System Name</Typography>
          <Typography className={styles.classes.resultItemColumnText}>{caseItem.name}</Typography>
        </Box>
        <Box className={styles.classes.resultItemColumn}>
          <Typography className={styles.classes.resultItemColumnLabel}>Firm</Typography>
          <Typography className={styles.classes.resultItemColumnText}>
            {caseItem.firm.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseInfoPreview;
