import moment from 'moment';

export const getDatesDiff = (date1: string, date2: string) => {
  const startDate = moment(date1);
  const finishDate = moment(date2);

  const duration = moment.duration(finishDate.diff(startDate));

  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.asDays()) % 30;
  const hours = Math.floor(duration.asHours()) % 24;

  let result = '';

  if (months > 0) {
    result += `${months}m `;
  }

  if (days > 0) {
    result += `${days}d `;
  }

  if (hours > 0) {
    result += `${hours}h `;
  } else if (result === '') {
    result += '< 1h';
  }

  return result;
};
