import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { AnyObject } from 'yup';
import moment from 'moment';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as LogoIcon } from '../LandingView/assets/logo.svg';

import {
  confirmClientEmail,
  getClientDataByEmailConfirmationToken,
} from '../../api/clientsApi/clientsApi';
import Loader from '../../components/Loader';
import FormDateField from '../../components/formFields/FormDateField';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { emailConfirmationFormSchema } from '../../helpers/formSchemas/formSchemas';
import { EMAIL_CONFIRMATION_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../hooks/useSnackBar';
import { ErrorData } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  pageBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',

    '@media (max-width: 640px)': {
      padding: '20px',
    },

    '@media (max-height: 550px)': {
      height: '100%',
      padding: '20px',
    },
  },
  signInLogoContainer: {
    marginBottom: '30px',
    svg: {
      color: theme.palette.common.white,
    },
  },
  formContentText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  formContainer: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: '32px',
    maxWidth: '480px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    width: '100%',
  },
  formTitle: {
    fontSize: '34px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '40px',
  },
  supportLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '120%',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
  welcomeText: {
    fontSize: '34px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    color: theme.palette.common.white,
    marginBottom: '20px',
  },
}));

interface ConfirmClientEmailFormProps {
  ssn: string;
  birthdate: string | Date | AnyObject | null;
}

const EmailConfirmView = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [isClientDataLoading, setIsClientDataLoading] = useState<boolean>(true);
  const [isConfirmEmailFormSubmiting, setIsConfirmEmailFormSubmiting] = useState<boolean>(false);
  const [clientData, setClientData] = useState<{
    token: 'string';
    isTokenExpired: true;
    clientFirstName: 'string';
  }>();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(emailConfirmationFormSchema),
    defaultValues: EMAIL_CONFIRMATION_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit = (values: ConfirmClientEmailFormProps) => {
    setIsConfirmEmailFormSubmiting(true);

    const submitData = {
      ssn: values.ssn,
      birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
      confirmationToken: token,
    };

    confirmClientEmail(submitData)
      .then((res) => {
        if (res.status === 204) {
          setAlert((prev) => ({
            ...prev,
            type: 'success',
            message: 'Email has been successfully confirmed.',
          }));

          navigate('/');
        }
      })
      .catch((err) => {
        setIsConfirmEmailFormSubmiting(false);
        setAlert((prev) => ({
          ...prev,
          type: 'error',
          message: err.response.data.message,
        }));
      });
  };

  useEffect(() => {
    if (token?.length) {
      getClientDataByEmailConfirmationToken(token as string)
        .then((res) => {
          if (!res.data.isTokenExpired) {
            setClientData(res.data);
            setIsClientDataLoading(false);
          }
        })
        .catch((err: AxiosError<ErrorData>) => {
          const errorMessage = err.response?.data.message || 'Error. Something went wrong...';

          setAlert((prev) => ({
            ...prev,
            message: errorMessage,
            type: 'error',
          }));
          navigate('/');
        });
    } else {
      navigate('/');
    }
  }, [token]);

  if (isClientDataLoading) {
    return (
      <Box className={classes.pageBackground}>
        <Box mb="100px">
          <Loader colorType="warning" />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.pageBackground}>
      <Box className={classes.signInLogoContainer}>
        <Link to="/">
          <LogoIcon />
        </Link>
      </Box>

      {clientData && (
        <>
          <Typography
            className={classes.welcomeText}
          >{`Hello, ${clientData.clientFirstName}`}</Typography>
          <Box className={classes.formContainer}>
            <FormProvider {...formMethods}>
              <Typography className={classes.formContentText}>
                To finish the confirmation,
              </Typography>
              <Typography className={classes.formContentText} mb="0px">
                please enter your SSN and Date of Birth.
              </Typography>

              <Box mt="32px">
                <FormMaskedTextField
                  mask="999-99-9999"
                  name="ssn"
                  label="Social Security Number *"
                />
              </Box>

              <Box mt="24px">
                <FormDateField name="birthdate" label="Date of Birth *" maxDate={moment()} />
              </Box>

              <Box display="flex" mt="32px">
                <FormSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  buttonText="Submit"
                  fontFamily="Inter"
                  disabled={!isValid || isConfirmEmailFormSubmiting}
                />
              </Box>
            </FormProvider>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EmailConfirmView;
