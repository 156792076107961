export const palette = {
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: {
    main: '#282B3A',
    light: '#35394B',
    dark: '#1E1E1E',
  },
  secondary: {
    main: 'rgba(255, 255, 255, 0.7)',
    dark: '#4A4E5D',
    light: 'rgba(255, 255, 255, 0.19)',
    contrastText: '',
  },
  info: {
    main: '#00667C',
    light: '#57748C',
    dark: '#06404D',
  },
  warning: {
    main: '#F4B139',
  },
  error: {
    main: '#FF8663',
  },
  success: {
    main: '#ADDC7B',
  },
  text: {
    primary: '#fff',
    secondary: '#C3C4C8',
    disabled: '#767885',
  },
};
