import { useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { AuthContext } from '../providers/AuthProvider';
import { getUser } from '../api/usersApi/usersApi';

const useAuth = () => {
  const {
    token,
    refreshToken,
    onLogOut,
    handleSendForgotPasswordEmail,
    handleResetPassword,
    isAuth,
    changeAuth,
  } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(true);

  const stopLoading = () => {
    setLoading(false);
  };

  const checkAuth = async () => {
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      const userTokenData: { [key: string]: string } = jwtDecode(token);
      const res = await getUser(userTokenData.username);
      if (res.data) {
        localStorage.setItem('role', res.data.role);
        localStorage.setItem('firmId', res.data.firm.id);
        localStorage.setItem('userId', res.data.id.toString());
      }

      if (changeAuth) {
        changeAuth(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    token,
    refreshToken,
    onLogOut,
    handleSendForgotPasswordEmail,
    handleResetPassword,
    isAuth,
    changeAuth,
    loading,
    stopLoading,
    checkAuth,
  };
};

export default useAuth;
