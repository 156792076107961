import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton, Theme, Typography } from '@mui/material';

import ClientActivationsButtonGroup from './ClientActivationsButtonGroup';

import { ClientEditFormSubmitProps } from './types';

import {
  getClientBirthDate,
  getClientSSN,
  resetClientAccount,
  sendEmailConfirmation,
  updateClientRecord,
} from '../../api/clientsApi/clientsApi';
import Loader from '../../components/Loader';
import CommonButton from '../../components/buttons/CommonButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { CLIENT_ACTIVATION_BUTTON_GROUPS, US_STATES_LIST } from '../../constants/constants';
import { CLIENT_EDIT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import {
  clientEditFormSchema,
  personalInfoFormSchema,
} from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import { ClientData, EntityFlexibleRecord } from '../../types';
import FormSSNField from '../../components/formFields/FormSSNField';
import ClientUpdateSSNDobForm from './ClientUpdateSSNDobForm';
import ClientActivationsContainer from './ClientActivationsContainer';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 800px)': {
      flexBasis: '480px',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 1000px)': {
      flexBasis: '416px',
    },

    '@media (max-width: 800px)': {
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '24px',
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',

    '@media (max-width: 1000px)': {
      gap: '24px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '200px',

    '@media (max-width: 800px)': {
      flexBasis: 0,
    },
  },
  editPersonalInfoButton: {
    padding: 0,
    svg: {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled': {
      svg: {
        color: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
}));

const ClientsEditFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const [isSSNValueVisible, setIsSSNValueVisible] = useState<boolean>(false);
  const [isSSNLoading, setIsSSNLoading] = useState<boolean>(false);
  const [isBirthDateValueVisible, setIsBirthDateValueVisible] = useState<boolean>(false);
  const [isBirthDateLoading, setIsBirthDateLoading] = useState<boolean>(false);
  const [isEditPersonalInfoPopupOpen, setIsEditPersonalInfoPopupOpen] = useState<boolean>(false);
  const [editPersonalFormData, setEditPersonalFormData] = useState<{
    ssn: string;
    birthdate: Date | null;
  }>({
    ssn: '',
    birthdate: null,
  });
  const [accountActivationsState, setAccountActivationsState] = useState({
    email: {
      label: 'EMAIL',
      isActive: false,
    },
    ssn: {
      label: 'SSN',
      isActive: false,
    },
    dob: {
      label: 'DATE OF BIRTH',
      isActive: false,
    },
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const isSendAppInviteAllowed = Object.values(accountActivationsState).every(
    (state) => state.isActive,
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientEditFormSchema),
    defaultValues: CLIENT_EDIT_FORM_DEFAULT_VALUES,
  });

  const personalInfoFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(personalInfoFormSchema),
    defaultValues: {
      birthdate: moment(),
      ssn: '***-**-****',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const {
    setValue,
    watch,
    formState: { isDirty: personalFormIsDirty },
  } = personalInfoFormMethods;

  const ssn = watch('ssn');
  const birthdate = watch('birthdate');

  const onSubmitForm = (values: ClientEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      address2: values.address2?.length ? values.address2 : null,
      middleName: values.middleName?.length ? values.middleName : null,
      referenceId: values.referenceId,
      firstName: values.firstName,
      lastName: values.lastName,
      address1: values.address1,
      city: values.city,
      countryState: values.state,
      zip: values.zip,
    };

    updateClientRecord(clientId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/clients');

          setAlert((prev) => ({
            ...prev,
            message: 'Client updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    setIsBirthDateValueVisible(false);
    setIsSSNValueVisible(false);
    reset();
  };

  const handleResetClientAccount = () => {
    resetClientAccount(clientId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'App invitation has been sent successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const getSSNFieldValue = () => {
    if (!clientId) return;

    if (ssn !== '***-**-****') {
      setIsSSNValueVisible(!isSSNValueVisible);
      return;
    }

    setIsSSNLoading(true);

    getClientSSN(Number(clientId))
      .then((res) => {
        setValue('ssn', res.data.ssn);
        setIsSSNValueVisible(true);
      })
      .catch(() => setIsSSNValueVisible(false))
      .finally(() => setIsSSNLoading(false));
  };

  const getBirhtDateFieldValue = () => {
    if (!clientId) return;

    if (!moment(birthdate).isSame(moment(), 'day')) {
      setIsBirthDateValueVisible(!isBirthDateValueVisible);
      return;
    }

    setIsBirthDateLoading(true);

    getClientBirthDate(Number(clientId))
      .then((res) => {
        setValue('birthdate', moment(res.data.birthdate));
        setIsBirthDateValueVisible(true);
      })
      .catch(() => setIsBirthDateValueVisible(false))
      .finally(() => setIsBirthDateLoading(false));
  };

  const handleSendEmailConfirmation = () => {
    sendEmailConfirmation(Number(clientId))
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Email confirmation has been sent successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onEditPersonalButtonClick = () => {
    onEditPersonalInfoPopupOpen();
    setIsEditPersonalInfoPopupOpen(true);
  };

  const onEditPersonalInfoPopupOpen = () => {
    getClientSSN(Number(clientId)).then((res) =>
      setEditPersonalFormData((prevState) => ({
        ...prevState,
        ssn: res.data.ssn,
      })),
    );

    getClientBirthDate(Number(clientId)).then((res) =>
      setEditPersonalFormData((prevState) => ({
        ...prevState,
        birthdate: moment(res.data.birthdate) as unknown as Date,
      })),
    );
  };

  const handleUpdateNewSSNDoBvalues = (newValues: { ssn: string; birthdate?: Date | null }) => {
    setValue('ssn', newValues.ssn, { shouldDirty: true });
    setValue('birthdate', moment(newValues.birthdate), {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (entityData) {
      const data: ClientData = entityData as ClientData;

      reset({
        id: data.id.toString(),
        firstName: data.firstName,
        middleName: data.middleName || '',
        lastName: data.lastName,
        referenceId: data.referenceId,
        email: data.email,
        phone: data.phone,
        address1: data.address1,
        address2: data.address2 || '',
        city: data.city,
        state: data.countryState,
        zip: data.zip,
      });

      setPageTitle && setPageTitle(`${entityData.firstName} ${entityData.lastName}`);
      setAccountActivationsState({
        email: {
          label: 'EMAIL',
          isActive: data.emailConfirmed,
        },
        ssn: {
          label: 'SSN',
          isActive: data.ssnConfirmed,
        },
        dob: {
          label: 'DATE OF BIRTH',
          isActive: data.dobConfirmed,
        },
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      {!entityData ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.pageContainer}>
            <form className={styles.classes.formContainer}>
              <Box className={styles.classes.formInfoContainer}>
                <Box className={styles.classes.formInnerWrapper}>
                  <Box className={styles.classes.formWrapper}>
                    <FormProvider {...formMethods}>
                      <Box display="flex" flexDirection="column" flexBasis="416px">
                        <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                        <Box mt="16px">
                          <FormCopyTextField entityName="Client" name="id" label="Id" isReadOnly />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="referenceId" label="Reference Id *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="firstName" label="First Name *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="middleName" label="Middle Name" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="lastName" label="Last Name *" />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Credentials
                        </Typography>
                        <Box mt="16px">
                          <FormTextField name="email" label="Email Address *" disabled />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="phone" label="Mobile Number *" disabled />
                        </Box>
                      </Box>
                    </FormProvider>
                    <Box display="flex" flexDirection="column" flexBasis="416px">
                      <FormProvider {...formMethods}>
                        <Typography className={styles.classes.fieldHeadline}>Address</Typography>
                        <Box mt="16px">
                          <FormTextField name="address1" label="Address Line 1 *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="address2" label="Address Line 2" />
                        </Box>
                        <Box className={styles.classes.formPlaceInfoContainer}>
                          <FormTextField name="city" label="City *" />
                          <FormSelectField
                            options={US_STATES_LIST}
                            name="state"
                            label="State *"
                            width="100%"
                          />
                          <FormTextField name="zip" label="Zip *" />
                        </Box>
                      </FormProvider>

                      <FormProvider {...personalInfoFormMethods}>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                          <Typography className={styles.classes.fieldHeadline} mt="32px">
                            Personal Information
                          </Typography>
                          <IconButton
                            className={styles.classes.editPersonalInfoButton}
                            onClick={onEditPersonalButtonClick}
                            disableFocusRipple
                            disabled={
                              accountActivationsState.dob.isActive &&
                              accountActivationsState.ssn.isActive
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Box>
                        <Box mt="16px">
                          <FormDateField
                            name="birthdate"
                            label="Date of Birth *"
                            maxDate={moment()}
                            readonly
                            isEncrypted
                            isEncryptedValueLoading={isBirthDateLoading}
                            isValueVisible={isBirthDateValueVisible}
                            onRightIconClick={getBirhtDateFieldValue}
                          />
                        </Box>
                        <Box mt="24px">
                          <FormSSNField
                            name="ssn"
                            label="Social Security Number *"
                            isEncrypted
                            isEncryptedValueLoading={isSSNLoading}
                            isValueVisible={isSSNValueVisible}
                            onRightIconClick={getSSNFieldValue}
                          />
                        </Box>
                      </FormProvider>

                      <Typography className={styles.classes.fieldHeadline} mt="32px">
                        Confirmations
                      </Typography>

                      <Box mt="12px" width="100%">
                        <ClientActivationsContainer
                          buttonGroupLabel="identity"
                          maxWidth="100px"
                          accountActivationsState={accountActivationsState}
                        />
                      </Box>

                      <Typography className={styles.classes.fieldHeadline} mt="32px">
                        Activations
                      </Typography>
                      <Box mt="12px" width="100%">
                        <ClientActivationsButtonGroup
                          buttonGroupLabel="app"
                          buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.app}
                          maxWidth="100px"
                        />
                      </Box>

                      <Box mt="16px">
                        <ClientActivationsButtonGroup
                          buttonGroupLabel="virtual card"
                          buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.virtualCard}
                          maxWidth="80px"
                        />
                      </Box>

                      <Box mt="16px">
                        <ClientActivationsButtonGroup
                          buttonGroupLabel="physical card"
                          buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.physicalCard}
                          maxWidth="90px"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" width="100%" justifyContent="center">
                    <Box
                      display="flex"
                      mt="32px"
                      maxWidth="416px"
                      flexDirection="column"
                      gap="16px"
                      width="100%"
                    >
                      <FormSubmitButton
                        disabled={
                          !isValid || personalFormIsDirty ? false : !isDirty || isFormSubmitting
                        }
                        buttonIcon={<SaveIcon />}
                        buttonText="Save"
                        onClick={handleSubmit(onSubmitForm)}
                      />
                      <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                    </Box>
                  </Box>
                </Box>

                <Box className={styles.classes.formActionsWrapper}>
                  <Typography className={styles.classes.fieldHeadline}>Actions</Typography>

                  <Box mt="16px">
                    <CommonButton
                      type="success"
                      buttonText="send app invite"
                      onButtonClick={handleResetClientAccount}
                      disabled={!isSendAppInviteAllowed}
                    />
                  </Box>

                  <Box mt="16px">
                    <CommonButton
                      type="success"
                      buttonText="Send email confirmation"
                      onButtonClick={handleSendEmailConfirmation}
                      disabled={!!entityData?.emailConfirmed}
                    />
                  </Box>

                  <Box mt="16px">
                    <CommonButton type="success" buttonText="issue virtual card" disabled />
                  </Box>

                  <Box mt="16px">
                    <CommonButton type="success" buttonText="issue physical card" disabled />
                  </Box>

                  {/* <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box> */}
                </Box>
              </Box>
            </form>
            <ClientUpdateSSNDobForm
              isOpen={isEditPersonalInfoPopupOpen}
              onSecondaryButtonClick={() => setIsEditPersonalInfoPopupOpen(false)}
              formData={editPersonalFormData}
              clientId={Number(clientId)}
              handleUpdateNewSSNDoBvalues={handleUpdateNewSSNDoBvalues}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ClientsEditFormContainer;
