import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: '12px',
  },
  filterItem: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    cursor: 'pointer',
  },
  filterItemActive: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  clearIconContainer: {
    borderRadius: '12px',
    padding: '10px',
    backgroundColor: theme.palette.info.main,
    svg: {
      color: theme.palette.common.white,
    },

    '&.Mui-disabled': {
      svg: {
        color: '#767885',
      },
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '&:hover': {
      backgroundColor: theme.palette.info.main,
      svg: {
        color: theme.palette.common.white,
      },
    },
  },
  filtersContainer: {
    display: 'flex',
    marginBottom: '24px',
    gap: '16px',
    alignItems: 'center',
    flexWrap: 'wrap',

    '@media (max-width: 550px)': {
      flexWrap: 'nowrap',
      marginRight: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '12px',
      marginBottom: 0,
    },
  },
  filtersForm: {
    width: '100%',

    '@media (max-width: 550px)': {
      width: 'auto',
      marginBottom: '12px',
      borderRadius: '12px',
      backgroundColor: theme.palette.primary.light,
    },
  },
  clearFiltersButton: {
    maxWidth: '100%',
    width: '100%',
    height: '48px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    borderRadius: '12px',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Inter',
    textTransform: 'capitalize',

    '&.Mui-disabled': {
      color: ' #767885',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
}));
