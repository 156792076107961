export const formatWithCurrencyMask = (
  // e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  value: string,
  digitsAfterDecimal = 2,
) => {
  // let value = e.target.value;
  value = value.replace(/\D/g, '');
  value = value.replace(new RegExp(`(\\d)(\\d{${digitsAfterDecimal}})$`), '$1.$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');

  //   // TODO: Cursor flexibility
  // const pointer = e.target.selectionStart;
  //   const element = e.target;
  //   window.requestAnimationFrame(() => {
  //     element.selectionStart = pointer;
  //     element.selectionEnd = pointer;
  //   });

  // e.target.value = value;
  return value;
};
