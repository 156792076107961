import { Box, Typography, useTheme, Theme } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Loader from '../Loader';
import { getDocumentFile } from '../../api/requestsApi/documentsApi';

interface LinkFileProps {
  documentId: string;
  label: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
}));

const LinkFile = ({ documentId, label }: LinkFileProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();

  const handleClick = () => {
    setLoading(true);
    getDocumentFile(documentId as string).then((fileRes) => {
      window.location.href = fileRes?.data;
    });
  };

  return loading ? (
    <Box width="100%" height="100%" display="flex">
      <Box width="20px">
        <Loader colorType="secondary" size={20} />
      </Box>
    </Box>
  ) : (
    <Box
      className={styles.classes.styledLink}
      onClick={handleClick}
      display="flex"
      alignItems="center"
      gap="4px"
      maxWidth="fit-content"
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Typography
        fontSize="14px"
        fontWeight="500"
        lineHeight="normal"
        textTransform="uppercase"
        color={theme.palette.info.main}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '200px', // Задайте нужную ширину
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default LinkFile;
