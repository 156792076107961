import { makeStyles } from 'tss-react/mui';

import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as AppPreviewImage } from './assets/app-preview.svg';

const useStyles = makeStyles()(() => ({
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '120px',
    marginBottom: '70px',
    padding: '0px 40px',

    '@media (max-width: 425px)': {
      padding: '0px 20px',
    },

    '@media (max-width: 768px)': {
      marginTop: '80px',
      marginBottom: '30px',
    },
  },
  previewContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    marginBottom: '40px',

    '@media (max-width: 768px)': {
      marginBottom: '20px',
    },

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  previewContainerHeadline: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '43.2px',
    fontSize: '36px',
    textAlign: 'center',

    '@media (max-width: 768px)': {
      lineHeight: '43.2px',
      fontSize: '36px',
    },
  },
  previewInnerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',

    '& .preview-image': {
      svg: {
        height: '100%',
      },
    },
  },
  previewMobileAppContainer: {
    position: 'absolute',
    right: '70px',
    top: '75px',
    display: 'flex',
  },
}));

const AppDemoPreviewBlock = () => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Box className={styles.classes.previewContainer}>
      <Box className={styles.classes.previewContainerHeader}>
        <Typography className={styles.classes.previewContainerHeadline} color="#283645">
          Client lifecycle management.
        </Typography>
        <Typography className={styles.classes.previewContainerHeadline} color="#283645">
          For law firms.
        </Typography>
        <Typography
          className={styles.classes.previewContainerHeadline}
          color={theme.palette.info.main}
        >
          Made easy.
        </Typography>
      </Box>
      <Box className={styles.classes.previewInnerContainer}>
        <Box className="preview-image" display="flex" minWidth={0}>
          <AppPreviewImage />
        </Box>
      </Box>
    </Box>
  );
};

export default AppDemoPreviewBlock;
