import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { getClientRecord } from '../../api/clientsApi/clientsApi';
import TabsPanel from '../../components/TabsPanel';
import { clientsAccountTabs } from '../../constants/tabRoutes';

const ClientAccountView = () => {
  const { clientId } = useParams();
  // TODO: types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [entityRecordData, setEntityRecordData] = useState<any>();

  useEffect(() => {
    getClientRecord(clientId as string).then((res) => {
      if (res.data) {
        setEntityRecordData({
          ...res.data,
          firmId: { id: res.data.firm.id, label: res.data.firm.name },
          address2: res.data.address2 || '',
          middleName: res.data.middleName || '',
          state: res.data.countryState,
        });
      }
    });
  }, [clientId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={clientsAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default ClientAccountView;
