import { useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import FormTextField from '../FormTextField';

import EditTextFieldPopup from './EditTextFieldPopup';

interface FormTextFieldWithPopupInputProps {
  readonly label: string;
  readonly name: string;
  readonly value: string;
  readonly handleChange: (value: string) => void;
}

const FormTextFieldWithPopupInput = ({
  label,
  name,
  value,
  handleChange,
}: FormTextFieldWithPopupInputProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FormTextField
        name={name}
        label={label}
        handleChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ margin: 0 }}>
              <IconButton onClick={() => setIsOpen(true)} sx={{ padding: 0, color: 'white' }}>
                <EditIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <EditTextFieldPopup
        isOpen={isOpen}
        value={value}
        onMainButtonClick={(savedValue) => {
          setIsOpen(false);
          handleChange(savedValue);
        }}
        onSecondaryButtonClick={() => setIsOpen(false)}
      />
    </>
  );
};

export default FormTextFieldWithPopupInput;
