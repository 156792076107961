/* eslint-disable quotes */
import { motion } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as BenefitImage } from './assets/benefit-image.svg';

const BENEFITS_TEXT_ARRAY = [
  {
    blockTitle: 'Stay connected',
    blockText:
      "Chat with current and prospective clients where you're sure to have their attention.",
  },
  {
    blockTitle: 'Fill in the blanks',
    blockText: 'Capturing missing client data is just a few keystrokes away.',
  },
  {
    blockTitle: 'Get the picture',
    blockText: 'Missing an important document? Clients can take a picture in the v. app.',
  },
  {
    blockTitle: 'Signatures are a snap',
    blockText: "Put digital signing requests right at your clients' fingertips.",
  },
  {
    blockTitle: 'Pay effortlessly',
    blockText: 'Get your clients paid faster and safer with none of the hassle.',
  },
  {
    blockTitle: 'Eliminate uncertainty',
    blockText: 'Have complete visibility into case economics for all parties, all the time.',
  },
];

const useStyles = makeStyles()((theme: Theme) => ({
  benefitsBackgroundBlock: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '80px 0px',
    boxSizing: 'border-box',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  benefitsHeadlineText: {
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '64px',
    color: theme.palette.info.main,
    textAlign: 'center',

    '@media (max-width: 1000px)': {
      textAlign: 'left',
      padding: '0px 40px',
    },

    '@media (max-width: 768px)': {
      lineHeight: '43.2px',
      fontSize: '36px',
    },

    '@media (max-width: 425px)': {
      padding: '0 20px',
    },
  },
  benefitsInnerContainer: {
    display: 'flex',
    gap: '40px 20px',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '@media (max-width: 1000px)': {
      gap: '20px',
      justifyContent: 'flex-start',
      padding: '0 40px',
    },

    '@media (max-width: 425px)': {
      padding: '0 20px',
    },
  },
  benefitInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '20px',
    maxWidth: '380px',
    boxSizing: 'border-box',

    '@media (max-width: 1200px)': {
      maxWidth: '340px',
    },

    '@media (max-width: 1000px)': {
      padding: '20px 0px',
      maxWidth: '300px',
    },

    '@media (max-width: 720px)': {
      maxWidth: '335px',
    },
  },
  benefitInfoHeadline: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.common.white,

    '@media (max-width: 1000px)': {
      fontSize: '18px',
      lineHeight: '21.6px',
    },
  },
  benefitInfoDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    color: theme.palette.text.secondary,

    '@media (max-width: 1000px)': {
      fontWeight: 500,
      lineHeight: '16.8px',
    },
  },
}));

interface BenefitsBlockProps {
  windowWidth: number;
}

const BenefitsBlock = ({ windowWidth }: BenefitsBlockProps) => {
  const styles = useStyles();

  const animationProps = {
    initial: { opacity: 0, transform: 'translateY(50%)' },
    whileInView: {
      opacity: 1,
      transform: 'translateY(0%)',
      transition: { duration: 0.5, ease: 'easeIn' },
    },
    viewport: { once: true },
  };

  return (
    <Box className={styles.classes.benefitsBackgroundBlock} id="whyv">
      <Box className={styles.classes.benefitsContainer}>
        <Typography className={styles.classes.benefitsHeadlineText}>
          A little v. goes a long way.
        </Typography>
        <motion.div
          className={styles.classes.benefitsInnerContainer}
          {...(windowWidth > 1000 && { ...animationProps })}
        >
          {BENEFITS_TEXT_ARRAY.map((benefit, idx) => (
            <motion.div key={idx} className={styles.classes.benefitInfoContainer}>
              <BenefitImage />
              <Typography className={styles.classes.benefitInfoHeadline}>
                {benefit.blockTitle}
              </Typography>
              <Typography className={styles.classes.benefitInfoDescription}>
                {benefit.blockText}
              </Typography>
            </motion.div>
          ))}
        </motion.div>
      </Box>
    </Box>
  );
};

export default BenefitsBlock;
