import api from '../service/api.service';

import { AttyFeesConfigData, PaymentPreviewData, SaveAttyFeesConfigData } from '../../types';

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/v3/cases`;

export const getCaseFeesConfig = async (caseId: number) => {
  const targetUrl = `${BASE_URL}/${caseId}/atty-fees-config`;

  return await api.get<AttyFeesConfigData>(targetUrl);
};

export const updateCaseFeesConfig = async (caseId: number, data: SaveAttyFeesConfigData) => {
  const targetUrl = `${BASE_URL}/${caseId}/atty-fees-config`;

  return await api.put<PaymentPreviewData>(targetUrl, data);
};

export const calculatePaymentPreview = async (caseId: number, data: SaveAttyFeesConfigData) => {
  const targetUrl = `${BASE_URL}/${caseId}/atty-fees-config/payment-preview-by-config`;

  return await api.post<PaymentPreviewData>(targetUrl, data);
};

export const getPaymentPreview = async (caseId: number) => {
  const targetUrl = `${BASE_URL}/${caseId}/atty-fees-config/payment-preview`;

  return await api.get<PaymentPreviewData>(targetUrl);
};
