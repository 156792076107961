import { motion } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import RequestDemoButton from './RequestDemoComponents/RequestDemoButton';

const useStyles = makeStyles<{ isReversed?: boolean }>()((theme: Theme, { isReversed }) => ({
  featureContainer: {
    display: 'flex',
    gap: '20px',
    boxSizing: 'border-box',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '120px',
    backgroundColor: theme.palette.common.white,

    '@media (max-width: 768px)': {
      marginTop: '80px',
    },
  },
  featureInnerContainer: {
    display: 'flex',
    flexBasis: '100%',
    gap: '40px',
    ...(isReversed && { flexDirection: 'row-reverse' }),

    '@media (max-width: 1250px)': {
      flexDirection: 'column',
      gap: 0,
      padding: '0 40px',
    },

    '@media (max-width: 425px)': {
      padding: '0 20px',
    },
  },
  featureInfoContainer: {
    display: 'flex',
    flexBasis: '510px',
    flexDirection: 'column',
    gap: '12px',

    '@media (max-width: 1250px)': {
      flexBasis: 'auto',
    },
  },
  featureImageContainer: {
    display: 'flex',
    flexBasis: 'auto',

    svg: {
      width: '100%',
    },

    '@media (max-width: 1250px)': {
      svg: {
        height: '100%',
        width: '100%',
      },
    },
  },
  featureInfoHeadlineText: {
    fontFamily: 'Poppins',
    fontSize: '33px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.black,

    '@media (max-width: 1025px)': {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  featureInfoDescription: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    color: '#5D6068',

    '@media (max-width: 1025px)': {
      lineHeight: '19.2px',
    },
  },
  featureDescriptionImageContainer: {
    marginTop: 'auto',
    marginLeft: isReversed ? 0 : 'auto',

    '@media (max-width: 1250px)': {
      marginTop: '-60px',
      marginLeft: 'auto',
    },

    '@media (max-width: 1025px)': {
      svg: {
        height: '100%',
        width: '100px',
      },
    },

    '@media (max-width: 600px)': {
      svg: {
        marginRight: '0',
      },
    },
  },
  featureInfoTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
}));

interface FeatureBlockProps {
  handleOpenPopup: () => void;
  featureText: string;
  featureLabel: string;
  featureIcon: JSX.Element;
  featureImageDesktop: JSX.Element;
  featureImageResponsive: JSX.Element;
  windowWidth: number;
  isReversed?: boolean;
}

const FeatureBlock = ({
  handleOpenPopup,
  isReversed,
  featureText,
  featureLabel,
  featureIcon,
  windowWidth,
  featureImageDesktop,
  featureImageResponsive,
}: FeatureBlockProps) => {
  const styles = useStyles({ isReversed });

  const textAnimationProps = {
    initial: { opacity: 0, transform: isReversed ? 'translateX(30%)' : 'translateX(-50%)' },
    whileInView: {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
    viewport: { once: true },
  };

  const imageAnimationProps = {
    initial: { opacity: 0 },
    whileInView: {
      opacity: 1,
      transition: { duration: 1, ease: 'easeInOut' },
    },
    viewport: { once: true },
  };

  return (
    <Box className={styles.classes.featureContainer}>
      <Box className={styles.classes.featureInnerContainer}>
        <Box className={styles.classes.featureInfoContainer}>
          <motion.div
            className={styles.classes.featureInfoTextContainer}
            {...(windowWidth > 1000 && { ...textAnimationProps })}
          >
            <Typography className={styles.classes.featureInfoHeadlineText}>
              {featureLabel}
            </Typography>
            <Typography className={styles.classes.featureInfoDescription}>{featureText}</Typography>
            <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="14px" />
          </motion.div>

          <motion.div
            className={styles.classes.featureDescriptionImageContainer}
            {...(windowWidth > 1000 && { ...imageAnimationProps })}
          >
            {featureIcon}
          </motion.div>
        </Box>

        <Box className={styles.classes.featureImageContainer}>
          {windowWidth < 1250 ? <>{featureImageResponsive}</> : <>{featureImageDesktop}</>}
        </Box>
      </Box>
    </Box>
  );
};

export default FeatureBlock;
