import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { SettlementData } from '../../../types';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

interface DeductionsTableDataRowProps {
  tableData: SettlementData[];
}

const SettlementsTableDataRow = ({ tableData }: DeductionsTableDataRowProps) => {
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/payments/settlement/${id}/home`);
  };

  const formattedFirmsList =
    tableData &&
    tableData.map((settlement: SettlementData) => ({
      id: settlement.id,
      rowFields: {
        name: settlement.case.name,
        plaintiff: `${settlement.client.firstName} ${settlement.client.middleName || ''} ${
          settlement.client.lastName
        }`,
        defendant: settlement.defendant?.name || '-',
        entryDate: formatDate(settlement.createdAt as string),
        grossSettlement: numberToUSDCurrency(settlement.settledAmount),
        fees: settlement?.attorneyFees ? numberToUSDCurrency(settlement?.attorneyFees) : '-',
        deductions: settlement?.deductions ? numberToUSDCurrency(settlement?.deductions) : '-',
        net: settlement?.netSettlement ? numberToUSDCurrency(settlement?.netSettlement) : '-',
        status: parseStatusTitle(settlement.status),
        paidAt: settlement.paidAt ? formatDate(settlement.paidAt as string) : '-',
      },
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SettlementsTableDataRow;
