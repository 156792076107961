import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import FirmBankingSystemAccountContainer from './FirmBankingSystemAccountContainer';
import FirmUnrestrictedAccountsView from './unrestrictedAccountsView/FirmUnrestrictedAccountsView';
import FirmTrustAccountsView from './trustAccountsView/FirmTrustAccountsView';
import { FirmBankingData } from './types';

import { getFirmBankingInfo } from '../../api/firmsApi/firmsApi';
import PaginationProvider from '../../providers/PaginationProvider';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '20px',
  },
  systemAccountContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsInnerContainer: {
    display: 'flex',
    height: '100%',
    gap: '20px',

    '@media (max-width: 1100px)': {
      flexWrap: 'wrap',
      maxWidth: '500px',
    },
  },
}));

export const FirmBankingAccountsView = () => {
  const { classes } = useStyles();

  const firmId = localStorage.getItem('firmId');

  const [bankingData, setBankingData] = useState<FirmBankingData>();

  const fetchBankingData = () => {
    getFirmBankingInfo(firmId as string).then((res) => {
      setBankingData(res.data);
    });
  };

  useEffect(() => {
    fetchBankingData();
  }, []);

  return (
    <Box className={classes.systemAccountContainer}>
      <Typography className={classes.systemAccountsBlockLabel} mb="12px">
        System Accounts
      </Typography>

      <Box className={classes.systemAccountsInnerContainer}>
        {bankingData?.accounts && (
          <>
            {Object.entries(bankingData?.accounts).map(([accountType, accountData]) => (
              <React.Fragment key={accountType}>
                <FirmBankingSystemAccountContainer label={accountType} accountData={accountData} />
              </React.Fragment>
            ))}
          </>
        )}
      </Box>

      <PaginationProvider key="bankingUnrestrictedAccountsView">
        <FirmUnrestrictedAccountsView />
      </PaginationProvider>

      <PaginationProvider key="bankingTrustAccountsView">
        <FirmTrustAccountsView />
      </PaginationProvider>
    </Box>
  );
};

export default FirmBankingAccountsView;
