import { Box } from '@mui/material';

import { usersAccountTabs } from '../../constants/tabRoutes';

import TabsPanel from '../../components/TabsPanel';

const UsersAccountView = () => {
  return (
    <>
      <Box display="flex">
        <TabsPanel tabsList={usersAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default UsersAccountView;
