import api from '../service/api.service';

import {
  ActiveSortField,
  ClientData,
  FilterTypes,
  PaginationQueryParams,
  ResultSet,
} from '../../types';
import { parseClientBankingFilters, parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V1 = '/v1/clients';

export const createClientRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V1, data);
};

export const getClientsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}&includeAdditionalInfo=true`;

  return await api.get<ResultSet<ClientData>>(targetUrl);
};

export const getClientRecord = async (id: number | string) => {
  const targetUrl = `${BASE_URL_V1}/${id}`;

  return await api.get<ClientData>(targetUrl);
};

export const updateClientRecord = async (clientId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}`;

  return await api.put(targetUrl, data);
};

export const resetClientAccount = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/invite`;

  return await api.put(targetUrl, null);
};

export const getClientBankingInfo = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking`;

  return await api.get(targetUrl);
};

export const startClientProvisioning = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/start-provisioning`;

  return await api.put(targetUrl);
};

export const getClientTransactionList = async (
  { size, page, filters, activeSortField }: PaginationQueryParams,
  clientId: string,
) => {
  const queryFilters = parseClientBankingFilters(filters as FilterTypes);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/ledger?size=${size}&page=${page}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getClientBirthDate = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/birthdate`;

  return await api.get<{ birthdate: string; id: number }>(targetUrl);
};

export const getClientSSN = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/ssn`;

  return await api.get<{ id: number; ssn: string }>(targetUrl);
};

export const deletePlaintiffAccount = async (token: string, email: string) => {
  const targetUrl = `${BASE_URL_V1}/self-remove-from-email`;

  return await api.get<void>(targetUrl, {
    params: {
      token,
      email,
    },
  });
};

export const sendEmailConfirmation = async (id: number) => {
  const targetUrl = `${BASE_URL_V1}/${id}/send-email-confirmation`;

  return await api.put<{ id: number; ssn: string }>(targetUrl);
};

export const getClientDataByEmailConfirmationToken = async (token: string) => {
  const targetUrl = `${BASE_URL_V1}/email-confirmations/${token}`;

  return await api.get(targetUrl);
};

export const confirmClientEmail = async (data: unknown) => {
  return await api.post(`${BASE_URL_V1}/confirm-email`, data);
};

export const updateClientPersonalInformation = async (
  id: number,
  data: { ssn: string; birthdate: Date | null | string },
) => {
  const targetUrl = `${BASE_URL_V1}/${id}/ssn-dob`;

  return await api.put(targetUrl, {
    ...data,
  });
};
