import { useNavigate, useParams } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { DataRequestShortData } from '../../../types';

interface DataTableDataRowProps {
  tableData: DataRequestShortData[];
}

const DataTableDataRow = ({ tableData }: DataTableDataRowProps) => {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/requests/data-request/${id}/home`);
  };

  const formattedFirmsList =
    tableData &&
    tableData.map((data: DataRequestShortData) => ({
      id: data.id,
      rowFields: {
        ...(!caseId && {
          name: `${data.client.firstName} ${data.client.lastName}`,
        }),
        sentAt: data?.sentAt ? formatDate(data.sentAt as string) : '-',
        caseType: data.case.type.name,
        items: data.formItemsCount,
        status: data.status,
        completion: `${data.formItemAnswersCount}/${data.formItemsCount}`,
        returnDate: data?.completedAt ? formatDate(data.completedAt) : '-',
        daysToReturn: '-',
        fileId: '-',
      },
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DataTableDataRow;
