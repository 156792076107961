import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme, Typography } from '@mui/material';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { requestSupportFormSchema } from '../../helpers/formSchemas/formSchemas';
import { REQUEST_SUPPORT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { requestToSupportMail } from '../../api/requestSupportApi';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: '32px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    width: '100%',
  },
  formTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '40px',
  },
  formContentText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    marginBottom: '20px',
  },
  formList: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}));

interface RequestSupportFormSubmitProps {
  name: string;
  email: string;
  message: string;
}

const RequestSupportForm = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isFormSubmittedSuccessful, setIsFormSubmittedSuccessful] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(requestSupportFormSchema),
    defaultValues: REQUEST_SUPPORT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit = (values: RequestSupportFormSubmitProps) => {
    setIsFormSubmitting(true);

    requestToSupportMail(values)
      .then((res) => {
        if (res) {
          setIsFormSubmitting(false);
          setIsFormSubmittedSuccessful(true);
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setIsFormSubmittedSuccessful(false);
        setAlert((prev) => ({
          ...prev,
          type: 'error',
          message: err.response.data.message,
        }));
      });
  };

  return (
    <Box className={classes.formContainer}>
      <Typography className={classes.formTitle}>Support</Typography>

      {isFormSubmittedSuccessful ? (
        <>
          <Typography className={classes.formContentText}>
            Your message has been received.
          </Typography>

          <Box display="flex">
            <FormSubmitButton
              onClick={() => navigate('/')}
              buttonText="Done"
              fontFamily="Inter"
              disabled={false}
            />
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.formContentText}>
            Have a question or concern? Send us a note.
          </Typography>

          <FormProvider {...formMethods}>
            <Box display="flex" flexDirection="column" gap="30px">
              <FormTextField name="name" label="Name" />
              <FormTextField name="email" label="Email" />
              <FormTextField name="message" label="Your Message" isMultiline />

              <FormSubmitButton
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isFormSubmitting}
                buttonText="Submit"
                fontFamily="Inter"
              />
            </Box>
          </FormProvider>
        </>
      )}
    </Box>
  );
};

export default RequestSupportForm;
