import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Box, Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  backButtonContainer: {
    display: 'flex',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '12px',
  },
}));

const GoBackButton = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Box className={styles.classes.backButtonContainer} onClick={onBack}>
      <Box className={styles.classes.backButton}>
        <KeyboardArrowLeftIcon />
      </Box>
    </Box>
  );
};

export default GoBackButton;
