import { makeStyles } from 'tss-react/mui';

import { Box, Button, Theme } from '@mui/material';

const useStyles = makeStyles<{ type: string }>()((theme: Theme, { type }) => ({
  default: {
    background: 'transparent',
    height: '100%',
    maxWidth: '20px',
    width: '100%',
    minWidth: '10px',
  },
  buttonInnerContainer: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
    alignItems: 'center',

    svg: {
      color: type === 'info' ? theme.palette.common.white : theme.palette.error.main,
    },
  },
  buttonTypography: {
    fontSize: '14px',
    textTransform: 'uppercase',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

interface FirmBankingTableActionButtonProps {
  buttonIcon: JSX.Element;
  type: 'info' | 'error';
  handleClick?: () => void;
}

const FirmBankingTableActionButton = ({
  buttonIcon,
  type,
  handleClick,
}: FirmBankingTableActionButtonProps) => {
  const { classes } = useStyles({ type });

  return (
    <Button className={classes.default} onClick={handleClick} disableRipple>
      <Box className={classes.buttonInnerContainer}>{buttonIcon && buttonIcon}</Box>
    </Button>
  );
};

export default FirmBankingTableActionButton;
