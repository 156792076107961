import { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFieldArray, useFormContext } from 'react-hook-form';

import AddButton from '../buttons/AddButton';
import { QuestionnaireSubmitForm } from '../../views/QuestionnairesView/types';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import { SelectOption } from '../../types';
import Popup from '../Popup';

import Section from './Section';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
}));

interface QuestionnaireFormBuilderProps {
  readonly booleanItems: SelectOption[];
  readonly handleUpdateBooleanItems: (items: SelectOption[]) => void;
}

const QuestionnaireFormBuilder = ({
  booleanItems,
  handleUpdateBooleanItems,
}: QuestionnaireFormBuilderProps) => {
  const { classes } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { control, clearErrors } = useFormContext<{ form: QuestionnaireSubmitForm }>();

  const {
    fields: sections,
    append,
    remove,
    swap,
  } = useFieldArray({
    name: 'form.sections',
    control,
  });

  const moveSection = (indexA: number, indexB: number) => {
    swap(indexA, indexB);
  };

  const removeSection = (index?: number | number[] | undefined) => {
    remove(index);
  };

  const openWarningPopup = () => setIsOpen(true);

  return (
    <Box className={classes.container}>
      {sections.map((section, index) => (
        <Section
          key={section.id}
          booleanItems={booleanItems}
          numberOfSections={sections.length}
          sectionIdx={index}
          handleUpdateBooleanItems={handleUpdateBooleanItems}
          onDeleteClick={removeSection}
          onMoveClick={moveSection}
          openWarningPopup={openWarningPopup}
        />
      ))}
      <AddButton
        buttonText="Add New Section"
        handleClick={() => {
          if (sections.length === 0) clearErrors('form.sections');

          append({
            title: '',
            items: [],
            immutable: false,
          });
        }}
      />

      <Popup
        isOpen={isOpen}
        headlineText={POPUP_DESCRIPTIONS.formBuilder.headlineText}
        contentText={POPUP_DESCRIPTIONS.formBuilder.contentText}
        onMainButtonClick={() => setIsOpen(false)}
      />
    </Box>
  );
};

export default QuestionnaireFormBuilder;
