import { TooltipProps, Tooltip } from '@mui/material';
import useWindowWidth from '../hooks/useWindowWidth';

interface DefaultTooltipProps extends TooltipProps {
  children: JSX.Element;
}

const TooltipComponent = ({ children, ...rest }: DefaultTooltipProps) => {
  const windowWidth = useWindowWidth();

  return (
    <Tooltip
      {...rest}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '16px',
            borderRadius: '16px',
            backgroundColor: '#454959',
            border: '1px solid rgba(37, 157, 168, 0.72)',
            fontSize: '16px',
            fontWeight: 500,
            maxWidth: windowWidth < 425 ? '220px' : '370px',
            width: '100%',
            color: 'rgba(37, 157, 168, 0.72)',
            fontFamily: 'Inter',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
