import { motion } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import RequestDemoButton from './RequestDemoComponents/RequestDemoButton';

const useStyles = makeStyles()((theme: Theme) => ({
  demoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '120px 40px',
    boxSizing: 'border-box',
    maxWidth: '1200px',
    margin: '0 auto',
    alignItems: 'center',

    '@media (max-width: 425px)': {
      padding: '120px 20px',
    },
  },
  demoHeadlineText: {
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '64px',
    color: theme.palette.common.white,
    textAlign: 'center',

    '@media (max-width: 768px)': {
      lineHeight: '43.2px',
      fontSize: '36px',
    },

    '@media (max-width: 425px)': {
      maxWidth: '335px',
    },
  },
  demoDescription: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '19.2px',
    color: theme.palette.text.secondary,
    textAlign: 'center',

    '@media (max-width: 425px)': {
      maxWidth: '335px',
    },
  },
}));

interface RequestDemoBlockProps {
  handleOpenPopup: () => void;
  windowWidth: number;
}

const RequestDemoBlock = ({ handleOpenPopup, windowWidth }: RequestDemoBlockProps) => {
  const styles = useStyles();

  const animationProps = {
    initial: { opacity: 0, transform: 'translateY(50%)' },
    whileInView: {
      opacity: 1,
      transform: 'translateY(0%)',
      transition: { duration: 0.5, ease: 'easeIn' },
    },
    viewport: { once: true },
  };

  return (
    <motion.div
      className={styles.classes.demoContainer}
      {...(windowWidth > 1000 && { ...animationProps })}
    >
      <Typography className={styles.classes.demoHeadlineText}>Get started with v.</Typography>
      <Typography className={styles.classes.demoDescription} mt="20px">
        Schedule a demo to see how v. can help you and your firm.
      </Typography>
      <Box mt="40px">
        <RequestDemoButton handleOpenPopup={handleOpenPopup} fontSize="16px" />
      </Box>
    </motion.div>
  );
};

export default RequestDemoBlock;
