import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';

import { Alert, AlertColor, Snackbar, AlertTitle, Typography } from '@mui/material';

interface FormatErrorSnackbarMessageProps {
  messages: string[] | string;
}

const FormatErrorSnackbarMessage = ({ messages }: FormatErrorSnackbarMessageProps) => {
  return (
    <>
      {Array.isArray(messages) ? (
        messages.map((message, idx) => <Typography key={idx}>{message}</Typography>)
      ) : (
        <Typography>{messages}</Typography>
      )}
    </>
  );
};

export const SnackBarContext = createContext<SnackBarContextProps>({
  setAlert: () => ({ type: '', message: '' }),
});

interface SnackBarContextProps {
  setAlert: Dispatch<
    SetStateAction<{ type: string; message: string | string[]; noAutoHideDuration?: boolean }>
  >;
}

interface SnackBarProviderProps {
  children: JSX.Element;
}

const SnackBarProvider = ({ children }: SnackBarProviderProps) => {
  const [alert, setAlert] = useState<{
    type: string;
    message: string | string[];
    noAutoHideDuration?: boolean;
  }>({
    type: 'info',
    message: '',
    noAutoHideDuration: false,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
    setAlert((prev) => ({ ...prev, message: '', type: '' }));
  };

  useEffect(() => {
    if (alert.message) {
      setIsOpen(true);
    }
  }, [alert.message, setAlert]);

  const value = {
    setAlert,
  };

  return (
    <SnackBarContext.Provider value={value}>
      {alert.type && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isOpen}
          onClose={handleClose}
          autoHideDuration={alert.noAutoHideDuration ? null : 5000}
        >
          <Alert onClose={handleClose} severity={alert?.type as AlertColor}>
            <AlertTitle sx={{ textTransform: 'capitalize' }}>{alert.type || ''}</AlertTitle>
            {alert.type === 'error' ? (
              <FormatErrorSnackbarMessage messages={alert.message as string[]} />
            ) : (
              <>{alert.message}</>
            )}
          </Alert>
        </Snackbar>
      )}
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
