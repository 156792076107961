import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { ClientCreateFormSubmitProps } from './types';

import { createClientRecord } from '../../api/clientsApi/clientsApi';
import Loader from '../../components/Loader';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { US_STATES_LIST } from '../../constants/constants';
import { CLIENT_CREATE_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { clientCreateFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    padding: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 1000px)': {
      flexBasis: '480px',
      padding: '12px',
    },
  },
  formWrapper: {
    display: 'flex',
  },
  formInfoContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 1000px)': {
      flexBasis: 0,
    },
  },
  formSecondInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 1000px)': {
      flexBasis: 0,
      marginTop: '32px',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '24px',
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',

    '@media (max-width: 1000px)': {
      gap: '24px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const ClientCreateFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const firmId = localStorage.getItem('firmId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientCreateFormSchema),
    defaultValues: CLIENT_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: ClientCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      ...(values.middleName?.length && { middleName: values.middleName }),
      ...(values.address2?.length && { address2: values.address2 }),
      referenceId: values.referenceId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address1: values.address1,
      city: values.city,
      zip: values.zip,
      ssn: values.ssn,
      birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
      firmId,
      countryState: values.state,
    };

    createClientRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/clients/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Client created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      {!firmId ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.formWrapper}>
            <FormProvider {...formMethods}>
              <form className={styles.classes.formContainer}>
                <Box className={styles.classes.formInfoContainer}>
                  <Box className={styles.classes.formInfoBlock}>
                    <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                    <Box mt="16px">
                      <FormTextField name="referenceId" label="Reference Id *" />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="firstName" label="First Name *" />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="middleName" label="Middle Name" />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="lastName" label="Last Name *" />
                    </Box>

                    <Typography className={styles.classes.fieldHeadline} mt="32px">
                      Credentials
                    </Typography>
                    <Box mt="16px">
                      <FormTextField name="email" label="Email Address *" />
                    </Box>
                    <Box mt="24px">
                      <FormMaskedTextField
                        mask="+19999999999"
                        name="phone"
                        label="Mobile Number *"
                      />
                    </Box>
                  </Box>

                  <Box className={styles.classes.formSecondInfoBlock}>
                    <Typography className={styles.classes.fieldHeadline}>Address</Typography>
                    <Box mt="16px">
                      <FormTextField name="address1" label="Address Line 1 *" />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="address2" label="Address Line 2" />
                    </Box>
                    <Box className={styles.classes.formPlaceInfoContainer}>
                      <FormTextField name="city" label="City *" />
                      <FormSelectField
                        options={US_STATES_LIST}
                        name="state"
                        label="State *"
                        width="100%"
                      />
                      <FormTextField name="zip" label="Zip *" />
                    </Box>

                    <Typography className={styles.classes.fieldHeadline} mt="32px">
                      Personal Information
                    </Typography>
                    <Box mt="16px">
                      <FormDateField name="birthdate" label="Date of Birth *" maxDate={moment()} />
                    </Box>
                    <Box mt="24px">
                      <FormMaskedTextField
                        mask="999-99-9999"
                        name="ssn"
                        label="Social Security Number *"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" width="100%" justifyContent="center">
                  <Box
                    display="flex"
                    mt="32px"
                    maxWidth="416px"
                    flexDirection="column"
                    gap="16px"
                    width="100%"
                  >
                    <FormSubmitButton
                      disabled={!isValid || isFormSubmitting}
                      buttonIcon={<SaveIcon />}
                      buttonText="Save"
                      onClick={handleSubmit(onSubmitForm)}
                    />
                    <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
};

export default ClientCreateFormContainer;
