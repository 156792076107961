export const parseStatusTitle = (status: string) => {
  switch (status) {
    case 'CancelInProgress':
      return 'Cancel In Progress';
    case 'CriticalError':
      return 'Critical Error';
    case 'OnHold':
      return 'On Hold';
    case 'DistributingFunds':
      return 'Distributing Funds';
    case 'PaymentInProgress':
      return 'Payment In Progress';
    default:
      return status;
  }
};
