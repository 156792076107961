import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v3/settlements';
const PAYOUT_BASE_URL = '/v4/settlements';
const GET_LIST_BASE_URL = '/v4/settlements';

export const getSettlementsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${GET_LIST_BASE_URL}?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}${sortFilters}`;

  return await api.get(targetUrl);
};

export const createSettlementRecord = async (data: unknown) => {
  return await api.post(BASE_URL, data);
};

export const getSettlementRecord = async (settlementId: string) => {
  const targetUrl = `${BASE_URL}/${settlementId}`;

  return await api.get(targetUrl);
};

export const updateSettlementRecord = async (settlementId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/${settlementId}`;

  return await api.put(targetUrl, data);
};

export const getSettlementTimeline = async (settlementId: string) => {
  const targetUrl = `${BASE_URL}/${settlementId}/timeline`;

  return await api.get(targetUrl);
};

export const getSettlementsCheck = async (settlementId: string, deductionIds?: number[]) => {
  const targetUrl = `${BASE_URL}/${settlementId}/payment-preview`;

  return await api.get(targetUrl, {
    params: {
      includedDeductionIds: deductionIds ? deductionIds : {},
    },
  });
};

export const deleteSettlementRecord = async (settlementId: string) => {
  const targetUrl = `${BASE_URL}/${settlementId}`;

  return await api.delete(targetUrl);
};

export const getSettlementDetails = async (settlementId: string) => {
  const targetUrl = `${PAYOUT_BASE_URL}/${settlementId}/payment-details`;

  return await api.get(targetUrl);
};

export const paySettlement = async (settlementId: string, pin: string, deductionIds?: number[]) => {
  const targetUrl = `${BASE_URL}/${settlementId}/pay`;

  return await api.put(targetUrl, deductionIds ? { includedDeductionIds: deductionIds } : {}, {
    headers: { 'x-pin': pin },
  });
};
