/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as WelcomeHeroTwoIcon } from '../assets/welcome-hero-2.svg';

import Header from '../Header';
import Footer from '../Footer';

import useWindowWidth from '../../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  pageMainContainer: {
    height: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19) 3.19%, rgba(37, 157, 168, 0.00) 37.39%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#283645',
    fontFamily: 'Inter',
  },
  footerBackground: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',

    '@media (max-width: 768px)': {
      paddingTop: '96px',
    },
  },
  pageHeadlineBlock: {
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    marginBottom: '66px',
    alignItems: 'flex-end',
    gap: '20px',
    marginTop: '40px',
    padding: '0 20px',

    '@media (max-width: 1150px)': {
      justifyContent: 'center',
    },

    '@media (max-width: 768px)': {
      margin: '48px 0',
    },
  },
  pageHeadlineText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: '72px',
    color: '#283645',
    textAlign: 'center',

    '@media (max-width: 768px)': {
      fontSize: '36px',
      lineHeight: '120%',
    },
  },
  policyPageContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1200px',
    gap: '40px',
    margin: '0 auto',
    padding: '0 20px',
    paddingBottom: '40px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#5D6068',

    a: {
      color: '#00667C',
      textDecoration: 'none',
    },

    strong: {
      fontWeight: 600,
      color: '#283645',
    },
  },
  termsList: {
    padding: '0 20px',
  },
}));

const TermsConditionsView = () => {
  const styles = useStyles();
  const windowWidth = useWindowWidth();

  const [isRequestDemoPopupOpen, setIsRequestDemoPopupOpen] = useState<boolean>(false);

  const handleClosePopup = () => setIsRequestDemoPopupOpen(false);
  const handleOpenPopup = () => setIsRequestDemoPopupOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  return (
    <Box className={styles.classes.pageMainContainer}>
      <Header
        windowWidth={windowWidth}
        handleOpenPopup={handleOpenPopup}
        isRequestDemoPopupOpen={isRequestDemoPopupOpen}
        handleClosePopup={handleClosePopup}
      />

      <Box className={styles.classes.pageHeadlineBlock}>
        {windowWidth > 1150 && <WelcomeHeroTwoIcon />}

        <Typography className={styles.classes.pageHeadlineText}>Terms and Conditions</Typography>
      </Box>

      <Box className={styles.classes.policyPageContentWrapper}>
        <section>
          <ol
            type="1"
            style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            <li>
              <strong>Applicability and Precedence.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              <li>
                These Terms and Conditions, along with any fully-executed Order Form(s) entered into
                between V and the Customer, comprise the agreement (<strong>“Agreement”</strong>)
                between the parties, effective as of the date both parties execute an Order Form (
                <strong>“Effective Date”</strong>). These Terms and Conditions may be amended from
                time to time, but the Agreement with Customer shall constitute the Terms and
                Conditions in effect as of the date of execution of any applicable Order Form.
                Except as stated in Section 1(b), to the extent there is any conflict or
                inconsistency between (i) these Terms and Conditions and (ii) any of the Order
                Forms, the provisions of these Terms and Conditions (excluding the Order Forms)
                shall take precedence.
              </li>
              <li>
                Nothing in any Order Form will alter the Terms and Conditions unless it specifically
                references the particular Section being altered (including Section number) which the
                parties wish to vary. Where a specific Section is referenced and varied in an Order
                Form, that change only applies to that Order Form and it does not otherwise vary the
                Terms and Conditions in respect of any other Order Form then in force or any future
                Order Form which may be entered into by the parties.
              </li>
            </ol>

            <li>
              <strong>Software.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>Features.</strong> V will provide Customer access to the software stated in
                the Order Form(s) (<strong>“Software”</strong>) entered into between V and Customer.
                The features and user interface of the Software are described at www.v.law and may
                be changed or updated by V during the term of this Agreement.
              </li>
              <li>
                <strong>Users.</strong> Customer may designate users of the Software. Users must be
                employees or independent contractors of Customer and not of separately represented
                parties or their counsel. If Customer is represented by one or more law firms,
                Customer may designate employees or independent contractors of those law firms as
                users of the Software, but only for use in their representation of Customer. If
                Customer is a law firm, Customer may designate employees of Customer’s clients as
                users of the Software, but only for use in connection with the law firm’s
                representation of Customer. Customer will safeguard and maintain the security of its
                and its users’ login credentials and shall be responsible for any and all activity
                that occurs under such login credentials. Customer is responsible for users’
                compliance with this Agreement.
              </li>
              <li>
                <strong>Intellectual Property.</strong> Customer acknowledges V owns all right,
                title, and interest (or is an authorized licensee) in (i) all intellectual-property
                rights related to or embodied in the Software; (ii) all V-supplied operating,
                support, technical documentation (<strong>“Documentation”</strong>); and (iii) V’s
                marks, logos, and product names; (collectively, the <strong>“V IP”</strong>). This
                Agreement does not convey to Customer any express or implied intellectual-property
                rights in the V IP, and Customer disclaims any such right, title, or interest in the
                V IP, other than the limited, nontransferable, nonexclusive, revocable, and
                temporary license to use the Software and Documentation, as provided under the terms
                of this Agreement. V IP includes without limitation all intellectual property rights
                in any copy, translation, modification, adaptation, or derivation of the Software or
                Documentation including any future improvements or developments in the Software or
                Documentation. V will receive a royalty-free, worldwide, transferable,
                sub-licensable, irrevocable, and perpetual license to use or incorporate into the
                Software or Documentation any suggestions, enhancement requests, recommendations, or
                other feedback provided by Customer or any authorized users granted access to the
                Software or Documentation. Customer shall promptly notify V if Customer becomes
                aware of any possible third-party infringement of V IP and will fully cooperate with
                V, at V’s expense (provided that Customer’s action did not contribute to the
                infringement) in any legal action taken by V against third parties to enforce the V
                IP. Customer shall use commercially reasonable efforts to safeguard the Software and
                Documentation (including all copies thereof) from infringement, misappropriation,
                theft, misuse, or unauthorized access.
              </li>
              <li>
                <strong>Limitations on Use.</strong> Customer may not and may not permit others to
                (i) reverse engineer or in any way derive source code from the Software; (ii) modify
                or otherwise create derivative works from the Software; (iii) resell access to the
                Software (except that, if Customer is a lawyer or law firm, it may charge clients);
                or (iv) use the Software to help anyone compete with V.
              </li>
              <li>
                <strong>Usage Data.</strong> V may use data gathered from Customer’s use of the
                Software solely for the purposes of providing or improving the Software and
                Services, subject to the confidentiality provisions of this Agreement. V may list
                Customer in its advertising and marketing materials.
              </li>
            </ol>

            <li>
              <strong>Confidentiality.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                For purposes of this Agreement, <strong>“Confidential Information”</strong> includes
                without limitation data, and technical, administrative, training, or business
                information disclosed by one party (the
                <strong>“Disclosing Party”</strong>) to the other party (the{' '}
                <strong>“Receiving Party”</strong>), whether orally or in writing, including
                information about the party’s and its clients, customers, properties, employees,
                finances, businesses and operations. Additionally, Confidential Information shall
                include all notes, analyses, compilations, studies, interpretations or other
                documents prepared by the Receiving Party or any of its Representatives, as defined
                herein, which contain, reflect or are based upon, in whole or in part, the
                information furnished to the Receiving Party or any of its Representatives by the
                Disclosing Party or any of its Representatives. Confidential Information will not
                include information that: (i) is now or thereafter becomes generally known or
                available to the public, through no act or omission on the part of the Receiving
                Party or its Representatives; (ii) was known by the Receiving Party or any of its
                Representatives prior to receiving such information from the Disclosing Party and
                without restriction as to use or disclosure; (iii) is rightfully acquired by the
                Receiving Party or any of its Representatives from a third party who has the right
                to disclose it and who provides it without restriction as to use or disclosure; or
                (iv) is independently developed by the Receiving Party without use of or reference
                to any Confidential Information of the Disclosing Party.
              </li>
              <li>
                Each party agrees: (i) to maintain the other party's Confidential Information in
                strict confidence using the same care it uses to protect its own Confidential
                Information but in no case less than reasonable care under the circumstances; (ii)
                not to disclose such Confidential Information to any third parties, except its
                Representatives; and (iii) not to use any such Confidential Information for any
                purpose except for exercising its rights, duties, and obligations under this
                Agreement. Each party may disclose the Confidential Information of the other party
                to its officers, directors, employees, shareholders, partners, members, affiliates,
                accountants, attorneys, financial advisors, consultants, other agents or
                representatives and financing sources (such persons collectively referred to as
                <strong>“Representatives”</strong>, provided however that no person shall be
                considered a Representative unless and until it receives Confidential Information
                from or on behalf of the Receiving Party) who have a need to know such Confidential
                Information, but solely to the extent necessary for the Receiving Party to exercise
                its rights, duties, and obligations under this Agreement and for no other purpose;
                provided that each Representative shall be informed of the confidential nature of
                the Confidential Information and the terms of this Agreement and agree to be bound
                by the terms of this Agreement. Each party agrees that it will be responsible for
                any breach of this Agreement by any of its Representatives. The provisions of this
                Section will not restrict a party from disclosing the other party’s Confidential
                Information to the extent required by any law or regulation; provided that the party
                required to make such a disclosure uses reasonable efforts (at the Disclosing
                Party’s expense) to give the other party reasonable advance notice of such required
                disclosure (to the extent allowed by law) in order to enable the other party to
                prevent or limit such disclosure, and shall disclose only that portion of the
                Confidential Information which, based on the reasonable advice of counsel, is
                legally required to be disclosed and will otherwise exercise all reasonable efforts
                to obtain reliable assurance that confidential treatment will be accorded the
                Confidential Information.
              </li>
            </ol>

            <li>
              <strong>
                Data Processing Addendum; Privacy Policy; Data Privacy Framework Notice.
              </strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                The terms of the data processing addendum, accessible at{' '}
                <a href="/data-processing-addendum">v.law/data-processing-addendum</a>, the privacy
                policy, accessible at <a href="/privacy-policy">v.law/privacy-policy</a>, and the
                data privacy framework notice, accessible at{' '}
                <a href="/data-privacy-framework-notice">v.law/data-privacy-framework-policy</a>, at
                the Effective Date are incorporated into this Agreement by reference.
              </li>
            </ol>

            <li>
              <strong>Support and Services.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>Support and Services Provided by V.</strong> V will provide Customer support
                and services as stated in the Order Form(s) (<strong>“Services”</strong>) entered
                into between V and Customer. Each party consents to the recording of telephone
                conversations involving V customer support personnel in connection with this
                Agreement. Each party also agrees to obtain any necessary consent of, and give any
                necessary notice of such recordings to, its relevant personnel.
              </li>
              <li>
                <strong>Services Provided by Third Parties.</strong> Customer may elect to purchase
                services from V channel partners or from third-party service companies who are not V
                channel partners. V is not responsible for the delivery, quality, or timeliness of
                services not delivered by V.
              </li>
            </ol>

            <li>
              <strong>Pricing and Payment.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>Price.</strong> Customer will pay V the amounts stated in an Order Form at
                the times stated therein, plus interest at 1.5% per month on past due amounts,
                provided, however, that Customer shall not be required to pay interest at more than
                the maximum rate allowed by law. V may suspend all performance if any invoice is not
                paid in full when due. Following suspension, (i) Customer will not have access to
                its data or the ability to archive or download its data until past-due amounts are
                paid in full; (ii) V will consider Customer’s data as abandoned and may dispose of
                part or all of the data after suspension; and (iii) charges will continue to accrue
                while V holds Customer’s data and Customer’s obligation to pay those charges will
                survive suspension. For the avoidance of doubt, all pricing information set forth in
                this Agreement shall constitute V Confidential Information.
              </li>
              <li>
                <strong>Taxes.</strong> All fees payable by Customer to V are exclusive of any
                applicable sales, use, value-added taxes and other assessments imposed by any
                governmental authority upon or with respect to the transactions and/or payments
                under this Agreement (collectively, <strong>“Taxes”</strong>). Customer is
                responsible for paying Taxes, excluding, for the avoidance of doubt, any taxes
                assessable against V based on its income, property or employees. If V is obligated
                under applicable law to collect or pay any Taxes, Customer agrees to pay to V, in
                addition to the fees owed to V, such Taxes as invoiced, unless Customer provides V
                with a valid tax exemption certificate for each jurisdiction in which it is claiming
                an exemption from such Taxes. Customer will provide such information to V as may be
                reasonably required to determine whether V is obligated to collect any Taxes from
                Customer. Unless otherwise agreed to by the parties, products and services provided
                by V to Customer under this Agreement are provided and sourced to Customer at the
                Customer address identified on the applicable Order Form. Customer will make payment
                of all fees to V under this Agreement free and clear of any tax deduction or
                withholding, except to the extent otherwise required under applicable law. If any
                such tax deduction or withholding is required under applicable law, Customer may
                deduct such amount from the fees owed to V and pay such deducted amount to the
                relevant taxing authority; provided, that Customer reasonably cooperates with V to
                minimize or eliminate the amount of any such withholding or deduction, and promptly
                provides to V official receipts evidencing payment of such amounts to each relevant
                taxing authority and other documents reasonably requested to enable V to claim a
                foreign tax credit or refund.
              </li>
              <li>
                <strong>Price Changes.</strong> Unless otherwise expressly provided in the
                applicable Order Form, V may revise the rates or charges applicable to any Software
                or Services by providing written notice (which may be via electronic notice through
                the Software or by email) to Customer of the revised rates or charges at least
                thirty (30) days prior to the effective date of such revised rates or charges (and
                the Order Form will be deemed amended to include such revised rates or charges);
                provided, however, with respect to any Order Form that (i) lists a specific
                subscription term for the Software and (ii) was executed by V prior to the date of
                such notice, such revised rates or charges for the recurring subscription fees for
                such Software will not become effective until the first day of the next renewal term
                of such subscription that follows the date of such notice.
              </li>
            </ol>

            <li>
              <strong>Representations, Warranties and Limitation of Liability.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                Each party represents and warrants to the other party that: (i) it is duly
                organized, validly existing and in good standing as a corporation or other entity as
                represented herein under the laws and regulations of its jurisdiction of
                incorporation, organization or chartering; (ii) it has the right, power and
                authority to enter the Agreement and to grant the rights and licenses granted
                hereunder and to perform all of its obligations hereunder; (iii) the execution of
                any other agreement incorporating this Agreement by its representative whose
                signature is set forth therein has been duly authorized by all necessary corporate
                or organizational action of the party; (iv) when any agreement incorporating this
                Agreement is executed and delivered by both parties, this Agreement will constitute
                the legal, valid and binding obligation of such party unless otherwise altered by
                the terms of a subsequent agreement, enforceable against such party in accordance
                with its terms; and (v) it will abide by all applicable federal, state and local
                laws and regulations with respect to its rights, duties, and obligations as a party
                in connection with the Agreement.
              </li>
              <li>
                <strong>
                  Except as otherwise provided in this Agreement, (1) V makes no warranties and
                  hereby disclaims all warranties, express or implied, including but not limited to
                  any warranty of merchantability or fitness for a particular purpose, and (2) shall
                  have no obligation to indemnify or defend Customer in connection with any claims
                  related to this Agreement or Customer’s use of the Software.
                </strong>
              </li>
              <li>
                <strong>
                  Other than as prohibited by law or as otherwise provided in this Agreement, V’s
                  liability arising out of or relating to this Agreement, any related agreements, or
                  any of V’s products or services will not in the aggregate exceed the amounts paid
                  by Customer to V under this Agreement during the 12-month period preceding the
                  first event giving rise to the liability. Neither party will be liable for any
                  consequential, indirect, special, incidental, or punitive damages.
                </strong>
              </li>
            </ol>

            <li>
              <strong>Indemnification.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>V Indemnity.</strong> V will have the obligation and right at its expense to
                defend any claim, suit or proceeding brought against Customer, its affiliates or
                their officers, directors, employees or agents so far as it is based on a
                third-party claim that the Software or Documentation supplied by V infringe a United
                States copyright, trademark or trade secret issued as of the Effective Date,
                provided that V will have no indemnity obligation or other liability hereunder
                arising from: (i) Customer's negligence, breach of the Agreement, or alteration of
                the Software or Documentation as provided by V; (ii) Software or Documentation that
                are based upon information, design, specifications, directions, instruction,
                software, data, or material not furnished by V; or (iii) combination of the Software
                or Documentation with any materials, products or services not provided by V. If such
                a claim is or is likely to be made, V will, at its own expense and sole discretion,
                exercise one or the following remedies: (iv) obtain for Customer the right to
                continue to use the Software or Documentation consistent with this Agreement; (v)
                modify the Software or Documentation so they are non-infringing and in compliance
                with this Agreement; (vi) terminate this Agreement with respect the applicable
                Software or Documentation without liability for such termination other than the
                ongoing indemnity obligation hereunder. The foregoing states the entire obligation
                of V and its suppliers, and the exclusive remedy of Customer, with respect to
                infringement of proprietary rights.
              </li>
              <li>
                <strong>Indemnification Procedure.</strong> The party claiming indemnification
                shall: (i) promptly notify the indemnifying party of any claim in respect of which
                the indemnity may apply; (ii) relinquish control of the defense of the claim to the
                indemnifying party; and (iii) provide the indemnifying party with all assistance
                reasonably requested in defense of the claim. The indemnifying party shall be
                entitled to settle any claim without the written consent of the indemnified party so
                long as such settlement only involves the payment of money by the indemnifying party
                and in no way affects any rights of the indemnified party.
              </li>
            </ol>

            <li>
              <strong>Term and Termination.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>For Convenience.</strong> Unless an applicable Order Form identifies a
                specific term of the Agreement in months or years, either party may terminate this
                Agreement at any time by giving 30 days’ written notice of termination to the other
                party.
              </li>
              <li>
                <strong>Subscription Renewal.</strong> If a specific subscription term is stated in
                the applicable Order Form, the subscription will automatically renew for successive
                renewal terms of equal duration to the original subscription term unless either
                party provides written notice of termination to the other party not later than 60
                days before the end of the then-active subscription term.
              </li>
              <li>
                <strong>For Cause.</strong> In the event of a material breach by one party, the
                other party may immediately terminate this Agreement at any time during the existing
                term if, after 30 days following notice of a breach, the breaching party has not
                cured the breach. Either party may immediately terminate this Agreement upon written
                notice to the other party in the event (i) the other party files a petition for
                bankruptcy or is adjudicated to be bankrupt; (ii) a petition in bankruptcy is filed
                against the other party and the petition is not dismissed within 60 calendar days;
                (iii) the other party becomes insolvent or makes an assignment for the benefit of
                its creditors or an arrangement for its creditors pursuant to any bankruptcy or
                other similar law; (iv) the other party Vntinues its business; or (v) a receiver is
                appointed for the other party or its business.
              </li>
              <li>
                <strong>Procedure at Termination.</strong> In the event of termination, and provided
                no unpaid invoices remain outstanding, Customer shall have the right to use export
                facilities in V Software to export Customer data up to the effective date of the
                termination. After that date, V shall have the right to delete any remaining
                Customer data and work product. Termination will not relieve Customer of its payment
                obligations incurred under this Agreement prior to termination, and any amounts
                already paid by Customer shall not be refunded upon termination.
              </li>
              <li>
                <strong>Survival.</strong> The following clauses in these Terms and Conditions shall
                survive termination of this Agreement: Sections 2(c)-(e), 5(b), 6-8, 9(e), and 10.
              </li>
            </ol>

            <li>
              <strong>Miscellaneous.</strong>
            </li>
            <ol
              type="a"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <li>
                <strong>Assignment.</strong> Neither party may assign or transfer this Agreement or
                any of its rights or delegate any of its obligations hereunder whether by operation
                of law or otherwise to any other party without the prior written consent of the
                other party, which consent shall not be unreasonably withheld, except that a party
                may assign this Agreement without such consent to a successor in interest by way of
                merger, acquisition, or sale of all or substantially all of that party’s assets. The
                terms of this Agreement shall be binding upon assignees.
              </li>
              <li>
                <strong>Anti-Slavery and Export Compliance.</strong> Each party shall comply with
                all applicable United States or international anti-slavery and human trafficking
                laws, and export laws and regulations in providing and using the Software and
                Services. Without limiting the generality of the foregoing, Customer shall not make
                the Software or Services available to any person or entity that: (i) uses any form
                of slave, forced, bonded, indentured, or involuntary prison labor; (ii) inadequately
                compensates its employees below the level of what is a living wage; (iii) retains
                employees’ government-issued identification, passports, or work permits as a
                condition of employment; (iv) is located in a country that is subject to a U.S.
                government embargo; (v) is listed on any U.S. government list of prohibited or
                restricted parties; or (vi) is engaged in activities directly or indirectly related
                to the proliferation of weapons of mass destruction.
              </li>
              <li>
                <strong>Choice of Law.</strong> The law of the Commonwealth of Virginia, ignoring
                its law of conflicts or choice of law, will govern any dispute, whether the dispute
                sounds in contract, tort, or otherwise, related to this Agreement or Customer’s use
                of the Software or Documentation.
              </li>
              <li>
                <strong>Counterparts/Electronic Signature and Storage.</strong> This Agreement shall
                be effective as of the Effective Date, and may be executed electronically and in
                counterparts, each of which will be deemed an original, but all of which taken
                together will constitute one single agreement between the parties. This signed
                Agreement or counterparts may be exchanged electronically or stored electronically
                as a photocopy (such as in .pdf format). The parties agree that electronically
                exchanged or stored copies will be enforceable as original documents and consent to
                the use of electronic and/or digital signatures for the execution of this Agreement
                and further agree the use of electronic and/or digital signatures will be binding,
                enforceable and admissible into evidence in any dispute regarding this Agreement.
              </li>
              <li>
                <strong>Disputes.</strong> Any controversy, claim, or dispute arising out of or
                relating to this Agreement, or the alleged breach thereof (“Dispute”), shall first
                be submitted to the executive management of Customer and V for attempted resolution
                of the Dispute. Executive management shall discuss the problem and negotiate in good
                faith in an effort to resolve the Dispute without necessity of any formal proceeding
                relating thereto. If executive management, within fifteen (15) days of their first
                communication has not resolved the Dispute, the parties shall immediately thereafter
                submit the Dispute to mediation, with the fees of the mediator to be divided equally
                between the parties. If the mediator is unable to resolve the Dispute within a
                reasonable time, as determined by the mediator (and not to exceed thirty (30) days
                after the parties meet for mediation), the mediator shall issue a written statement
                to the parties to that effect.
                <strong>
                  The complaining party may then pursue binding arbitration and the parties shall
                  immediately thereafter submit the dispute to binding arbitration administered by
                  the American Arbitration Association in Travis County, Texas in accordance with
                  its commercial arbitration rules (“Rules”) then in effect, and judgment on the
                  award rendered by the arbitrator may be entered in any court having jurisdiction
                  thereof. The arbitrator shall be an attorney licensed in Texas with experience in
                  legal issues related to commercial software-as-a-service. The award shall be made
                  within nine months of the filing of the notice of intention to arbitrate (demand),
                  and the arbitrator shall agree to comply with this schedule before accepting
                  appointment. This time limit may be extended by the arbitrator for good cause
                  shown, or by mutual agreement of the parties. Any award in an arbitration
                  initiated under this clause shall be limited to monetary damages and shall include
                  no injunction or direction to any party other than the direction to pay a monetary
                  amount. The arbitrator will have the authority to allocate the costs of the
                  arbitration process among the parties, including attorneys’ fees. Except as may be
                  required by law or as necessary to enforce the award in a court of law, neither
                  party nor the arbitrator may disclose the existence, content, or results of any
                  arbitration hereunder without the prior written consent of both parties, except
                  that a party may disclose such information to its attorneys or auditors who are
                  subject to confidentiality and ethical obligations. The parties agree that failure
                  or refusal of a party to pay its required share of the deposits for arbitrator
                  compensation or administrative charges shall constitute a waiver by that party to
                  present evidence or cross-examine witnesses. In such event, the other party shall
                  be required to present evidence and legal argument as the arbitrator may require
                  for the making of an award.
                </strong>
              </li>
              <li>
                <strong>Entire Agreement Between Independent Parties.</strong> This is the entire
                agreement between the parties and supersedes all prior agreements and
                representations related to the subject matter of this Agreement. No amendment will
                take effect unless it is in writing and signed by each party. Neither party has
                relied on any representation or promise not expressed in this Agreement in deciding
                to enter into this Agreement. Neither Customer nor V is a legal representative or
                agent of the other, or is legally a partner of the other. V staff performing the
                Services will at all times remain independent contractors with respect to Customer,
                and under the direction and control of V. V will be responsible for all payroll
                taxes, unemployment insurance payments and other obligations of employers with
                respect to V employees performing the Services.
              </li>
              <li>
                <strong>Equitable Relief.</strong> Notwithstanding anything in this Agreement to the
                contrary, each party acknowledges that a breach or threatened breach of this
                Agreement by the other party or its Representatives may cause irreparable harm to
                the non-breaching party for which monetary damages would not be an adequate remedy,
                and hereby agrees that in the event of a breach or a threatened breach by a party or
                its Representatives, the non-breaching party shall be entitled to seek a temporary
                restraining order, injunction, specific performance and any other equitable relief
                available from a court of competent jurisdiction in Travis County, Texas, and the
                parties hereby waive any requirement for the securing or posting of any bond or the
                showing of actual monetary damages in connection with such claim. The existence of
                any claim or cause of action of the breaching party against the non-breaching party,
                whether predicated on this Agreement or otherwise, shall not preclude the
                non-breaching party’s right to equitable relief.
                <strong>
                  Notwithstanding the above, each party irrevocably and unconditionally waives any
                  right it may have to a trial by jury in respect of any legal action arising out of
                  or relating to this Agreement, the transactions contemplated herein, or the
                  validity, protection, interpretation or enforcement thereof.
                </strong>
              </li>
              <li>
                <strong>Force Majeure.</strong> V shall not be liable or responsible to Customer,
                nor be deemed to have defaulted under or breached this Agreement, for any failure or
                delay in fulfilling or performing any term of this Agreement, when and to the extent
                such failure or delay is caused by or results from acts beyond V’s reasonable
                control, including, without limitation: (a) acts of God; (b) flood, fire,
                earthquake, plague, pandemic, locusts, tropical storm or hurricane, or explosion;
                (c) war, invasion, hostilities (whether war is declared or otherwise), terrorist
                threats or acts, riot, or other civil unrest; (d) government order or law; (e)
                actions, embargoes, or blockades in effect on or after the date of this Agreement;
                (f) action by any governmental authority; (g) national or regional emergency; and
                (h) shortage of adequate power or transportation facilities.
              </li>
              <li>
                <strong>Headings.</strong> The headings in this Agreement are for reference only and
                shall not affect the interpretation of this Agreement.
              </li>

              <li>
                <strong>Notice.</strong> Notices required under this Agreement must be provided, if
                to V, by mail or email to the address listed below, and, if to Customer, to an
                address stated on an Order Form. Notices sent by email qualify as written notices
                under this Agreement. Either party may change its address under this Section by
                providing written notice of the change to the other party.
                <p>
                  to V:
                  <br />
                  v.law, LLC
                  <br />
                  7950 Jones Branch Drive
                  <br />
                  8th Floor North
                  <br />
                  McLean, Virginia 22102
                  <br />
                  <a href="mailto:notices@v.law">notices@v.law</a>
                </p>
              </li>
              <li>
                <strong>No Practice of Law.</strong> V is not a law firm and does not provide legal
                advice or engage in the practice of law. Customer acknowledges that it is not
                relying on V for legal advice and has not engaged V or any V employee as an
                attorney.
              </li>
              <li>
                <strong>No Third-Party Beneficiaries.</strong> This Agreement is for the benefit of,
                and is enforceable only by, Customer and V. There are no third-party beneficiaries
                who may enforce this Agreement.
              </li>
              <li>
                <strong>No Waiver.</strong> The failure of either party to insist upon performance
                by the other of any provision of this Agreement, or to exercise any right or remedy
                under this Agreement, is not a waiver of that party’s right to rely upon any
                provision of this Agreement or any right or remedy available to that party in that
                or any other instance.
              </li>
              <li>
                <strong>Severability.</strong> The invalidity or unenforceability of any provision
                of this Agreement will not affect the validity or enforceability of any other
                provision of this Agreement.
              </li>
            </ol>
          </ol>
        </section>
      </Box>

      <Box className={styles.classes.footerBackground}>
        <Footer />
      </Box>
    </Box>
  );
};

export default TermsConditionsView;
