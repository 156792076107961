import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { CreateUserFormSubmitProps } from './types';
import { createUser } from '../../api/usersApi/usersApi';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSelectField from '../../components/formFields/FormSelectField';
import { ROLE_OPTIONS } from '../../constants/constants';
import { CREATE_USER_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { createUserFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    padding: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 725px)': {
      padding: '12px',
      flexBasis: '100%',
    },
  },
  formInfoContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 725px)': {
      flexBasis: 0,
    },
  },
  formSecondInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 725px)': {
      flexBasis: 0,
      marginTop: '32px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const UsersCreateFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();
  const firmId = localStorage.getItem('firmId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createUserFormSchema),
    defaultValues: { ...CREATE_USER_FORM_DEFAULT_VALUES, firmId: firmId || '' },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: CreateUserFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      phone: values.phone,
      role: values.role,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      firmId: Number(values.firmId),
    };

    createUser(submitData)
      .then((res) => {
        if (res.data) {
          setIsFormSubmitting(false);
          navigate(`/users/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'User account created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.formContainer}>
            <Box className={styles.classes.formInfoContainer}>
              <Box className={styles.classes.formInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                <Box mt="16px">
                  <FormTextField name="firstName" label="First Name *" />
                </Box>
                <Box mt="24px">
                  <FormTextField name="lastName" label="Last Name *" />
                </Box>

                <Typography className={styles.classes.fieldHeadline} mt="32px">
                  Credentials
                </Typography>

                <Box mt="16px">
                  <FormTextField name="email" label="Email Address *" />
                </Box>
                <Box mt="24px">
                  <FormMaskedTextField mask="+19999999999" name="phone" label="Mobile Number *" />
                </Box>
              </Box>

              <Box className={styles.classes.formSecondInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Role</Typography>

                <Box mt="16px">
                  <FormSelectField width="100%" options={ROLE_OPTIONS} name="role" label="Role *" />
                </Box>
              </Box>
            </Box>

            <Box display="flex" width="100%" justifyContent="center">
              <Box
                display="flex"
                mt="32px"
                maxWidth="416px"
                flexDirection="column"
                gap="16px"
                width="100%"
              >
                <FormSubmitButton
                  disabled={!isValid || isFormSubmitting}
                  buttonIcon={<SaveIcon />}
                  buttonText="Save"
                  onClick={handleSubmit(onSubmitForm)}
                />
                <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default UsersCreateFormContainer;
