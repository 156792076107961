import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from 'axios';

import {
  calculatePaymentPreview,
  updateCaseFeesConfig,
  getCaseFeesConfig,
  getPaymentPreview,
} from '../../api/casesApi/attyFeesConfigApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormTextField from '../../components/formFields/FormTextField';
import { caseAttorneysFeesFormSchema } from '../../helpers/formSchemas/formSchemas';
import { CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../hooks/useSnackBar';
import { ErrorData, PaymentPreviewData, SaveAttyFeesConfigData } from '../../types';
import { formatWithCurrencyMask } from '../../helpers/formatWithCurrencyMask';

import CasesFeesSummary from './CasesFeesSummary';
import { mapFeesFormValues } from './helpers';
import { CaseAttorneyFeesConfigSubmitProps, FIRM_ROLES } from './types';
import FormCurrencyField from '../../components/formFields/FormCurrencyField';

const useStyles = makeStyles()((theme: Theme) => ({
  feesPageContainer: {
    display: 'flex',
    gap: '16px',
    width: '100%',
    flexBasis: '100%',
    height: '100%',

    '@media (max-width: 1000px)': {
      maxWidth: '600px',
      flexDirection: 'column',
      gap: '30px',
    },
  },
  feesFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '58px',

    '@media (max-width: 1300px)': {
      flexBasis: '600px',
    },

    '@media (max-width: 1000px)': {
      marginTop: '12px',
    },
  },
  feesFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    background: theme.palette.primary.light,
    padding: '32px 12px',
    borderRadius: '16px',
  },
  feesFormFieldsWrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',

    '@media (max-width: 1300px)': {
      flexDirection: 'column',
    },
  },
  feesFormContainerDivider: {
    width: '100%',
    height: '1px',
    background: '#515151',
  },
  feesFormButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    button: {
      flexBasis: '115px',
    },
  },
  secondaryFirmHeadline: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19.2px',
    color: theme.palette.common.white,
  },
  warningBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    padding: '10px',
    background: '#FF866314',
  },
  warningText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: theme.palette.error.main,
  },
}));

const CaseAttorneyFeesView = () => {
  const styles = useStyles();
  const { caseId } = useParams();
  const { setAlert } = useSnackBar();

  const [billCheck, setBillCheck] = useState<PaymentPreviewData | null>(null);
  const [isBillCheckUpdating, setIsBillCheckUpdating] = useState<boolean>(false);
  const [caseSystemName, setCaseSystemName] = useState<string>('');
  const [isAttyConfigDraft, setIsAttyConfigDraft] = useState<boolean>(false);
  const [isWarningBoxOpen, setIsWarningBoxOpen] = useState<boolean>(false);

  // eslint-disable-next-line quotes
  const configLabel = `Participating Firms' Compensation`;

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(caseAttorneysFeesFormSchema),
    defaultValues: CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES,
  });

  const {
    formState: { isValid, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
    getValues,
  } = formMethods;

  const { fields } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const getAttyFeesConfigData = () => {
    if (!caseId) return;

    getCaseFeesConfig(Number(caseId))
      .then((res) => {
        if (res.data) {
          setCaseSystemName(res.data.case.name);
          setIsAttyConfigDraft(res.data.config.isDraft);

          const secondaryFirms = res.data.config.secondaryFirms.length
            ? res.data.config.secondaryFirms.map((firm) => ({
                ...firm,
                config: {
                  fixedFeeAmount: firm.config.fixedFeeAmount
                    ? formatWithCurrencyMask(firm.config.fixedFeeAmount.toFixed(2))
                    : '',
                  variableFeePercent: firm.config.variableFeePercent?.toFixed(3) || '',
                  variableFeeMaximumAmount: firm.config.variableFeeMaximumAmount
                    ? formatWithCurrencyMask(firm.config.variableFeeMaximumAmount.toFixed(2))
                    : '',
                  variableFeeBasis: 'Attorneys’ Fees',
                },
              }))
            : [];

          reset({
            caseFeesConfig: {
              maximumAmount: res.data.config.maximumAmount
                ? formatWithCurrencyMask(res.data.config.maximumAmount.toFixed(2))
                : '',
              variableFeePercent: res.data.config.variableFeePercent.toFixed(3) || '',
              variableFeeBasis: 'Gross Settlement',
            },
            secondaryFirms: secondaryFirms,
          });

          if (!res.data.config.isDraft) {
            setIsWarningBoxOpen(true);
            setAlert({
              message:
                'One or more settlements are processing or completed. Attorneys’ fees are no longer editable.',
              type: 'info',
              noAutoHideDuration: true,
            });
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setIsBillCheckUpdating(false);
        setAlert({
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        });
      });
  };

  const updateAttyFeesConfigData = (data: SaveAttyFeesConfigData) => {
    if (!caseId) return;

    updateCaseFeesConfig(Number(caseId), data)
      .then((res) => {
        if (res.data) {
          setAlert({
            message: 'Attorneys’ fees config updated successfully.',
            type: 'success',
          });

          setBillCheck(res.data);

          // TODO: Why do we need this?
          // getCaseFeesConfig(Number(caseId)).then((res) =>
          //   setIsAttyConfigDraft(res.data.config.isDraft),
          // );
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert({
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        });
      });
  };

  // Generate a payment preview based on the input data.
  // This method is intended for use when the case attorney configuration is not locked.
  const generatePaymentPreviewData = (data: SaveAttyFeesConfigData) => {
    if (!caseId) return;

    setIsBillCheckUpdating(true);

    calculatePaymentPreview(Number(caseId), data)
      .then((res) => {
        if (res.data) {
          setBillCheck(res.data);

          setTimeout(() => {
            setIsBillCheckUpdating(false);
            setAlert({
              type: 'info',
              message: 'Bill check recalculated successfully.',
            });
          }, 3000);
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setIsBillCheckUpdating(false);
        setAlert({
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        });
      });
  };

  // Generate a payment preview based on already existing data.
  const getPaymentPreviewData = () => {
    if (!caseId) return;

    setIsBillCheckUpdating(true);

    getPaymentPreview(Number(caseId))
      .then((res) => {
        if (res.data) {
          setBillCheck(res.data);
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsBillCheckUpdating(false));
  };

  const onSubmitForm = (values: CaseAttorneyFeesConfigSubmitProps) => {
    const submitData = mapFeesFormValues(values);
    updateAttyFeesConfigData(submitData);
  };

  const handleReset = () => {
    reset();
    getPaymentPreviewData();
  };

  useEffect(() => {
    getAttyFeesConfigData();
    getPaymentPreviewData();

    return () => setAlert({ type: '', message: '' });
  }, [caseId]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  return (
    <Box className={styles.classes.feesPageContainer}>
      <FormProvider {...formMethods}>
        <Box className={styles.classes.feesFormWrapper}>
          {!isAttyConfigDraft && isWarningBoxOpen && (
            <Box className={styles.classes.warningBox}>
              <Typography className={styles.classes.warningText}>
                One or more settlements are processing or completed. Attorneys’ fees are no longer
                editable
              </Typography>
              <IconButton
                sx={{
                  padding: 0,
                  color: '#FFF',
                }}
                onClick={() => setIsWarningBoxOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box className={styles.classes.feesFormContainer}>
            <Typography className={styles.classes.secondaryFirmHeadline}>{configLabel}</Typography>
            <Box className={styles.classes.feesFormFieldsWrapper}>
              <FormCurrencyField
                digitsAfterDecimal={3}
                name="caseFeesConfig.variableFeePercent"
                label="Variable Fee"
                valueDefinition="%"
                disabled={!isAttyConfigDraft}
              />
              <FormTextField
                name="caseFeesConfig.variableFeeBasis"
                label="Variable Fee Basis"
                readOnly
                disabled={!isAttyConfigDraft}
              />
              <FormCurrencyField
                name="caseFeesConfig.maximumAmount"
                label="Maximum Variable Fee"
                disabled={!isAttyConfigDraft}
              />
            </Box>
          </Box>

          {fields.map((field, idx) => (
            <Box key={field.id} className={styles.classes.feesFormContainer}>
              <Typography className={styles.classes.secondaryFirmHeadline}>{`${field.name} - ${
                FIRM_ROLES[field.role as keyof typeof FIRM_ROLES]
              }`}</Typography>

              <Box className={styles.classes.feesFormFieldsWrapper}>
                <FormCurrencyField
                  name={`secondaryFirms.${idx}.config.fixedFeeAmount`}
                  label="Fixed Fee"
                  disabled={!isAttyConfigDraft}
                />
                <FormCurrencyField
                  digitsAfterDecimal={3}
                  name={`secondaryFirms.${idx}.config.variableFeePercent`}
                  label="Variable Fee"
                  valueDefinition="%"
                  disabled={!isAttyConfigDraft}
                />
                <FormTextField
                  name={`secondaryFirms.${idx}.config.variableFeeBasis`}
                  label="Variable Fee Basis"
                  readOnly
                  disabled={!isAttyConfigDraft}
                />
                <FormCurrencyField
                  name={`secondaryFirms.${idx}.config.variableFeeMaximumAmount`}
                  label="Maximum Variable Fee"
                  disabled={!isAttyConfigDraft}
                />
              </Box>
            </Box>
          ))}

          <Box className={styles.classes.feesFormButtonsContainer}>
            {isDirty && <FormCancelButton onClick={handleReset} />}
            <FormSubmitButton
              disabled={!isValid || !isDirty || !isAttyConfigDraft}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
          </Box>
        </Box>
        <CasesFeesSummary
          billCheck={billCheck}
          caseSystemName={caseSystemName}
          isAttyConfigDraft={isAttyConfigDraft}
          isBillCheckUpdating={isBillCheckUpdating}
          handleCalculateCheckBill={generatePaymentPreviewData}
        />
      </FormProvider>
    </Box>
  );
};

export default CaseAttorneyFeesView;
