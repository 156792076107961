import { makeStyles } from 'tss-react/mui';

import { Dialog, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '44px 32px',
      maxWidth: '480px',
      boxSizing: 'border-box',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  popupTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '12px',
    textAlign: 'center',
  },
  popupContentContainer: {
    padding: 0,
    paddingTop: '10px',
  },
  popupSecondaryText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#C3C4C8',
  },
}));

interface RequestDemoPopupProps {
  isOpen: boolean;
  onClose?: () => void;
}

const RequestDemoSuccessPopup = ({ isOpen, onClose }: RequestDemoPopupProps) => {
  const styles = useStyles();

  return (
    <Dialog open={isOpen} className={styles.classes.popupContainer} onClose={onClose}>
      <DialogTitle className={styles.classes.popupTitle}>Thank you!</DialogTitle>

      <DialogContent className={styles.classes.popupContentContainer}>
        <Typography className={styles.classes.popupSecondaryText}>
          We will be in touch with you shortly
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RequestDemoSuccessPopup;
