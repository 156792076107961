import { getCasesList } from '../../api/casesApi/casesApi';
import { getDefendantsList } from '../../api/defendantsApi/defendantsApi';
import { PaginationQueryParams } from '../../types';

export const mapCasesQueryResults = (array: { id: string; referenceId: string }[]) => {
  return array.map((item) => ({
    id: item.id,
    label: item.referenceId,
  }));
};

export const mapDefendantsQueryResults = (array: { id: string; name: string }[]) => {
  return array.map((item) => ({
    id: item.id,
    label: item.name,
  }));
};

export const handleGetCases = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
}: PaginationQueryParams) => {
  return await getCasesList({ page, size, count, match, filters });
};

export const handleGetDefendants = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
}: PaginationQueryParams) => {
  return await getDefendantsList({ page, size, count, match, filters });
};
