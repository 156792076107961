import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  useTheme,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles<{
  maxWidth?: string;
  selectValue?: string;
  disabled?: boolean;
  minWidth?: string;
  width?: string;
}>()((theme: Theme, { maxWidth, selectValue, disabled, minWidth, width }) => ({
  defaultSelect: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    ...(width && { width }),
    margin: 0,
    justifyContent: 'flex-end',
    background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',

    ul: {
      li: {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Inter',
      },
    },

    '@media (max-width: 550px)': {
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.dark,
      borderRadius: '12px',
    },

    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white,
      padding: '12px 16px',
      borderRadius: '12px',

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        WebkitTextFillColor: theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      '&:hover': {
        border: 'none',

        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'none',
          },
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiInputLabel-root[data-shrink="false"]': {
      color: theme.palette.text.disabled,
      top: '3px',
    },

    '& .MuiInputLabel-root[data-shrink="true"]': {
      color: theme.palette.text.disabled,

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },
    },

    '& .MuiSelect-icon': {
      color: selectValue ? theme.palette.common.white : theme.palette.text.disabled,

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
  },
  dropdownMenuItem: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
  },
}));

interface FormSelectFieldProps {
  className?: string;
  name: string;
  label: string;
  options: Array<{ id: string; label: string }>;
  readOnly?: boolean;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
  minWidth?: string;
  width?: string;
  customOnChange?: (value: string, name: string) => void;
  handleChange?: (value: string, name: string) => void;
  resetFields?: () => void;
  shouldClearable?: boolean;
}

const FormSelectField = ({
  className,
  name,
  label,
  options,
  maxWidth,
  disabled,
  readOnly,
  minWidth,
  width,
  customOnChange,
  resetFields,
  handleChange,
  shouldClearable,
}: FormSelectFieldProps) => {
  const theme = useTheme();

  const { control, setValue, watch } = useFormContext();

  const selectValue = watch(name);

  const styles = useStyles({ maxWidth, selectValue, disabled, minWidth, width });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value, { shouldDirty: true, shouldValidate: true });
    handleChange && handleChange(e.target.value, name);
  };

  return (
    <>
      {options && (
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <FormControl
              className={clsx(styles.classes.defaultSelect, className)}
              size="small"
              sx={{ m: 1, minWidth: maxWidth }}
              disabled={disabled}
            >
              <InputLabel>{label}</InputLabel>
              <Select
                label={label}
                defaultValue={value || ''}
                value={value}
                onChange={(e) => {
                  resetFields && resetFields();
                  customOnChange
                    ? customOnChange(e.target.value, name)
                    : onChange(e as React.ChangeEvent<HTMLInputElement>);
                }}
                disabled={disabled}
                readOnly={readOnly}
                {...(shouldClearable && {
                  IconComponent: value
                    ? () => (
                        <IconButton
                          onClick={() => setValue(name, '')}
                          disableRipple
                          sx={{
                            color: 'white',
                            svg: {
                              fontSize: '20px',
                            },
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )
                    : undefined,
                })}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    },
                  },
                }}
              >
                {options &&
                  options.map((option) => (
                    <MenuItem
                      className={styles.classes.dropdownMenuItem}
                      key={option.id}
                      value={option.id}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </>
  );
};

export default FormSelectField;
