import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

import FormTextField from '../../../components/formFields/FormTextField';
import FormToggleField from '../../../components/formFields/FormToggleField';

const useStyles = makeStyles<{ isLongData?: boolean }>()((theme: Theme, { isLongData }) => ({
  tableContainer: {
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '16px',
    overflow: 'auto',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 500,
    marginTop: '16px',
    minHeight: isLongData ? '300px' : 'inherit',

    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  tableHeaderCell: {
    background: theme.palette.primary.light,

    '& .MuiTableCell-root': {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      borderBottom: '1px solid #515151',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '16px',
      height: '56px',
      padding: '16px 12px 16px 8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '200px',
    },
  },
  tableBodyCell: {
    '& .MuiTableCell-root': {
      color: theme.palette.common.white,
      borderBottom: '1px solid #4A4E5D',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '16px',
      height: '65px',
      padding: 0,
      paddingLeft: '8px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

interface SettlementsDeductionsTableProps {
  disabled?: boolean;
  handleChangeAddToggle: () => void;
  fields: Record<'id', string>[];
}

const SettlementsDeductionsTable = ({
  disabled,
  handleChangeAddToggle,
  fields,
}: SettlementsDeductionsTableProps) => {
  const { watch } = useFormContext();
  const deductionsFields = watch('deduction');
  const isLongData = deductionsFields?.length > 3;

  const styles = useStyles({ isLongData });

  return (
    <TableContainer className={styles.classes.tableContainer} component={Paper}>
      <Table sx={{ minWidth: 430 }} size="small">
        <TableHead className={styles.classes.tableHeaderCell}>
          <TableRow>
            <TableCell>Entry date</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Assigned</TableCell>
            <TableCell align="right">Add</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={clsx(styles.classes.tableBodyCell)}>
          {!!deductionsFields?.length &&
            fields.map((field, idx: number) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormTextField
                    name={`deduction.${idx}.createdAt`}
                    label="Entry date"
                    readOnly
                    isReadOnly
                    isOnlyText
                  />
                </TableCell>
                <TableCell>
                  <FormTextField
                    name={`deduction.${idx}.type`}
                    label="Type"
                    readOnly
                    isReadOnly
                    isOnlyText
                  />
                </TableCell>

                <TableCell>
                  <FormTextField
                    name={`deduction.${idx}.amount`}
                    label="Amount"
                    readOnly
                    isReadOnly
                    isOnlyText
                  />
                </TableCell>

                <TableCell>
                  <FormTextField
                    name={`deduction.${idx}.isAssigned`}
                    label="Assigned"
                    readOnly
                    isReadOnly
                    isOnlyText
                  />
                </TableCell>

                <TableCell>
                  <FormToggleField
                    name={`deduction.${idx}.isAdded`}
                    customHandleChange={handleChangeAddToggle}
                    disabled={disabled}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettlementsDeductionsTable;
