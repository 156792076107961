import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box } from '@mui/material';

import { ReactComponent as LogoIcon } from '../LandingView/assets/logo.svg';
import RequestSupportForm from './RequestSupportForm';

const useStyles = makeStyles()(() => ({
  pageBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100dvh',
    width: '100%',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },

  supportFormWrapper: {
    width: '480px',
    height: 'calc(100% - 80px)',
    display: 'block',
    overflow: 'auto',
    padding: '40px 0',
    boxSizing: 'border-box',

    '::-webkit-scrollbar': {
      width: 0,
    },

    '@media (max-width: 512px)': {
      width: '100%',
      padding: '40px 16px',
    },
  },

  signInLogoContainer: {
    width: 'fit-content',
    margin: '0 auto',
    marginBottom: '60px',
    svg: {
      color: '#FFF',
    },
  },
}));

const SupportPageView = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.pageBackground}>
      <Box className={classes.supportFormWrapper}>
        <Box className={classes.signInLogoContainer}>
          <Link to="/">
            <LogoIcon />
          </Link>
        </Box>

        <RequestSupportForm />
      </Box>
    </Box>
  );
};

export default SupportPageView;
