import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v2/deductions';

export const getDeductionsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const createDeductionRecord = async (data: unknown) => {
  return await api.post(BASE_URL, data);
};

export const getDeductionRecord = async (deductionId: string) => {
  const targetUrl = `${BASE_URL}/${deductionId}`;

  return await api.get(targetUrl);
};

export const updateDeductionRecord = async (deductionId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/${deductionId}`;

  return await api.put(targetUrl, data);
};

export const getDeductionTimeline = async (deductionId: string) => {
  const targetUrl = `${BASE_URL}/${deductionId}/timeline`;

  return await api.get(targetUrl);
};

export const deleteDeductionRecord = async (deductionId: string) => {
  const targetUrl = `${BASE_URL}/${deductionId}`;

  return await api.delete(targetUrl);
};
