import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CaseTypeCategories } from '../../constants/constants';
import { splitCamelCaseString } from '../../helpers/splitCamelCaseString';

import CategoryButton from './CategoryButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
}));

interface CategoryListProps {
  readonly selectedCategory: string | null;
  readonly handleSelect: (category: string) => void;
}

const CategoryList = ({ selectedCategory, handleSelect }: CategoryListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      {Object.keys(CaseTypeCategories).map((category) => {
        return (
          <CategoryButton
            key={category}
            buttonText={splitCamelCaseString(category)}
            isActive={selectedCategory === category}
            onClick={() => handleSelect(category)}
          />
        );
      })}
    </Box>
  );
};

export default CategoryList;
