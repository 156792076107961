import { v4 as uuidv4 } from 'uuid';

import { ExpectedAnswer } from '../../../constants/constants';
import { QuestionnaireItem, QuestionnaireItemFormat } from '../../../types';
import { FormSnippetSubmitData } from '../types';

export const mapFormSnippetQueryResult = (data: { items: Array<QuestionnaireItem> }) => {
  const mappedData: FormSnippetSubmitData = {
    items: data.items.map((item) => {
      return {
        // To avoid duplicate IDs
        id: uuidv4(),
        code: item.code,
        format: item.format,
        systemName: item.systemName,
        questionText: item.questionText,
        immutable: item?.immutable || false,
        dependsOnItem: item?.dependsOnItem?.booleanItemId
          ? {
              booleanItemId: item.dependsOnItem.booleanItemId,
              expectedValue: item.dependsOnItem.expectedValue
                ? ExpectedAnswer.Yes
                : ExpectedAnswer.No,
            }
          : {
              booleanItemId: '',
              expectedValue: '',
            },
        ...(item.format === QuestionnaireItemFormat.DateRange && {
          allowPartial: item?.allowPartial || false,
        }),
        ...(item.format === QuestionnaireItemFormat.Text && {
          placeholder: item?.placeholder || '',
        }),
      };
    }),
  };

  return mappedData;
};
