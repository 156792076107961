import moment from 'moment/moment';

import api from '../api/service/api.service';
import { PaginationQueryParams } from '../types';

import { parseQueryFilters } from './parseQueryFilters';

export const downloadCsv = async (
  targetPath: string,
  request: 'GET' | 'POST',
  filename: string,
  paginationQueryParams?: PaginationQueryParams,
) => {
  const queryFilters = parseQueryFilters(paginationQueryParams?.filters);
  const targetUrl = `${targetPath}/?${
    paginationQueryParams?.match ? `&match=${paginationQueryParams.match}` : ''
  }${queryFilters || ''}`;

  try {
    let response;

    if (request === 'GET') {
      response = await api.get(targetUrl);
    } else {
      response = await api.post(
        targetUrl,
        {},
        {
          responseType: 'blob',
        },
      );
    }

    const blob = new Blob([response.data], { type: 'text/csv' });
    const time = moment().format('HH-mm');

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}--${time}.csv`;

    link.click();

    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error('Error downloading CSV file:', error);
  }
};
