import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { paymentsAccountTabs } from '../../constants/tabRoutes';

const PaymentsAccountView = () => {
  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel tabsList={paymentsAccountTabs} locationSlicePosition={2} />
      </Box>
    </>
  );
};

export default PaymentsAccountView;
