import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { DeductionData } from '../../../types';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

interface DeductionsTableDataRowProps {
  tableData: DeductionData[];
}

const DeductionsTableDataRow = ({ tableData }: DeductionsTableDataRowProps) => {
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/payments/deduction/${id}/home`);
  };

  const formattedFirmsList =
    tableData &&
    tableData.map((deduction: DeductionData) => ({
      id: deduction.id,
      rowFields: {
        name: deduction.case.name,
        plaintiff: `${deduction.plaintiff.firstName} ${deduction.plaintiff.middleName || ''} ${
          deduction.plaintiff.lastName
        }`,
        defendant: deduction.defendant?.name || '-',
        entryDate: formatDate(deduction.createdAt as string),
        type: deduction.type,
        // amount: `$${deduction.amount}`,
        amount: numberToUSDCurrency(deduction.amount),
        status: parseStatusTitle(deduction.status),
        paidAt: deduction.paidAt ? formatDate(deduction.paidAt as string) : '-',
      },
    }));

  return (
    <>
      {formattedFirmsList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DeductionsTableDataRow;
