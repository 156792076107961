type RouteTabs = Array<{
  name: string;
  path: string;
  matchUrls: Array<string>;
}>;

const firstTabIndex = 0;

export const getTabIdByLocation = (
  tabs: RouteTabs,
  location: string,
  locationSlicePosition?: number,
) => {
  if (!location) return firstTabIndex;

  const splitedLocation = location.split('/');

  const tabName = locationSlicePosition
    ? splitedLocation[locationSlicePosition]
    : splitedLocation[2];

  if (!tabName) return firstTabIndex;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return firstTabIndex;

  return tabId;
};
