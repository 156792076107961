import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams, ResultSet } from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import { FirmUnrestrictedAccountQueryData } from '../../views/FirmBankingView/types';

const BASE_URL = '/v1';

export const getFirmUnrestrictedAccounts = async (
  { size, page, count, activeSortField }: PaginationQueryParams,
  firmId: number | string,
) => {
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);
  const targetUrl = `${BASE_URL}/firms/${Number(
    firmId,
  )}/banking/unrestricted-accounts?size=${size}&page=${page}&count=${count}${sortFilters}`;

  return await api.get<ResultSet<FirmUnrestrictedAccountQueryData>>(targetUrl);
};

export const createFirmUnrestrictedAccount = async (
  firmId: number | string,
  data: {
    accountNumber: string;
    routingNumber: string;
    name: string;
    isVerified: boolean;
  },
) => {
  const targetUrl = `${BASE_URL}/firms/${Number(firmId)}/banking/unrestricted-accounts`;

  return await api.post(targetUrl, data);
};

export const getFirmUnrestrictedAccount = async (id: number | string) => {
  const targetUrl = `${BASE_URL}/unrestricted-accounts/${id}`;

  return await api.get<FirmUnrestrictedAccountQueryData>(targetUrl);
};

export const updateFirmUnrestrictedAccount = async (
  id: number | string,
  data: {
    accountNumber?: string;
    routingNumber?: string;
    name?: string;
    isVerified?: boolean;
  },
) => {
  const targetUrl = `${BASE_URL}/unrestricted-accounts/${id}`;

  return await api.put<FirmUnrestrictedAccountQueryData>(targetUrl, data);
};

export const deleteFirmUnrestrictedAccount = async (id: number | string) => {
  const targetUrl = `${BASE_URL}/unrestricted-accounts/${id}`;

  return await api.delete<void>(targetUrl);
};
