import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { translateStatus } from '../../helpers/translateStatus';
import { DefendantData } from '../../types';

interface AdminsTableDataRowProps {
  tableData: DefendantData[];
}

const DefendantsTableDataRow = ({ tableData }: AdminsTableDataRowProps) => {
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/defendants/${String(id)}/home`);
  };

  const formattedLawyersList =
    tableData &&
    tableData.map((defendant) => ({
      id: defendant.id,
      rowFields: {
        name: defendant.name,
        createdAt: formatDate(defendant.createdAt),
        status: translateStatus(true),
        activeCases: '-',
        grossSettlements: '-',
        netSettlements: '-',
      },
    }));

  return (
    <>
      {formattedLawyersList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DefendantsTableDataRow;
