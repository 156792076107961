import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { DefendantFormSubmitProps } from './types';

import { getDefendantRecord, updateDefendantRecord } from '../../api/defendantsApi/defendantsApi';

import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormTextField from '../../components/formFields/FormTextField';
// import CommonButton from '../../components/buttons/CommonButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import Loader from '../../components/Loader';

import { DEFENDANT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { defendantFormSchema } from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
// import useWindowWidth from '../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    padding: '32px',
    flexBasis: '722px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 725px)': {
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 725px)': {
      flexBasis: '100%',
    },
  },
  formActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '240px',

    '@media (max-width: 725px)': {
      marginTop: '32px',
      flexBasis: 0,
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const DefendantsEditFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { defendantId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  // const windowWidth = useWindowWidth();

  const [isDataLoading, setIsDataLoading] = useState(true);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(defendantFormSchema),
    defaultValues: DEFENDANT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: DefendantFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      name: values.name,
      referenceId: values.referenceId,
      firmId: values.firmId,
    };

    updateDefendantRecord(defendantId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/defendants');

          setAlert((prev) => ({
            ...prev,
            message: 'Defendant account updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    getDefendantRecord(defendantId as string).then((res) => {
      if (res.data) {
        reset({
          id: res.data.id,
          referenceId: res.data.referenceId,
          name: res.data.name,
          firmId: res.data.firm.id,
        });

        setPageTitle && setPageTitle(res.data.name);
        setIsDataLoading(false);
      }
    });

    return () => setPageTitle && setPageTitle('');
  }, [defendantId]);

  return (
    <>
      {isDataLoading ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.formWrapper}>
            <FormProvider {...formMethods}>
              <form className={styles.classes.formContainer}>
                <Box className={styles.classes.formInfoContainer}>
                  <Box className={styles.classes.formInfoBlock}>
                    <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                    <Box mt="16px">
                      <FormCopyTextField entityName="Defendant" name="id" label="Id" isReadOnly />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="referenceId" label="Reference Id *" />
                    </Box>
                    <Box mt="24px">
                      <FormTextField name="name" label="Name *" />
                    </Box>
                  </Box>

                  {/* <Box className={styles.classes.formActionsContainer}>
                    <Typography className={styles.classes.fieldHeadline}>Actions</Typography>

                    <Box mt="16px">
                      <CommonButton
                        type="error"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                        maxWidth={windowWidth < 725 ? 'none' : '240px'}
                      />
                    </Box>
                  </Box> */}
                </Box>

                <Box display="flex" width="100%" justifyContent="center">
                  <Box
                    display="flex"
                    mt="32px"
                    maxWidth="416px"
                    flexDirection="column"
                    gap="16px"
                    width="100%"
                  >
                    <FormSubmitButton
                      disabled={!isValid || !isDirty || isFormSubmitting}
                      buttonIcon={<SaveIcon />}
                      buttonText="Save"
                      onClick={handleSubmit(onSubmitForm)}
                    />
                    <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
};

export default DefendantsEditFormContainer;
