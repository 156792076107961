import { Theme, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';

import FormTextField from '../../components/formFields/FormTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0px',
  },
  dialogContent: {
    marginTop: '16px',
    padding: '0px',
  },
  form: {
    padding: '8px 0px 16px 0px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '0px',
    marginTop: '32px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  buttonDefault: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  createButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(100, 'The maximum field length is 100 characters.')
      .required('Required *'),
  })
  .required();

interface CreateCaseTypePopupProps {
  isOpen: boolean;
  onMainButtonClick: (name: string) => void;
  onSecondaryButtonClick: () => void;
}

interface FormSubmitProps {
  name: string;
}

const CreateCaseTypePopup = ({
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: CreateCaseTypePopupProps) => {
  const { classes } = useStyles();

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = formMethods;

  const handleCreateButtonClick = (values: FormSubmitProps) => {
    reset();
    onMainButtonClick(values.name);
  };

  const handleCancelButtonClick = () => {
    reset();
    onSecondaryButtonClick();
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open={isOpen}
      onClose={handleCancelButtonClick}
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle className={classes.dialogTitle}>{'Create New Case Type'}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormProvider {...formMethods}>
          <form className={classes.form}>
            <FormTextField name="name" label="Name" />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.buttonDefault, classes.createButton)}
          disabled={!isValid}
          onClick={handleSubmit(handleCreateButtonClick)}
        >
          {'Create'}
        </Button>
        <Button
          className={clsx(classes.buttonDefault, classes.cancelButton)}
          onClick={handleCancelButtonClick}
        >
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCaseTypePopup;
