import { useNavigate, useParams } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { DocumentData } from '../../../types';
import { getDatesDiff } from '../../../helpers/getDatesDiff';
import LinkFile from '../../../components/LinkFile/LinkFile';

interface DocumentsTableDataRowProps {
  tableData: DocumentData[];
}

const DocumentsTableDataRow = ({ tableData }: DocumentsTableDataRowProps) => {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/requests/document-request/${id}/home`);
  };

  const formattedDocumentsList =
    tableData &&
    tableData.map((document: DocumentData) => ({
      id: document.id,
      rowFields: {
        ...(!caseId && {
          name: `${document.client.firstName} ${document.client.middleName || ''} ${
            document.client.lastName
          }`,
        }),
        sentAt: document.sentAt ? formatDate(document.sentAt as string) : '-',
        documentName: document.name,
        status: document.status,
        completionDate: document.completedAt ? formatDate(document.completedAt as string) : '-',
        completionTime:
          document.status === 'Completed'
            ? getDatesDiff(document.createdAt, document.completedAt)
            : '-',
        file: document.fileName ? (
          <LinkFile documentId={`${document.id}`} label={document.fileName} />
        ) : (
          '-'
        ),
      },
    }));

  return (
    <>
      {formattedDocumentsList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DocumentsTableDataRow;
