import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';

import FormCheckboxField from '../formFields/FormCheckboxField';
import FormSelectField from '../formFields/FormSelectField';
import FormTextField from '../formFields/FormTextField';
import FormTextFieldWithPopupInput from '../formFields/FormTextFieldWithPopupInput/FormTextFieldWithPopupInput';
import { DATA_TYPES_OPTIONS, EXPECTED_ANSWERS_OPTIONS } from '../../constants/constants';
import { QuestionnaireItemFormat, SelectOption } from '../../types';
import { QuestionnaireSubmitForm } from '../../views/QuestionnairesView/types';
import useWindowWidth from '../../hooks/useWindowWidth';

import IconButton from './IconButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 1024px)': {
      flexDirection: 'row',
      gap: '10px',
    },
  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    margin: '8px 0px 16px 0px',
    gap: '10px',

    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      margin: '0px',
      gap: '20px',
    },
  },
  formField: {
    minWidth: '170px',

    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));

interface ItemProps {
  readonly booleanItems: SelectOption[];
  readonly itemIdx: number;
  readonly numberOfItems: number;
  readonly sectionIdx: number;
  readonly handleUpdateBooleanItems: (items: SelectOption[]) => void;
  readonly onDeleteClick: (index?: number | number[] | undefined) => void;
  readonly onMove: (indexA: number, indexB: number) => void;
  readonly openWarningPopup: () => void;
}

const Item = ({
  booleanItems,
  itemIdx,
  numberOfItems,
  sectionIdx,
  handleUpdateBooleanItems,
  onDeleteClick,
  onMove,
  openWarningPopup,
}: ItemProps) => {
  const { classes } = useStyles();
  const windowWidth = useWindowWidth();
  const { getValues, setValue, watch } = useFormContext<{ form: QuestionnaireSubmitForm }>();

  const item = watch(`form.sections.${sectionIdx}.items.${itemIdx}`);

  // TODO: Can we make it simpler?
  const handleDeleteButtonClick = () => {
    if (item.format === QuestionnaireItemFormat.Boolean) {
      const { form } = getValues();

      for (let i = 0; i < form.sections.length; i++) {
        for (let j = 0; j < form.sections[i].items.length; j++) {
          if (form.sections[i].items[j].dependsOnItem.booleanItemId === item.id) {
            setValue(`form.sections.${i}.items.${j}.dependsOnItem.booleanItemId`, '', {
              shouldDirty: true,
            });

            setValue(`form.sections.${i}.items.${j}.dependsOnItem.expectedValue`, '', {
              shouldDirty: true,
            });
          }
        }
      }

      handleUpdateBooleanItems([...booleanItems.filter((el) => el.id !== item.id)]);
    }

    onDeleteClick(itemIdx);
  };

  const handleFormatChange = (value: string) => {
    if (value === QuestionnaireItemFormat.Boolean) {
      handleUpdateBooleanItems([...booleanItems, { id: item.id, label: item.systemName }]);
    }

    if (item.format === QuestionnaireItemFormat.Boolean) {
      handleUpdateBooleanItems([...booleanItems.filter((el) => el.id !== item.id)]);
    }

    setValue(
      `form.sections.${sectionIdx}.items.${itemIdx}`,
      {
        id: item.id,
        code: item.code,
        systemName: item.systemName,
        questionText: item.questionText,
        immutable: item.immutable,
        dependsOnItem: item.dependsOnItem,
        format: value as QuestionnaireItemFormat,
        ...(value === QuestionnaireItemFormat.DateRange && { allowPartial: false }),
        ...(value === QuestionnaireItemFormat.Text && { placeholder: '' }),
      },
      {
        shouldDirty: true,
      },
    );
  };

  const handleBlurSystemName = (value: string) => {
    if (item.format === QuestionnaireItemFormat.Boolean) {
      const index = booleanItems.findIndex((el) => el.id === item.id);

      if (index !== -1 && booleanItems[index].label !== value) {
        const tmp = [...booleanItems];
        tmp[index].label = value;
        handleUpdateBooleanItems(tmp);
      }
    }
  };

  const handleDependsOnChange = (value: string) => {
    if (item.id === value) {
      openWarningPopup();
      return;
    }

    if (!value) {
      setValue(`form.sections.${sectionIdx}.items.${itemIdx}.dependsOnItem.expectedValue`, '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    setValue(`form.sections.${sectionIdx}.items.${itemIdx}.dependsOnItem.booleanItemId`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleMoveUpButtonClick = () => {
    onMove(itemIdx, itemIdx - 1);
  };

  const handleMoveDownButtonClick = () => {
    onMove(itemIdx, itemIdx + 1);
  };

  return (
    <Box className={classes.container}>
      {windowWidth <= 1024 && (
        <Box className={classes.actionsWrapper}>
          <IconButton
            buttonTitle="Move up"
            buttonType="move-up"
            disabled={itemIdx === 0}
            onClick={handleMoveUpButtonClick}
          />
          <IconButton
            buttonTitle="Move down"
            buttonType="move-down"
            disabled={itemIdx === numberOfItems - 1}
            onClick={handleMoveDownButtonClick}
          />
          <IconButton buttonTitle="Delete" buttonType="delete" onClick={handleDeleteButtonClick} />
        </Box>
      )}
      <Box className={classes.inputsWrapper}>
        {windowWidth > 1024 && <DragIndicatorIcon htmlColor="rgba(255, 255, 255, 0.3)" />}
        <FormSelectField
          className={classes.formField}
          options={booleanItems}
          name={`form.sections.${sectionIdx}.items.${itemIdx}.dependsOnItem.booleanItemId`}
          label="Depends On"
          disabled={!booleanItems.length}
          customOnChange={handleDependsOnChange}
        />
        <FormSelectField
          className={classes.formField}
          options={EXPECTED_ANSWERS_OPTIONS}
          name={`form.sections.${sectionIdx}.items.${itemIdx}.dependsOnItem.expectedValue`}
          label="Expected Answer"
          disabled={!item.dependsOnItem?.booleanItemId}
        />
        <Box className={classes.formField}>
          <FormTextField
            name={`form.sections.${sectionIdx}.items.${itemIdx}.systemName`}
            label="System Name *"
            onBlur={handleBlurSystemName}
          />
        </Box>
        <FormSelectField
          className={classes.formField}
          options={DATA_TYPES_OPTIONS}
          name={`form.sections.${sectionIdx}.items.${itemIdx}.format`}
          label="Format"
          customOnChange={handleFormatChange}
        />
        <Box width="100%">
          <FormTextFieldWithPopupInput
            label="Question Text *"
            name={`form.sections.${sectionIdx}.items.${itemIdx}.questionText`}
            value={item.questionText}
            handleChange={(value) =>
              setValue(`form.sections.${sectionIdx}.items.${itemIdx}.questionText`, value, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        </Box>
        <Box className={classes.formField}>
          <FormTextField
            name={`form.sections.${sectionIdx}.items.${itemIdx}.code`}
            label="Code *"
          />
        </Box>

        {windowWidth > 1024 ? (
          <>
            <Box className={classes.formField}>
              {item.format === QuestionnaireItemFormat.DateRange && (
                <FormCheckboxField
                  name={`form.sections.${sectionIdx}.items.${itemIdx}.allowPartial`}
                  label="Allow Partial"
                />
              )}
              {item.format === QuestionnaireItemFormat.Text && (
                <FormTextField
                  name={`form.sections.${sectionIdx}.items.${itemIdx}.placeholder`}
                  label="Placeholder"
                />
              )}
            </Box>
            <IconButton
              buttonTitle="Delete"
              buttonType="delete"
              onClick={handleDeleteButtonClick}
            />
          </>
        ) : (
          <>
            {item.format === QuestionnaireItemFormat.DateRange && (
              <Box className={classes.formField}>
                <FormCheckboxField
                  name={`form.sections.${sectionIdx}.items.${itemIdx}.allowPartial`}
                  label="Allow Partial"
                />
              </Box>
            )}
            {item.format === QuestionnaireItemFormat.Text && (
              <Box className={classes.formField}>
                <FormTextField
                  name={`form.sections.${sectionIdx}.items.${itemIdx}.placeholder`}
                  label="Placeholder"
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Item;
