import { Navigate } from 'react-router';

import useAuth from '../../hooks/useAuth';

const AuthVerifyView = () => {
  const { token } = useAuth();

  if (token) {
    return <Navigate to="cases" />;
  }

  return <div></div>;
};

export default AuthVerifyView;
