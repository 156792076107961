import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import SignaturesTableDataRow from './SignaturesTableDataRow';

import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import TableContainer from '../../../components/Table/TableContainer';
import {
  CASES_SIGNATURE_LIST_TABLE_LABELS,
  SIGNATURES_LIST_TABLE_LABELS,
} from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, FilterTypes, SignatureData } from '../../../types';
import { getSignaturesList } from '../../../api/requestsApi/signaturesApi';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import { SIGNATURE_REQUESTS_STATUS_FILTER_OPTIONS } from '../../../components/TableFilters/constants';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const SignaturesView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [signaturesList, setSignaturesList] = useState<SignatureData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    plaintiff: {
      order: '',
      fieldName: 'plaintiff',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    completedAt: {
      order: '',
      fieldName: 'completedAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getSignaturesList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setSignaturesList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, activeSortField]);

  const exportList = () => {
    downloadCsv('/v3/signature-requests/export', 'POST', 'Signatures_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      filters: { ...filters, ...(hasParams && params) },
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Signature requests exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !signaturesList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            exportList={exportList}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              client
              timeFilter={'REQUEST DATE'}
              statusFilter={SIGNATURE_REQUESTS_STATUS_FILTER_OPTIONS}
              isStatusArray
            />
          )}

          {!isDataLoading && signaturesList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={
                params.caseId ? CASES_SIGNATURE_LIST_TABLE_LABELS : SIGNATURES_LIST_TABLE_LABELS
              }
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <SignaturesTableDataRow tableData={signaturesList} />
            </TableContainer>
          )}
        </>
      )}
      <Outlet />
    </>
  );
};

export default SignaturesView;
