import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Button, ClickAwayListener, IconButton, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DOCUMENT_LINKS = [
  { name: 'Terms and Conditions', path: '/terms-and-conditions' },
  { name: 'Terms of Use', path: '/terms-of-use' },
  { name: 'Privacy Policy', path: '/privacy-policy' },
  { name: 'Data Processing Addendum', path: '/data-processing-addendum' },
  { name: 'Data Privacy Framework Notice', path: '/data-privacy-framework-notice' },
];

const useStyles = makeStyles()((theme: Theme) => ({
  bgPaper: {
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
  backdrop: {
    position: 'fixed',
    display: 'flex',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  sideMenuWrapper: {
    maxWidth: '240px',
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',
    height: '100%',
    padding: '40px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  sideMenuLink: {
    padding: '12px 16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    textDecoration: 'none',
    color: '#C3C4C8',

    '&:hover': {
      background: 'rgba(37, 157, 168, 0.19)',
      color: 'white',
      borderRadius: '12px',
    },
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '32px',
  },
  bottomButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '20px',
    marginTop: 'auto',
  },
  bottomButton: {
    padding: '10px 32px',
    background: theme.palette.info.main,
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    color: theme.palette.common.white,
    boxSizing: 'border-box',
    height: '48px',
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
    width: '100%',
    justifyContent: 'center',
    textTransform: 'none',

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
}));

interface SideMenuProps {
  handleCloseMenu: () => void;
  handleOpenPopup: () => void;
  handleNavigateToSection: (e: React.SyntheticEvent) => void;
  handleCloseSideBar: () => void;
  isLandingPageRoute: boolean;
}

const SideMenu = ({
  handleCloseMenu,
  handleOpenPopup,
  handleNavigateToSection,
  handleCloseSideBar,
  isLandingPageRoute,
}: SideMenuProps) => {
  const styles = useStyles();

  const onRequestDemoBtnClick = () => {
    handleCloseSideBar();
    handleOpenPopup();
  };

  return (
    <Box className={styles.classes.bgPaper}>
      <Box className={styles.classes.backdrop} />

      <ClickAwayListener onClickAway={handleCloseMenu}>
        <Box className={styles.classes.sideMenuWrapper}>
          <IconButton
            sx={{
              maxWidth: 'fit-content',
              color: 'white',
            }}
            onClick={handleCloseMenu}
          >
            <CloseIcon />
          </IconButton>

          {isLandingPageRoute ? (
            <Box
              className={styles.classes.linksContainer}
              onClick={(e: React.SyntheticEvent) => handleNavigateToSection(e)}
            >
              <a className={styles.classes.sideMenuLink} href="#whyv" id="#whyv">
                Why v?
              </a>
              <a className={styles.classes.sideMenuLink} id="#features" href="#features">
                Features
              </a>
              <a className={styles.classes.sideMenuLink} href="#howitworks" id="#howitworks">
                How It Works
              </a>
            </Box>
          ) : (
            <Box
              className={styles.classes.linksContainer}
              onClick={(e: React.SyntheticEvent) => handleNavigateToSection(e)}
            >
              {DOCUMENT_LINKS.map((link) => (
                <Link className={styles.classes.sideMenuLink} key={link.name} to={link.path}>
                  {link.name}
                </Link>
              ))}
            </Box>
          )}

          <Box className={styles.classes.bottomButtonsContainer}>
            <Link className={styles.classes.bottomButton} to="sign-in">
              Sign In
            </Link>

            <Button
              onClick={onRequestDemoBtnClick}
              disableRipple
              className={styles.classes.bottomButton}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default SideMenu;
