import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, IconButton, Link, Typography, useTheme } from '@mui/material';

import { ReactComponent as PdfImage } from './pdf.svg';
import { ReactComponent as DocusignImage } from './docusign.svg';
import { ReactComponent as CsvImage } from './csv.svg';

interface DocumentDownloaderProps {
  title?: string;
  fileName?: 'pdf' | 'docusign' | 'csv';
  url?: string;
  onClick?: () => void;
}

const DocumentDownloader = ({ title, fileName, url, onClick }: DocumentDownloaderProps) => {
  const theme = useTheme();

  if (!url && !onClick) return null;

  const renderImage = () => {
    switch (fileName) {
      case 'pdf':
        return <PdfImage />;
      case 'docusign':
        return <DocusignImage />;
      case 'csv':
        return <CsvImage />;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography
          color={theme.palette.common.white}
          fontSize="20px"
          fontWeight="600"
          lineHeight="24px"
          fontFamily="Inter"
        >
          {title || 'Document File'}
        </Typography>
        <Link href={url} target="_blank" onClick={onClick}>
          <IconButton
            disableRipple
            disabled={!url && !onClick}
            sx={{
              padding: 0,
              paddingBottom: '4px',
              color: theme.palette.common.white,

              '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.12)',
              },
            }}
          >
            <FileDownloadIcon />
          </IconButton>
        </Link>
      </Box>

      <Link
        href={url}
        target="_blank"
        onClick={onClick}
        mt="20px"
        style={{ maxWidth: '90px', cursor: 'pointer' }}
      >
        {renderImage()}
      </Link>
    </>
  );
};

export default DocumentDownloader;
