import { SelectOption } from '../../types';
import { AnyObject } from 'yup';

export interface CaseCreateFormSubmitProps {
  id?: string;
  name?: string;
  referenceId: string;
  caseTypeCategory: string;
  type: SelectOption;
  trustAccount: {
    id?: string;
    label?: string;
  } | null;
  firmId?: string;
  status?: string;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined | null;
  filedAt: AnyObject | undefined | null;
  secondaryFirms: SecondaryFirmsField[] | undefined;
}

export interface CaseEditFormSubmitProps {
  id?: string;
  name?: string;
  referenceId: string;
  caseTypeCategory: string;
  type: SelectOption;
  trustAccount: {
    id?: string;
    label?: string;
  } | null;
  firmId?: string;
  status?: string;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined | null;
  filedAt: AnyObject | undefined | null;
  secondaryFirms: SecondaryFirmsField[] | undefined;
}

export type DefendantField = {
  defendantId: { id: string; label: string };
  gross?: string | undefined;
};

export type SecondaryFirmsField = {
  firmId?: { id?: string; label?: string };
  firmRole?: string | undefined;
};

export interface CaseAttorneyFeesConfigData {
  case: {
    id: number;
    name: string;
  };
  config: {
    maximumAmount: number | null;
    isDraft: boolean;
    variableFeePercent: number;
    secondaryFirms: Array<{
      role: 'CoCounsel' | 'ReferringCounsel';
      id: number;
      name: string;
      config: {
        fixedFeeAmount: number | null;
        variableFeePercent: number | null;
        variableFeeMaximumAmount: number | null;
      };
    }>;
  };
}

export interface CaseAttorneyFeesConfigSubmitProps {
  caseFeesConfig: {
    variableFeePercent?: string;
    maximumAmount?: string;
    variableFeeBasis: string;
  };
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
    config: {
      fixedFeeAmount?: string;
      variableFeePercent?: string;
      variableFeeMaximumAmount?: string;
      variableFeeBasis: string;
    };
  }>;
}

export enum FIRM_ROLES {
  LeadCounsel = 'Lead Counsel',
  CoCounsel = 'Co-counsel',
  ReferringCounsel = 'Referring Counsel',
}
