import { useEffect, useState } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { Box, Theme } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import {
  cancelDataRequest,
  deleteDataRequest,
  sendDataRequest,
  updateDataRequest,
} from '../../../api/requestsApi/dataApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import CommonButton from '../../../components/buttons/CommonButton';
import { dataEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { DATA_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { DataRequestData, EntityFlexibleRecord, ErrorData } from '../../../types';
import DocumentDownloader from '../../../components/DocumentsDownloader/DocumentDownloader';
import PreviewFormPopup from '../../../components/PreviewFormPopup';
import { mapQuestionnaireQueryResult } from '../../../components/QuestionnaireFormBuilder/helpers/mapQuestionnaireQueryResult';
import DataRequestFormBuilderPopup from '../../../components/DataRequestFormBuilderPopup';
import { QuestionnaireSubmitForm } from '../../QuestionnairesView/types';
import { mapQuestionnaireData } from '../../QuestionnairesView/helpers/mapQuestionnaireData';
import Loader from '../../../components/Loader';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { downloadCsv } from '../../../helpers/downloadCsv';
import { DataEditFormSubmitProps } from '../types';
import { FieldHeadline } from '../styled';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    padding: '32px',
    gap: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,

    '@media (max-width: 1024px)': {
      flexBasis: '416px',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '64px',

    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '416px',
    gap: '32px',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  headlineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  downloadWidgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  statisticsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  formButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '416px',
    gap: '16px',

    '@media (max-width: 768px)': {
      alignSelf: 'flex-start',
    },
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '200px',
    gap: '16px',

    '@media (max-width: 800px)': {
      flexBasis: 0,
    },
  },
}));

const DataEditFormContainer = () => {
  const { classes } = useStyles();
  const { dataId } = useParams<string>();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormBuilderPopupOpen, setIsFormBuilderPopupOpen] = useState(false);
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const formMethods = useForm<DataEditFormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataEditFormSchema),
    defaultValues: DATA_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
    setValue,
  } = formMethods;

  const form = watch('form');
  const answers = watch('answers');
  const status = watch('status');
  const caseId = watch('case.id');
  const plaintiffId = watch('plaintiff.id');
  const message = watch('message');

  const isDraft = status === 'Draft';
  const isCompleted = status === 'Completed';
  const isCancelable = entityData?.status === 'Unopened' || entityData?.status === 'Opened';

  const handleRenderCancelDeleteButton = () => {
    if (entityData?.status) {
      if (isDraft) {
        return (
          <CommonButton
            disabled={!isDraft}
            type="error"
            buttonText="delete"
            onButtonClick={() => setIsDeletePopupOpen(true)}
          />
        );
      } else {
        return (
          <CommonButton
            disabled={!isCancelable}
            type="error"
            buttonText="cancel"
            onButtonClick={() => setIsCancelPopupOpen(true)}
          />
        );
      }
    }
  };

  const onSubmitForm = (values: DataEditFormSubmitProps) => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    const data = {
      form: values.form ? mapQuestionnaireData(values.form) : null,
      name: values.name,
      ...(values.message && { message: values.message }),
      ...(values.notes && { notes: values.notes }),
    };

    updateDataRequest(dataId, data)
      .then((response) => {
        if (response.status === 200) {
          navigate('/requests/data-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Data request updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancelChanges = () => reset();

  const handleSendDataRequest = () => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    sendDataRequest(dataId)
      .then((response) => {
        if (response.status === 200) {
          navigate(-1);

          setAlert((prev) => ({
            ...prev,
            message: 'Data request has been sent successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancelDataRequest = () => {
    if (!dataId) return;

    setIsFormSubmitting(true);

    cancelDataRequest(dataId)
      .then((response) => {
        if (response.status === 200) {
          navigate(-1);

          setAlert((prev) => ({
            ...prev,
            message: 'Data request cancelled successfully.',
            type: 'success',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleDeleteDataRequest = () => {
    deleteDataRequest(dataId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Data request deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onSaveForm = (values: QuestionnaireSubmitForm) => {
    setValue('form', values, { shouldDirty: true });
    setIsFormBuilderPopupOpen(false);
  };

  const handleDownloadClick = () => {
    downloadCsv(`/v2/data-requests/${dataId}/answers/csv`, 'GET', `data_request_${dataId}`);
  };

  useEffect(() => {
    if (entityData) {
      const data: DataRequestData = entityData as DataRequestData;
      let mappedForm: QuestionnaireSubmitForm | null = null;

      if (data.form) mappedForm = mapQuestionnaireQueryResult(data.form);

      reset({
        form: mappedForm,
        answers: data.answers,
        status: data.status,
        id: data.id,
        name: data.name,
        case: data.case,
        firm: data.firm,
        plaintiff: {
          ...data.client,
          middleName: data.client.middleName || '',
        },
        plaintiffFullName: `${data.client.firstName} ${
          data.client.middleName ? `${data.client.middleName} ` : ''
        }${data.client.lastName}`,
        notes: data.notes || '',
        message: data.message || '',
        formItemAnswersCount: data.formItemAnswersCount,
        formItemsCount: data.formItemsCount,
      });

      setPageTitle && setPageTitle('');
      setIsLoading(false);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  if (isLoading) {
    return <Loader colorType="warning" />;
  }

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.section}>
              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Identity'}</FieldHeadline>
                <FormCopyTextField entityName="Data request" name="id" label="Id" isReadOnly />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Case'}</FieldHeadline>
                  <FormLinkButton path={`/cases/${caseId}/home`} />
                </Box>
                <FormTextField name="case.referenceId" label="Reference Id" disabled />
                <FormTextField name="case.type.name" label="Type" disabled />
                <FormTextField name="case.name" label="System Name" disabled />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Lead Counsel'}</FieldHeadline>
                </Box>
                <FormTextField name="firm.name" label="Firm" disabled />
              </Box>

              <Box className={classes.infoBlock}>
                <Box className={classes.headlineWrapper}>
                  <FieldHeadline>{'Client'}</FieldHeadline>
                  <FormLinkButton path={`/clients/${plaintiffId}/home`} />
                </Box>
                <FormTextField name="plaintiffFullName" label="Client" disabled />
              </Box>
            </Box>

            <Box className={classes.section}>
              {isCompleted && dataId && (
                <Box className={classes.downloadWidgetWrapper}>
                  <DocumentDownloader
                    title="Data File"
                    fileName={'csv'}
                    onClick={handleDownloadClick}
                  />
                </Box>
              )}

              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Info'}</FieldHeadline>
                <FormTextField name={'name'} label="Name *" disabled={!isDraft} />
                <FormTextField name={'notes'} label="Notes" disabled={!isDraft} isMultiline />
                <FormTextField name={'message'} label="Message" disabled={!isDraft} isMultiline />
              </Box>

              <Box className={classes.infoBlock}>
                <FieldHeadline>{'Statistics'}</FieldHeadline>
                <Box className={classes.statisticsContainer}>
                  <FormTextField name="formItemAnswersCount" label="Answered Questions" disabled />
                  <FormTextField name="formItemsCount" label="Total Questions" disabled />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.formButtonsWrapper}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || !isDraft || isCompleted || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              fontFamily="Inter"
              onClick={handleSubmit(onSubmitForm)}
            />
            <FormCancelButton onClick={handleCancelChanges} disabled={!isDirty} />
          </Box>
        </form>
      </FormProvider>

      <Box className={classes.actionsWrapper}>
        <FieldHeadline>{'Actions'}</FieldHeadline>
        <CommonButton
          disabled={isDirty || !isDraft || !isValid || !form}
          type="warning"
          buttonText="Send Data Request"
          onButtonClick={handleSendDataRequest}
        />
        {handleRenderCancelDeleteButton()}
        <CommonButton buttonText="Archive" disabled type="error" />
        <CommonButton
          disabled={!isDraft}
          type="success"
          buttonText="Edit Form"
          onButtonClick={() => setIsFormBuilderPopupOpen(true)}
        />
        <CommonButton
          type="success"
          buttonText={isDraft ? 'Preview Form' : 'Preview Answers'}
          onButtonClick={() => setIsPreviewPopupOpen(true)}
          disabled={!form}
        />
      </Box>

      <DataRequestFormBuilderPopup
        isOpen={isFormBuilderPopupOpen}
        data={form}
        onMainButtonClick={onSaveForm}
        onSecondaryButtonClick={() => setIsFormBuilderPopupOpen(false)}
      />
      <PreviewFormPopup
        isOpen={isPreviewPopupOpen}
        title={isDraft ? 'Preview Form' : 'Preview Answers'}
        data={JSON.stringify({
          form: form ? mapQuestionnaireData(form) : null,
          answers,
          message,
          readonly: true,
        })}
        onSecondaryButtonClick={() => setIsPreviewPopupOpen(false)}
      />
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.dataRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.dataRequestCancel.contentText}
        onMainButtonClick={handleCancelDataRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.dataRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.dataRequestCancel.contentText}
        onMainButtonClick={handleCancelDataRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
      <Popup
        isOpen={isDeletePopupOpen}
        headlineText={POPUP_DESCRIPTIONS.dataRequestDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.dataRequestDelete.contentText}
        onMainButtonClick={handleDeleteDataRequest}
        onSecondaryButtonClick={() => setIsDeletePopupOpen(false)}
      />
    </Box>
  );
};

export default DataEditFormContainer;
