import { ExpectedAnswer } from '../../../constants/constants';
import { QuestionnaireItem, QuestionnaireItemFormat } from '../../../types';
import { FormSnippetSubmitData } from '../types';

export const mapSnippetData = (data: FormSnippetSubmitData) => {
  const mappedData: { items: Array<QuestionnaireItem> } = {
    items: data.items.map((item) => {
      return {
        id: item.id,
        code: item.code,
        format: item.format,
        systemName: item.systemName,
        questionText: item.questionText,
        immutable: item.immutable,
        dependsOnItem: item?.dependsOnItem?.booleanItemId
          ? {
              booleanItemId: item.dependsOnItem.booleanItemId,
              expectedValue: item.dependsOnItem.expectedValue === ExpectedAnswer.Yes,
            }
          : null,
        ...(item.format === QuestionnaireItemFormat.DateRange && {
          allowPartial: item.allowPartial,
        }),
        ...(item.format === QuestionnaireItemFormat.Text && {
          placeholder: item.placeholder || null,
        }),
      };
    }),
  };

  return mappedData;
};
