import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as WelcomeHeroTwoIcon } from '../assets/welcome-hero-2.svg';

import Header from '../Header';
import Footer from '../Footer';

import useWindowWidth from '../../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  pageMainContainer: {
    height: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19) 3.19%, rgba(37, 157, 168, 0.00) 37.39%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#283645',
    fontFamily: 'Inter',
  },
  footerBackground: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',

    '@media (max-width: 768px)': {
      paddingTop: '96px',
    },
  },
  pageHeadlineBlock: {
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    marginBottom: '66px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    padding: '0 20px',

    '@media (max-width: 768px)': {
      margin: '48px 0',
    },
  },
  pageHeadlineText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: '72px',
    color: '#283645',
    textAlign: 'center',
    maxWidth: '500px',

    '@media (max-width: 900px)': {
      maxWidth: 'none',
    },

    '@media (max-width: 768px)': {
      fontSize: '36px',
      lineHeight: '120%',
    },
  },
  policyPageContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1200px',
    gap: '40px',
    margin: '0 auto',
    padding: '0 20px',
    paddingBottom: '40px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#5D6068',

    a: {
      color: '#00667C',
      textDecoration: 'none',
    },

    strong: {
      fontWeight: 600,
      color: '#283645',
    },

    h3: {
      fontWeight: 600,
      color: '#283645',
    },
  },
}));

const DataPrivacyFrameworkNoticeView = () => {
  const styles = useStyles();
  const windowWidth = useWindowWidth();

  const [isRequestDemoPopupOpen, setIsRequestDemoPopupOpen] = useState<boolean>(false);

  const handleClosePopup = () => setIsRequestDemoPopupOpen(false);
  const handleOpenPopup = () => setIsRequestDemoPopupOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  return (
    <Box className={styles.classes.pageMainContainer}>
      <Header
        windowWidth={windowWidth}
        handleOpenPopup={handleOpenPopup}
        isRequestDemoPopupOpen={isRequestDemoPopupOpen}
        handleClosePopup={handleClosePopup}
      />

      <Box className={styles.classes.pageHeadlineBlock}>
        {windowWidth > 900 && <WelcomeHeroTwoIcon />}
        <Typography className={styles.classes.pageHeadlineText}>
          Data Privacy Framework Notice
        </Typography>
      </Box>

      <Box className={styles.classes.policyPageContentWrapper}>
        <main>
          <section>
            <p>
              v.law, LLC (<strong>“V”</strong>, the <strong>“Company”</strong>,<strong>“we”</strong>
              , or
              <strong>“us”</strong>) is committed to safeguarding the private information entrusted
              to it by its customers. V complies with the EU-U.S. Data Privacy Framework (EU-U.S.
              DPF), the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework
              (Swiss-U.S. DPF) (collectively, the
              <strong>“DPF”</strong>) as set forth by the U.S. Department of Commerce. V has
              certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data
              Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing of
              personal data received from the European Union in reliance on the EU-U.S. DPF and from
              the United Kingdom (and Gibraltar) in reliance on the UK Extension to the EU-U.S. DPF.
              V has certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S.
              Data Privacy Framework Principles (Swiss-U.S. DPF Principles) with regard to the
              processing of personal data received from Switzerland in reliance on the Swiss-U.S.
              DPF. If there is any conflict between the terms in this Data Privacy Framework Notice
              (this <strong>“Notice”</strong>) and the EU-U.S. DPF Principles and/or the Swiss-U.S.
              DPF Principles (collectively, the <strong>“DPF Principles”</strong>), the DPF
              Principles shall govern. To learn more about the Data Privacy Framework (DPF) program,
              and to view our certification, please visit{' '}
              <a href="https://www.dataprivacyframework.gov/">
                https://www.dataprivacyframework.gov/
              </a>
              .
            </p>
            <p>
              V is responsible for the processing of personal data it receives under the DPF and
              subsequently transfers to a third party acting as an agent on its behalf. V complies
              with the DPF Principles for all onward transfers of personal data from the EU, UK, and
              Switzerland, including the onward transfer liability provisions.
            </p>
            <p>
              This Notice extends to V’s secure collection, use and storage of Personal Data
              transferred from European Union (<strong>“EU”</strong>) member countries, the United
              Kingdom, and Switzerland to the United States (<strong>“Personal Data”</strong>) and
              supplements the terms set forth in our Privacy Policy (located at{' '}
              <a href="/privacy-policy">v.law/privacy-policy</a>). Unless specifically defined in
              this Notice, the terms in this Notice have the same meaning as our Privacy Policy with
              respect to such Personal Data.
            </p>
            <h3>Information We Receive From Our Customers</h3>
            <p>
              We collect and store Personal Data provided to us by individuals, law firms, business
              organizations, and government entities (each, a <strong>“Customer”</strong>, and
              collectively, our <strong>“Customers”</strong>) who sign agreements with us to use our
              software. Personal Data we receive from Customers are names, addresses, email
              addresses, email content, file-shares and other Personal Data identifiable to an
              individual. Any Personal Data received from our Customers is used strictly for the
              business purposes defined in the software agreement between a Customer and V. It is
              not shared with third parties except in compliance with the provisions of the software
              agreement between a Customer and V, and in such a case, such Personal Data is shared
              only with third parties who are authorized to receive it under the provisions of the
              software agreement, which may include without limitation, parties or counsel involved
              in litigation or other forms of dispute resolution, V affiliates or service providers,
              and V subprocessors as defined in V’s Data Processing Addendum. Such sharing occurs
              solely to permit V to exercise its rights and satisfy its obligations pursuant to the
              software agreement between a Customer and V. We also collect and store names,
              addresses, email addresses, and email content received from Customer representatives
              using our software and accessing our support services.
            </p>
            <p>
              You have rights with respect to the Personal Data we receive from our Customers
              including the right to access, delete, amend, and correct your Personal Data. If you
              wish to access, correct, amend, or delete your Personal Data provided by a Customer to
              V, you may contact us as described below. Similarly, if you wish to limit the use or
              sharing of your Personal Data, you should contact us as described below.
            </p>
            <h3>Information About Visitors to Our Website</h3>
            <p>
              Visitors to our website v.law will occasionally provide us with Personal Data on a
              completely voluntary basis. V will only process and store this data in ways that are
              compatible with the purpose for which the visitor gave it to V, or for which V stored
              it, or for purposes the visitor later authorizes. Before we use this data for a
              purpose that is materially different than the purpose for which we received it or for
              which it was later authorized, we will provide the visitor with the opportunity to opt
              out. V maintains reasonable procedures under the circumstances to help ensure that
              data collected from visitors is reliable for its intended use, accurate, complete, and
              current.
            </p>
            <p>
              We also collect and store anonymous general information about the use of our websites
              and software, such as which software features our Customers use most frequently, and
              which services our Customers access the most. We use only aggregated data for this
              purpose, which de-identifies any individual Customer or visitor to our site. This
              information helps us determine what is most beneficial for our users and how we can
              continually create a better overall software and services experience. We may use this
              general and aggregated anonymous information and share it with our business partners
              so that they too may understand how our site is used.
            </p>
            <h3>Sensitive Personal Data</h3>
            <p>
              Our Customers may, without our knowledge, provide us data that includes Personal Data
              relating to racial or ethnic origin, political opinions, religious or philosophical
              beliefs, trade-union membership, genetic information, or health or sex-life
              information (collectively <strong>“Sensitive Personal Data”</strong>), solely for the
              purpose of using V’s software and services. V does not otherwise collect or store
              Sensitive Personal Data as a matter of course in its operations and does not ask our
              Customers or any visitors to supply it. Any Sensitive Personal Data received from
              Customers is used strictly for the business purposes defined in the software agreement
              between a Customer and V. It is not shared with third parties except in compliance
              with the provisions of the software agreement between a Customer and V, and in such a
              case, such Personal Data is shared only with third parties who are authorized to
              receive it under the provisions of the software agreement, which may include without
              limitation, parties or counsel involved in litigation or other forms of dispute
              resolution, V affiliates or service providers, and V subprocessors as defined in V’s
              Data Processing Addendum. Such sharing occurs solely to permit V to exercise its
              rights and satisfy its obligations pursuant to the software agreement between a
              Customer and V.
            </p>
            <p>
              You have the rights with respect to the Sensitive Personal Data we receive from our
              Customers including the right to access, delete, amend, and correct your Sensitive
              Personal Data. If you wish to access, correct, amend, or delete your Sensitive
              Personal Data provided by a Customer to V, you may contact us as described below.
              Similarly, if you wish to limit the use or sharing of your Sensitive Personal Data,
              you should contact us as described below. Any requests to obtain your opt-in consent
              where the DPF requires, including disclosure of your Sensitive Personal Data to third
              parties, or before your Sensitive Personal Data is used for a different purpose than
              for which it was provided to V by a Customer or for which you later authorized, should
              be directed to us as described below.
            </p>
            <h3>Compliance With Law Enforcement</h3>
            <p>
              In certain situations, V may be required to disclose EU, UK, or Swiss Personal Data in
              response to a lawful request by public authorities, including to meet national
              security or law enforcement requirements.
            </p>
            <h3>Enforcement and Dispute Resolution</h3>
            <p style={{ wordWrap: 'break-word' }}>
              For purposes of enforcing compliance with the DPF, V is subject to the investigatory
              and enforcement authority of the US Federal Trade Commission. For more information
              about the DPF, see the US Department of Commerce’s Data Privacy Framework website
              located at:{' '}
              <a href="https://www.dataprivacyframework.gov/">
                https://www.dataprivacyframework.gov/
              </a>
              . To review our representation on the Data Privacy Framework list, see the US
              Department of Commerce’s DPF self-certification list located at{' '}
              <a href="https://www.dataprivacyframework.gov/s/participant-search">
                https://www.dataprivacyframework.gov/s/participant-search
              </a>
              .
            </p>
            <p>
              In compliance with the DPF, V commits to resolve DPF Principles-related complaints
              about our collection and use of your personal information. EU, UK, and Swiss
              individuals with inquiries or complaints regarding our handling of personal data
              received in reliance on DPF should first contact V at:{' '}
            </p>
            <p>
              <a href="mailto:privacy@v.law">privacy@v.law</a>
            </p>
            <p>OR</p>
            <p>
              v.law, LLC
              <br />
              Attn: General Counsel
              <br />
              7950 Jones Branch Drive
              <br />
              8th Floor North
              <br />
              McLean, Virginia 21012
            </p>
            <p>
              V will respond to individuals within forty-five (45) days of an inquiry or complaint.
              If an individual has an unresolved complaint or concern that is not addressed
              satisfactorily, that individual may contact our U.S. based third-party dispute
              resolution provider: JAMS Dispute Resolution Services. If you do not receive timely
              acknowledgment of your complaint from us, or if we have not addressed your complaint
              to your satisfaction, please contact or visit{' '}
              <a href="https://www.jamsadr.com/eu-us-data-privacy-framework">
                https://www.jamsadr.com/eu-us-data-privacy-framework
              </a>
              for more information or to file a complaint. The services of JAMS are provided at no
              cost to you.
            </p>
            <p>
              If the dispute involves human resources personal information or information collected
              in the context of an employment relationship, V commits to cooperate and comply
              respectively with the advice of the panel established by the EU data protection
              authorities (DPAs), UK Information Commissioner’s Office (ICO) and the Gibraltar
              Regulatory Authority (GRA), and the Swiss Federal Data Protection and Information
              Commissioner (FDPIC). For information on how to contact your EU jurisdiction’s DPA,
              visit:
              <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
                https://edpb.europa.eu/about-edpb/about-edpb/members_en
              </a>
              . For information on how to contact your UK Information Commissioner, visit:{' '}
              <a href="https://ico.org.uk/">https://ico.org.uk/</a>. For information on how to
              contact your Swiss jurisdiction’s Commissioner, visit:{' '}
              <a href="https://www.edoeb.admin.ch/index.html?lang=en">
                https://www.edoeb.admin.ch/index.html?lang=en
              </a>
              .
            </p>
            <p>
              Under certain circumstances, you may have the option to select binding arbitration for
              the resolution of your complaint, provided you have already taken the following steps
              (as applicable): (1) raised your complaint directly with our Customer and provided
              them with the opportunity to resolve the issue; (2) raised your complaint directly
              with V and provided us with the opportunity to resolve the issue; (3) made use of the
              independent dispute resolution mechanism identified above; and (4) raised the issue
              through the relevant data protection authority and allowed the US Department of
              Commerce an opportunity to resolve the complaint at no cost to you.
            </p>
            <h3>Data Transfers to Third Parties</h3>
            <p>
              V may transfer Personal Data to our third-party agents or service providers who
              perform functions on our behalf as described in our Website Privacy Policy. Where
              required by the DPF, we enter into written agreements with those third-party agents
              and service providers requiring them to provide the same level of protection the DPF
              requires and limiting their use of the data to the specified services provided on our
              behalf. We take reasonable and appropriate steps to ensure that third-party agents and
              service providers process Personal Data in accordance with our DPF obligations and to
              stop and remediate any unauthorized processing. Under the DPF, we are responsible for
              the processing of information about you we receive from the EU, UK, and Switzerland
              and onward transfers to a third party acting as an agent on our behalf. We comply with
              the DPF Principles for such onward transfers and remain liable in accordance with the
              DPF Principles if third-party agents that we engage to process such information about
              you on our behalf do so in a manner inconsistent with the DPF Principles unless we
              prove that we are not responsible for the event giving rise to the damage.
            </p>
            <h3>Security</h3>
            <p>
              V maintains reasonable and appropriate security measures to protect Personal Data from
              loss, misuse, unauthorized access, disclosure, alteration, or destruction in
              accordance with the DPF and the DPF Principles.
            </p>
            <h3>Your Acceptance of These Terms</h3>
            <p>
              By using our website, you signify your acceptance of this Notice. If you do not agree
              to this Notice, please do not use our website. Your continued use of our website
              following the posting of changes to this Notice will be deemed your acceptance of
              those changes.
            </p>
            <h3>Changes to This Notice </h3>
            <p>
              We reserve the right to amend this Notice from time to time consistent with the DPF
              requirements.
            </p>
          </section>
        </main>
      </Box>

      <Box className={styles.classes.footerBackground}>
        <Footer />
      </Box>
    </Box>
  );
};

export default DataPrivacyFrameworkNoticeView;
