/* eslint-disable @typescript-eslint/no-explicit-any */
export const getTextFieldErrorMessage = (obj: any, key: string): string => {
  if (key in obj) {
    return obj[key];
  }

  const keys = key.split('.');

  let value = obj;

  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]];

    if (value === undefined) {
      break;
    }
  }

  return value;
};
