import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme, Typography } from '@mui/material';

import FormTextField from '../../../components/formFields/FormTextField';
import { requestDemoSchema } from '../../../helpers/formSchemas/formSchemas';
import { REQUEST_DEMO_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import FormCheckboxField from '../../../components/formFields/FormCheckboxField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import { requestDemoMail } from '../../../api/requestDemoApi';
import useSnackBar from '../../../hooks/useSnackBar';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()((theme: Theme) => ({
  checkboxWrapper: {
    display: 'flex',
    margin: '32px 0',
    alignItems: 'center',

    '& label': {
      marginRight: 0,
    },
  },
  checkboxLabel: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    color: theme.palette.text.secondary,
  },
  checkboxLink: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
}));

interface RequestDemoFormSubmitProps {
  userName: string;
  email: string;
  message: string;
  isAgreed: boolean;
}

interface RequestDemoFormProps {
  onClose: () => void;
  setIsSuccessPopupOpen: Dispatch<SetStateAction<boolean>>;
}

const RequestDemoForm = ({ onClose, setIsSuccessPopupOpen }: RequestDemoFormProps) => {
  const styles = useStyles();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(requestDemoSchema),
    defaultValues: REQUEST_DEMO_FORM_DEFAULT_VALUES,
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSuccessSubmit = () => {
    onClose();
    reset(REQUEST_DEMO_FORM_DEFAULT_VALUES);
    setIsSuccessPopupOpen(true);
    setIsFormSubmitting(false);

    setTimeout(() => {
      setIsSuccessPopupOpen(false);
    }, 1500);
  };

  const onSubmit = (values: RequestDemoFormSubmitProps) => {
    const submitData = {
      name: values.userName,
      email: values.email,
      message: values.message,
    };

    setIsFormSubmitting(true);

    requestDemoMail(submitData)
      .then((res) => {
        res.status === 204 && onSuccessSubmit();
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <Box display="flex" flexDirection="column" gap="24px">
          <FormTextField name="userName" label="Name" />
          <FormTextField name="email" label="Email" />
          <FormTextField name="message" label="Your Message" isMultiline />
        </Box>

        <Box className={styles.classes.checkboxWrapper}>
          <FormCheckboxField name="isAgreed" />
          <Box display="flex" gap="4px">
            <Typography className={styles.classes.checkboxLabel}>
              By submitting this form, you allow v. to use your information as described in our{' '}
              <Link to="/privacy-policy" className={styles.classes.checkboxLink} target="_blank">
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Box>

        <FormSubmitButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isFormSubmitting}
          buttonText="Submit"
          fontFamily="Inter"
        />
      </FormProvider>
    </>
  );
};

export default RequestDemoForm;
