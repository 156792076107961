import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FirmTrustAccountForm from './FirmTrustAccountForm';

import { FirmTrustAccountData, FirmTrustAccountSubmitFormProps } from '../types';

import { createFirmTrustAccount } from '../../../api/firmsApi/trustAccountsApi';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import { firmTrustAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      maxWidth: '800px',
      width: '100%',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '34px',
      fontWeight: 600,
      lineHeight: '120%',
      color: theme.palette.common.white,
      fontFamily: 'Poppins',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    margin: '0 auto',
    gap: '16px',
    padding: 0,
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

interface FirmTrustAccountPopupProps {
  isOpen: boolean;
  headlineText: string;
  handleEditAccount: (data: FirmTrustAccountSubmitFormProps) => void;
  selectedTableItem: FirmTrustAccountData | null;
  handleResetFormState: () => void;
  handleClosePopup: () => void;
  fetchTrustAccounts: () => void;
}

const FirmTrustAccountPopup = ({
  isOpen,
  headlineText,
  handleEditAccount,
  selectedTableItem,
  handleResetFormState,
  handleClosePopup,
  fetchTrustAccounts,
}: FirmTrustAccountPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const firmId = localStorage.getItem('firmId');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmTrustAccountFormSchema),
    defaultValues: FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = formMethods;

  const wasUsedForPayment = watch('wasUsedForPayment');

  const handleAddNewAccount = (accountFormData: FirmTrustAccountSubmitFormProps) => {
    if (!firmId) return;

    setIsFormSubmitting(true);

    const submitData = {
      accountNumber: accountFormData.accountNumber,
      routingNumber: accountFormData.routingNumber,
      name: accountFormData.name,
      isVerified: accountFormData.isVerified,
    };

    createFirmTrustAccount(Number(firmId), submitData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Trust account created successfully.',
            type: 'success',
          }));

          handleClosePopup();
          fetchTrustAccounts();
          reset(FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES);
          setIsFormSubmitting(false);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: FirmTrustAccountSubmitFormProps) => {
    if (wasUsedForPayment) {
      setAlert((prev) => ({
        ...prev,
        message:
          // eslint-disable-next-line quotes
          `You cannot edit this trust account since it's connected to the cases for which settlement process has been started.`,
        type: 'info',
      }));
      return;
    }

    selectedTableItem ? handleEditAccount(values) : handleAddNewAccount(values);
  };

  const onPopupClose = () => {
    handleResetFormState();
    reset(FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES);
  };

  useEffect(() => {
    selectedTableItem
      ? reset({
          accountNumber: selectedTableItem.accountNumber,
          routingNumber: selectedTableItem.routingNumber,
          name: selectedTableItem.name,
          isVerified: selectedTableItem.isVerified,
          wasUsedForPayment: selectedTableItem.wasUsedForPayment,
        })
      : reset({
          ...FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES,
        });
  }, [selectedTableItem]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          onClick={onPopupClose}
          disableRipple
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>{headlineText}</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <FirmTrustAccountForm />
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.popupActions}>
        <FormSubmitButton
          disabled={!isValid || !isDirty || isFormSubmitting}
          buttonText="Submit"
          onClick={handleSubmit(onSubmitForm)}
        />

        <FormCancelButton onClick={onPopupClose} />
      </DialogActions>
    </Dialog>
  );
};

export default FirmTrustAccountPopup;
