import { useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      height: '90%',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    textAlign: 'center',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  button: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
}));

interface PreviewFormPopupProps {
  isOpen: boolean;
  title: string;
  data: string;
  onSecondaryButtonClick: () => void;
}

const PreviewFormPopup = ({
  isOpen,
  title,
  data,
  onSecondaryButtonClick,
}: PreviewFormPopupProps) => {
  const { classes } = useStyles();
  const iRef = useRef<HTMLIFrameElement | null>(null);

  const handleLoad = () => {
    setTimeout(() => {
      if (iRef && iRef?.current && iRef?.current?.contentWindow)
        iRef.current.contentWindow.postMessage(data, process.env.REACT_APP_FORMS_APP_URL as string);
    }, 1000);
  };

  return (
    <Dialog
      className={classes.popupContainer}
      open={isOpen}
      onClose={onSecondaryButtonClick}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <iframe
          ref={iRef}
          src={process.env.REACT_APP_FORMS_APP_URL}
          width={'100%'}
          height={'100%'}
          onLoad={handleLoad}
          style={{
            border: 'none',
            borderRadius: '16px',
          }}
        ></iframe>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.button} onClick={onSecondaryButtonClick}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewFormPopup;
