import { IconButton as MUIIconButton, Theme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import TooltipComponent from '../TooltipComponent';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    width: '40px',
    height: '40px',
    borderRadius: '12px',

    '&:disabled': {
      opacity: 0.3,
    },
  },
  button: {
    background: '#259DA830',
    color: theme.palette.common.white,

    '&:disabled': {
      background: '#259DA830',
      color: theme.palette.common.white,
    },

    '&:hover': {
      background: '#259DA830',
    },
  },
  deleteButton: {
    background: 'transparent',
    color: theme.palette.error.main,

    '&:disabled': {
      background: 'transparent',
      color: theme.palette.error.main,
    },

    '&:hover': {
      background: 'transparent',
    },
  },
}));

type ButtonType = 'snippets' | 'move-up' | 'move-down' | 'delete';

interface IconButtonProps {
  readonly buttonTitle?: string;
  readonly buttonType: ButtonType;
  readonly disabled?: boolean | undefined;
  readonly onClick?: () => void;
}

const IconButton = ({ buttonTitle, buttonType, disabled, onClick }: IconButtonProps) => {
  const { classes } = useStyles();

  const getButtonIcon = () => {
    switch (buttonType) {
      case 'snippets':
        return <TextSnippetIcon />;
      case 'move-up':
        return <KeyboardArrowUpIcon />;
      case 'move-down':
        return <KeyboardArrowDownIcon />;
      case 'delete':
        return <DeleteForeverIcon />;
      default:
        return null;
    }
  };

  return (
    <TooltipComponent title={buttonTitle} placement="bottom">
      <span>
        <MUIIconButton
          className={clsx({
            [classes.default]: true,
            [classes.button]: buttonType !== 'delete',
            [classes.deleteButton]: buttonType === 'delete',
          })}
          disabled={disabled}
          onClick={onClick}
        >
          {getButtonIcon()}
        </MUIIconButton>
      </span>
    </TooltipComponent>
  );
};

export default IconButton;
