import { Fragment, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { AxiosError } from 'axios';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import AddButton from '../buttons/AddButton';
import FormTextField from '../formFields/FormTextField';
import {
  ErrorData,
  FormSnippetShortData,
  QuestionnaireItemFormat,
  SelectOption,
} from '../../types';
import { QuestionnaireSubmitForm } from '../../views/QuestionnairesView/types';
import SearchFormSnippetPopup from '../SearchFormSnippetPopup';
import { getFormSnippet } from '../../api/questionnairesApi/formSnippetsApi';
import useSnackBar from '../../hooks/useSnackBar';
import { mapFormSnippetQueryResult } from '../../views/FormSnippetsView/helpers/mapFormSnippetQueryResult';
import { extractBooleanItems } from '../../views/FormSnippetsView/helpers/extractBooleanItems';
import useWindowWidth from '../../hooks/useWindowWidth';
import Loader from '../Loader';

import IconButton from './IconButton';
import Item from './Item';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',

    '@media (max-width: 1024px)': {
      gap: '8px',
    },
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '8px',

    '@media (max-width: 1024px)': {
      gap: '20px',
    },
  },
  divider: {
    background: theme.palette.secondary.dark,
  },
}));

interface SectionProps {
  readonly booleanItems: SelectOption[];
  readonly numberOfSections: number;
  readonly sectionIdx: number;
  readonly handleUpdateBooleanItems: (items: SelectOption[]) => void;
  readonly onDeleteClick: (index?: number | number[] | undefined) => void;
  readonly onMoveClick: (indexA: number, indexB: number) => void;
  readonly openWarningPopup: () => void;
}

const Section = ({
  booleanItems,
  numberOfSections,
  sectionIdx,
  handleUpdateBooleanItems,
  onDeleteClick,
  onMoveClick,
  openWarningPopup,
}: SectionProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const firmId = localStorage.getItem('firmId');

  const windowWidth = useWindowWidth();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

  const { control, clearErrors, getValues, setValue } = useFormContext<{
    form: QuestionnaireSubmitForm;
  }>();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: `form.sections.${sectionIdx}.items`,
  });

  const handleAddNewItemButtonClick = () => {
    if (fields.length === 0) clearErrors(`form.sections.${sectionIdx}.items`);

    append({
      id: uuidv4(),
      code: '',
      systemName: '',
      questionText: '',
      immutable: false,
      format: QuestionnaireItemFormat.Text,
      dependsOnItem: {
        booleanItemId: '',
        expectedValue: '',
      },
      placeholder: '',
    });
  };

  const moveItem = (indexA: number, indexB: number) => {
    swap(indexA, indexB);
  };

  const removeItem = (index?: number | number[] | undefined) => {
    remove(index);
  };

  const handleMoveUpButtonClick = () => {
    onMoveClick(sectionIdx, sectionIdx - 1);
  };

  const handleMoveDownButtonClick = () => {
    onMoveClick(sectionIdx, sectionIdx + 1);
  };

  // TODO: Can we make it simpler?
  const handleDeleteButtonClick = () => {
    const { form } = getValues();

    const removedBooleanItems = form.sections[sectionIdx].items.filter(
      (el) => el.format === QuestionnaireItemFormat.Boolean,
    );

    if (removedBooleanItems.length) {
      for (let i = 0; i < form.sections.length; i++) {
        if (i === sectionIdx) continue;

        for (let j = 0; j < form.sections[i].items.length; j++) {
          if (
            removedBooleanItems.some(
              (el) => el.id === form.sections[i].items[j].dependsOnItem.booleanItemId,
            )
          ) {
            setValue(`form.sections.${i}.items.${j}.dependsOnItem.booleanItemId`, '', {
              shouldDirty: true,
            });

            setValue(`form.sections.${i}.items.${j}.dependsOnItem.expectedValue`, '', {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        }
      }

      handleUpdateBooleanItems([
        ...booleanItems.filter(
          (item) => !removedBooleanItems.some((secondItem) => item.id === secondItem.id),
        ),
      ]);
    }

    onDeleteClick(sectionIdx);
  };

  const handleAddSnippet = (snippet: FormSnippetShortData) => {
    setIsSearchPopupOpen(false);
    setIsLoading(true);

    getFormSnippet(snippet.id)
      .then((response) => {
        if (response.data) {
          const mappedData = mapFormSnippetQueryResult(response.data.data);

          mappedData.items.forEach((el) => append(el));

          if (mappedData.items.some((el) => el.format === QuestionnaireItemFormat.Boolean)) {
            handleUpdateBooleanItems([...booleanItems, ...extractBooleanItems(mappedData)]);
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.sectionHeader}>
        <FormTextField
          name={`form.sections.${sectionIdx}.title`}
          label="Section Title *"
          maxWidth="480px"
        />
        <IconButton buttonTitle="Delete" buttonType="delete" onClick={handleDeleteButtonClick} />
        <IconButton
          buttonTitle="Move down"
          buttonType="move-down"
          disabled={sectionIdx === numberOfSections - 1}
          onClick={handleMoveDownButtonClick}
        />
        <IconButton
          buttonTitle="Move up"
          buttonType="move-up"
          disabled={sectionIdx === 0}
          onClick={handleMoveUpButtonClick}
        />
        <IconButton
          buttonTitle="Snippets"
          buttonType="snippets"
          onClick={() => setIsSearchPopupOpen(true)}
        />
      </Box>

      <Box className={classes.itemsWrapper}>
        <Droppable droppableId={`section-${sectionIdx}`}>
          {(provider) => {
            return (
              <Box
                className={classes.itemsWrapper}
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {fields.map((item, itemIdx) => {
                  if (windowWidth > 1024) {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={`item-box-${sectionIdx}-${itemIdx}`}
                        index={itemIdx}
                      >
                        {(provider) => {
                          return (
                            <Box
                              ref={provider.innerRef}
                              {...provider.dragHandleProps}
                              {...provider.draggableProps}
                            >
                              <Item
                                booleanItems={booleanItems}
                                itemIdx={itemIdx}
                                numberOfItems={fields.length}
                                sectionIdx={sectionIdx}
                                handleUpdateBooleanItems={handleUpdateBooleanItems}
                                onDeleteClick={removeItem}
                                onMove={moveItem}
                                openWarningPopup={openWarningPopup}
                              />
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  } else {
                    return (
                      <Fragment key={item.id}>
                        <Item
                          booleanItems={booleanItems}
                          itemIdx={itemIdx}
                          numberOfItems={fields.length}
                          sectionIdx={sectionIdx}
                          handleUpdateBooleanItems={handleUpdateBooleanItems}
                          onDeleteClick={removeItem}
                          onMove={moveItem}
                          openWarningPopup={openWarningPopup}
                        />
                        <Divider className={classes.divider} />
                      </Fragment>
                    );
                  }
                })}
                {provider.placeholder}
              </Box>
            );
          }}
        </Droppable>
        {isLoading && <Loader colorType="warning" />}
        <AddButton
          buttonText="Add New Item"
          handleClick={handleAddNewItemButtonClick}
          disabled={isLoading}
        />
      </Box>

      <Divider className={classes.divider} />

      <SearchFormSnippetPopup
        firmId={firmId}
        isOpen={isSearchPopupOpen}
        onMainButtonClick={handleAddSnippet}
        onSecondaryButtonClick={() => setIsSearchPopupOpen(false)}
      />
    </Box>
  );
};

export default Section;
