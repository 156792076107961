import { QuestionnaireItemFormat, SelectOption } from '../../../types';
import { QuestionnaireSubmitForm } from '../../../views/QuestionnairesView/types';

export const extractBooleanItems = (form: QuestionnaireSubmitForm) => {
  const booleanItems: SelectOption[] = [{ id: '', label: '-' }];

  for (const section of form.sections) {
    for (const item of section.items) {
      if (item.format === QuestionnaireItemFormat.Boolean)
        booleanItems.push({ id: item.id, label: item.systemName });
    }
  }

  return booleanItems;
};
