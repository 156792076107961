import { Fragment, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { CaseTypeCategories } from '../../constants/constants';
import theme from '../../theme';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonGroup: {
    width: 'auto',
    maxWidth: '100%',
    borderRadius: '12px',
  },
  default: {
    display: 'flex',
    height: '48px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.08)',
    color: theme.palette.text.disabled,

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },

    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.08)',
      color: theme.palette.text.disabled,
    },
  },
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
  },
}));

enum Options {
  DefaultTrustAccount = 'Default Trust Account',
  Delete = 'Delete',
}

interface CaseTypeButtonProps {
  readonly buttonText: string;
  readonly category: string;
  readonly onPress?: () => void;
  readonly onDelete?: () => void;
  readonly onDefaultTrustAccountClick?: () => void;
}

const CaseTypeButton = ({
  buttonText,
  category,
  onPress,
  onDelete,
  onDefaultTrustAccountClick,
}: CaseTypeButtonProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    onPress?.();
  };

  const handleMenuItemClick = (option: Options) => {
    switch (option) {
      case Options.DefaultTrustAccount:
        onDefaultTrustAccountClick?.();
        break;
      case Options.Delete:
        onDelete?.();
        break;
      default:
        break;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const getMenuOptions = () => {
    if (category === CaseTypeCategories.Custom)
      return [Options.DefaultTrustAccount, Options.Delete];
    return [Options.DefaultTrustAccount];
  };

  return (
    <Fragment>
      <ButtonGroup
        className={classes.buttonGroup}
        disableElevation
        ref={anchorRef}
        variant="contained"
      >
        <Button
          className={clsx(classes.default)}
          disabled={category !== CaseTypeCategories.Custom}
          onClick={handleClick}
        >
          <Typography className={classes.buttonText} noWrap>
            {buttonText}
          </Typography>
        </Button>
        <Button className={clsx(classes.default)} size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              borderRadius: '12px',
              backgroundColor: theme.palette.secondary.dark,
              boxShadow: 'none',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {getMenuOptions().map((option) => (
                    <MenuItem
                      key={option}
                      className={classes.buttonText}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default CaseTypeButton;
