import { Box, FormControlLabel, Checkbox, FormGroup, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';

import { QuestionnaireSection, QuestionnaireItem } from '../../../types';

const checkMarkURI =
  // eslint-disable-next-line quotes
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E";

const indeterminateCheckMarkURI =
  // eslint-disable-next-line quotes
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.83333 9.16675H14.1667C14.625 9.16675 15 9.54175 15 10.0001C15 10.4584 14.625 10.8334 14.1667 10.8334H5.83333C5.375 10.8334 5 10.4584 5 10.0001C5 9.54175 5.375 9.16675 5.83333 9.16675Z' fill='%23fff'/%3E%3C/svg%3E";

const CheckBoxIcon = styled('span')(() => ({
  borderRadius: '8px',
  width: '24px',
  height: '24px',
  backgroundColor: 'transparent',
  border: '2px solid #4A4E5D',
}));

const CheckedCheckBoxIcon = styled(CheckBoxIcon)({
  backgroundColor: '#00667C',
  border: '2px solid #00667C',
  '&:before': {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: `url("${checkMarkURI}")`,
    content: '""',
  },
});

const IndeterminateCheckBoxIcon = styled(CheckBoxIcon)({
  backgroundColor: '#00667C',
  border: '2px solid #00667C',
  '&:before': {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: `url("${indeterminateCheckMarkURI}")`,
    content: '""',
  },
});

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  sectionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 0px 20px',
    gap: '8px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 0px 20px',
    gap: '8px',
  },
  root: {
    marginLeft: 0,
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.common.white,
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0px',
    },
  },
}));

interface IndeterminateCheckboxProps {
  section: QuestionnaireSection;
  acceptedItems: string[];
  setAcceptedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

// TODO: separate component / code review
const IndeterminateCheckbox = ({
  section,
  acceptedItems,
  setAcceptedItems,
}: IndeterminateCheckboxProps) => {
  const { classes } = useStyles();

  const handleParentCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const accepted = section.items
        .filter((item) => {
          return !acceptedItems.includes(item.id);
        })
        .map((item) => {
          return item.id;
        });

      setAcceptedItems((prev) => [...prev, ...accepted]);
    } else {
      const arr = section.items.map((item) => item.id);
      setAcceptedItems((prev) => prev.filter((item) => !arr.includes(item)));
    }
  };

  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = acceptedItems.indexOf(event.target.value);
    if (index === -1) {
      setAcceptedItems([...acceptedItems, event.target.value]);
    } else {
      setAcceptedItems(acceptedItems.filter((item) => item !== event.target.value));
    }
  };

  const children = (
    <Box className={classes.itemsWrapper}>
      {section.items.map((item) => {
        return (
          <FormControlLabel
            key={item.id}
            label={item.systemName}
            className={classes.root}
            control={
              <Checkbox
                value={item.id}
                checked={acceptedItems.includes(item.id)}
                onChange={handleItemChange}
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckedCheckBoxIcon />}
                indeterminateIcon={<IndeterminateCheckBoxIcon />}
              />
            }
          />
        );
      })}
    </Box>
  );

  const getCheckedValue = (arr: string[], target: QuestionnaireItem[]) =>
    target.every((el) => arr.includes(el.id));

  const checkIndeterminate = () => {
    const arr = section.items.filter((item) => acceptedItems.includes(item.id));
    return arr.length > 0 && arr.length < section.items.length;
  };

  return (
    <Box className={classes.section}>
      <FormControlLabel
        label={section.title}
        className={classes.root}
        control={
          <Checkbox
            checked={getCheckedValue(acceptedItems, section.items)}
            indeterminate={checkIndeterminate()}
            onChange={handleParentCheckbox}
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckedCheckBoxIcon />}
            indeterminateIcon={<IndeterminateCheckBoxIcon />}
          />
        }
      />
      {children}
    </Box>
  );
};

interface ItemListProps {
  readonly acceptedItems: string[];
  readonly numberOfItems: number;
  readonly selectedSections: QuestionnaireSection[];
  readonly setAcceptedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const ItemList = ({
  acceptedItems,
  numberOfItems,
  selectedSections,
  setAcceptedItems,
}: ItemListProps) => {
  const { classes } = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const items: string[] = [];

      selectedSections.forEach((section) => {
        section.items.forEach((item) => {
          items.push(item.id);
        });
      });

      setAcceptedItems(items);
    } else {
      setAcceptedItems([]);
    }
  };

  return (
    <Box className={classes.container}>
      {selectedSections?.length !== 0 && (
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            label={'Select All'}
            className={classes.root}
            control={
              <Checkbox
                checked={numberOfItems !== 0 && acceptedItems.length === numberOfItems}
                indeterminate={acceptedItems.length > 0 && acceptedItems.length < numberOfItems}
                onChange={handleChange}
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckedCheckBoxIcon />}
                indeterminateIcon={<IndeterminateCheckBoxIcon />}
              />
            }
          />
          <Box className={classes.sectionsWrapper}>
            {selectedSections.map((section) => {
              return (
                <IndeterminateCheckbox
                  key={section.items[0].id}
                  section={section}
                  acceptedItems={acceptedItems}
                  setAcceptedItems={setAcceptedItems}
                />
              );
            })}
          </Box>
        </FormGroup>
      )}
    </Box>
  );
};

export default ItemList;
