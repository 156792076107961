import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { PRIVACY_POLICY_URL } from '../../../constants/constants';

const MobileAppPrivacyPolicyView = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div
        style={{
          height: '100%',
        }}
      >
        <Viewer fileUrl={PRIVACY_POLICY_URL} />
      </div>
    </Worker>
  );
};

export default MobileAppPrivacyPolicyView;
