import { Box } from '@mui/material';

import { defendantsAccountTabs } from '../../constants/tabRoutes';

import TabsPanel from '../../components/TabsPanel';

const DefendantAccountView = () => {
  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel tabsList={defendantsAccountTabs} locationSlicePosition={3} />
      </Box>
    </>
  );
};

export default DefendantAccountView;
