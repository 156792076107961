export const numberToUSDCurrency = (value?: string | number) => {
  if (value === undefined) return '—';

  return value !== null
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(value))
    : '—';
};
