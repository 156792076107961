import { useEffect, useState } from 'react';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import AddButton from '../../../components/buttons/AddButton';
import {
  deleteSettlementRecord,
  getSettlementsCheck,
  paySettlement,
} from '../../../api/paymentsApi/settlementsApi';
import CommonButton from '../../../components/buttons/CommonButton';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { settlementsEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../../hooks/usePageTitle';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord, ErrorData } from '../../../types';
import TooltipComponent from '../../../components/TooltipComponent';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import VerificationPopup from '../../../components/VerificationPopup';
import { checkUserPin } from '../../../api/usersApi/usersApi';
import { SettlementAdditionalInfoData, SettlementsPayoutCheckData } from '../types';
import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { FieldHeadline } from '../styled';

import SettlementsDeductionsTable from './SettlementsDeductionsTable';
import SettlementsPayoutCheck from './SettlementsPayoutCheck';
import { ReactComponent as InfoIcon } from './info.svg';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1132px',
    gap: '24px',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      flexBasis: '800px',
      flexShrink: 1,
      minWidth: 0,
    },
  },
  formLeftColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '492px',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    padding: '32px',
    flexShrink: 1,
    minWidth: 0,

    '@media (max-width: 1200px)': {
      flexShrink: 'inherit',
      minWidth: 'inherit',
    },

    '@media (max-width: 650px)': {
      padding: '16px',
    },
  },
  formCheckDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '424px',
  },
  formActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '168px',

    '@media (max-width: 1200px)': {
      maxWidth: '250px',
    },
  },
}));

const SettlementsEditFormContainer = () => {
  const navigate = useNavigate();
  const styles = useStyles();

  const userId = localStorage.getItem('userId');
  const { settlementId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [payoutCheck, setPayoutCheck] = useState<SettlementsPayoutCheckData>();
  const [isPayoutCheckUpdating, setIsPayoutCheckUpdating] = useState<boolean>(true);
  const [settledAmount, setSettledAmount] = useState<string>('');
  const [additionalSettlementInfo, setAdditionalSettlementInfo] =
    useState<SettlementAdditionalInfoData>();
  const [currentCaseId, setCurrentCaseId] = useState<string>('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [isPaymentWarningPopupOpen, setIsPaymentWarningPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [isConfirmPaymentPopupOpen, setIsConfirmPaymentPopupOpen] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(settlementsEditFormSchema),
    defaultValues: SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    reset,
    watch,
    control,
    formState: { isDirty },
  } = formMethods;

  const defendantIdValue = watch('defendant.id');
  const deductionsFields = watch('deduction');
  const plaintiffIdValue = watch('plaintiff.id');

  const { fields } = useFieldArray({
    name: 'deduction',
    control,
  });

  const handleChangeAddToggle = () => {
    const mappedDeductions = deductionsFields
      ?.filter((deduction) => deduction?.isAdded)
      .map((deduction) => deduction?.id);

    handleCalculatePayoutInfo(mappedDeductions as number[]);
  };

  const handleCalculatePayoutInfo = (includedDeductions: number[]) => {
    setIsPayoutCheckUpdating(true);

    getSettlementsCheck(settlementId as string, includedDeductions)
      .then((res) => {
        if (res.data) {
          setPayoutCheck(res.data);

          setTimeout(() => {
            setIsPayoutCheckUpdating(false);
            setAlert({
              type: 'success',
              message: 'Settlement details were updated successfully.',
            });
          }, 500);
        }
      })
      .catch((err) => {
        setIsPayoutCheckUpdating(false);
        setAlert({
          message: err.response.data.message,
          type: 'error',
        });
      });
  };

  const handleDeleteSettlement = () => {
    deleteSettlementRecord(settlementId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Settlement deleted successfully.',
          type: 'success',
        }));
        navigate('/payments/settlements');
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
        handleCloseDeletePopup();
      });
  };

  const handleProcessSettlement = () => {
    handleCloseConfirmPaymentPopup();

    const mappedDeductions = deductionsFields
      ?.filter((deduction) => deduction?.isAdded)
      .map((deduction) => deduction?.id);

    paySettlement(settlementId as string, pin, mappedDeductions as number[])
      .then((res) => {
        if (res.status === 202) {
          setPin('');
          setAlert((prev) => ({
            ...prev,
            message: 'The settlement payment process has been initiated.',
            type: 'success',
          }));
          setTimeout(() => window.location.reload(), 500);
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    reset();
    handleCalculatePayoutInfo([]);
  };

  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleClosePaymentWarningPopup = () => setIsPaymentWarningPopupOpen(false);
  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);
  const handleCloseConfirmPaymentPopup = () => setIsConfirmPaymentPopupOpen(false);
  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleOpenPaymentWarningPopup = () => setIsPaymentWarningPopupOpen(true);
  const handleOpenVerificationPopup = () => setIsVerificationPopupOpen(true);
  const handleOpenConfirmPaymentPopup = () => setIsConfirmPaymentPopupOpen(true);

  const handleConfirmPin = (pin: string) => {
    if (!userId) return;

    checkUserPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          handleCloseVerificationPopup();
          handleOpenConfirmPaymentPopup();
          setPin(pin);
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const onProcessSettlementBtnClick = () => {
    entityData?.status === 'Draft' && handleOpenPaymentWarningPopup();
  };

  useEffect(() => {
    if (entityData) {
      setSettledAmount(entityData.settledAmount as string);
      setCurrentCaseId(entityData.caseId as string);
      setPageTitle && setPageTitle('Settlement');

      reset({
        ...entityData,
      });
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      <Box className={styles.classes.pageContainer}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.pageContainer}>
            <Box className={styles.classes.formContainer}>
              <Box className={styles.classes.formLeftColumnContainer}>
                <FieldHeadline>Identity</FieldHeadline>
                <Box mt="16px">
                  <FormCopyTextField entityName="Settlement" name="id" label="Id" isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="32px">
                  <FieldHeadline>Case</FieldHeadline>
                  <FormLinkButton path={`/cases/${currentCaseId}/home`} />
                </Box>
                <Box mt="16px">
                  <FormTextField name="caseReferenceId" label="ReferenceId" readOnly isReadOnly />
                </Box>
                <Box mt="16px">
                  <FormTextField name="caseSystemName" label="System Name" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="32px">
                  <FieldHeadline>Client</FieldHeadline>
                  <FormLinkButton path={`/clients/${plaintiffIdValue}/home`} />
                </Box>
                <Box mt="16px">
                  <FormTextField name="plaintiff.fullName" label="Client" readOnly isReadOnly />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="32px">
                  <FieldHeadline>Defendant</FieldHeadline>
                  <Box display="flex" gap="10px" alignItems="center">
                    <FormLinkButton
                      path={defendantIdValue ? `/defendants/${defendantIdValue}/home` : '#'}
                      disabled={!defendantIdValue}
                    />
                  </Box>
                </Box>
                <Box mt="16px">
                  <FormAutocompleteField
                    name="defendant"
                    label="Defendant *"
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                  />
                </Box>

                <Box mt="32px" display="flex" gap="4px" alignItems="center">
                  <FieldHeadline>Settled Amount</FieldHeadline>
                  {additionalSettlementInfo && (
                    <TooltipComponent
                      title={`This settled amount is ${
                        additionalSettlementInfo?.percentOfGrossSettlement
                      }% of the case total gross settlement, or ${
                        additionalSettlementInfo?.percentOfTotalGrossSettlement
                      }% of this defendant's gross settlement. Current unpaid gross settlement for this defendant is ${numberToUSDCurrency(
                        additionalSettlementInfo?.defendantUnpaidGrossSettlement,
                      )} `}
                      placement="bottom"
                    >
                      <IconButton
                        sx={{
                          padding: 0,
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </TooltipComponent>
                  )}
                </Box>

                <Box mt="16px">
                  <Typography color="white" ml="8px">
                    {numberToUSDCurrency(Number(settledAmount))}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="100%"
                >
                  <FieldHeadline mt="32px">Deductions</FieldHeadline>
                  {!!deductionsFields?.length && (
                    <FormLinkButton
                      path={
                        deductionsFields?.length
                          ? deductionsFields.length === 1
                            ? `/payments/deduction/${deductionsFields[0]?.id}`
                            : '/payments/deductions'
                          : '#'
                      }
                      disabled={!deductionsFields.length}
                    />
                  )}
                </Box>

                {!!deductionsFields?.length && (
                  <SettlementsDeductionsTable
                    handleChangeAddToggle={handleChangeAddToggle}
                    fields={fields}
                    disabled={isPayoutCheckUpdating || payoutCheck === undefined}
                  />
                )}

                <Box mt="16px" display="flex">
                  <AddButton
                    buttonText="Create deduction"
                    path="/payments/create-deduction"
                    disabled={entityData?.status === 'Paid'}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap="16px" mt="32px">
                  <FormSubmitButton
                    disabled
                    buttonIcon={<SaveIcon />}
                    buttonText="Save"
                    onClick={() => console.log('submitted')}
                  />
                  {isDirty && <FormCancelButton onClick={handleCancelChanges} />}
                </Box>
              </Box>

              <Box className={styles.classes.formCheckDetailsContainer}>
                <SettlementsPayoutCheck
                  setPayoutCheck={setPayoutCheck}
                  payoutCheck={payoutCheck}
                  isPayoutCheckUpdating={isPayoutCheckUpdating}
                  setAdditionalSettlementInfo={setAdditionalSettlementInfo}
                  secondaryFirmsData={entityData?.secondaryFirms}
                  caseSystemName={entityData?.caseSystemName}
                  leadFirmData={entityData?.firm}
                  setIsPayoutCheckUpdating={setIsPayoutCheckUpdating}
                  status={entityData?.status as string}
                />
              </Box>

              <Box className={styles.classes.formActionsContainer}>
                <FieldHeadline>Actions</FieldHeadline>
                <Box mt="16px">
                  <CommonButton
                    disabled={payoutCheck === undefined}
                    type="warning"
                    buttonText="pay settlement"
                    onButtonClick={onProcessSettlementBtnClick}
                  />
                </Box>
                <Box mt="16px">
                  <CommonButton
                    disabled={entityData?.status !== 'Draft'}
                    type="error"
                    buttonText="delete"
                    onButtonClick={handleOpenDeletePopup}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </FormProvider>
        <Popup
          isOpen={isDeletePopupOpen}
          headlineText={POPUP_DESCRIPTIONS.settlementDelete.headlineText}
          contentText={POPUP_DESCRIPTIONS.settlementDelete.contentText}
          onMainButtonClick={handleDeleteSettlement}
          onSecondaryButtonClick={handleCloseDeletePopup}
        />
        <Popup
          isOpen={isPaymentWarningPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.paySettlement.headlineText}
          contentText={POPUP_DESCRIPTIONS.paySettlement.contentText}
          onMainButtonClick={() => {
            handleClosePaymentWarningPopup();
            handleOpenVerificationPopup();
          }}
          onSecondaryButtonClick={handleClosePaymentWarningPopup}
        />
        <VerificationPopup
          isOpen={isVerificationPopupOpen}
          onMainButtonClick={handleConfirmPin}
          onSecondaryButtonClick={handleCloseVerificationPopup}
        />
        <Popup
          isOpen={isConfirmPaymentPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.paySettlement.headlineText}
          contentText={'Settled amount'}
          PopupContentComponent={() => (
            <Typography
              fontFamily={'Poppins'}
              fontWeight={'600'}
              fontSize={'20px'}
            >{`${numberToUSDCurrency(settledAmount)}`}</Typography>
          )}
          onMainButtonClick={handleProcessSettlement}
          onSecondaryButtonClick={() => {
            handleCloseConfirmPaymentPopup();
            setPin('');
          }}
        />
      </Box>
    </>
  );
};

export default SettlementsEditFormContainer;
