import { Box, CircularProgress } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface LoaderProps {
  readonly colorType: OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'
  >;
  readonly size?: string | number | undefined;
}

// TODO: Why do we need this? sx={{ marginBottom: '100px' }}
const Loader = ({ colorType, size }: LoaderProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
      <CircularProgress color={colorType} size={size || 100} />
    </Box>
  );
};

export default Loader;
