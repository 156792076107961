import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme } from '@mui/material';

import { ReactComponent as DrawGirlImage } from './assets/draw-girl.svg';
import { ReactComponent as DealImage } from './assets/deal-image.svg';
import { ReactComponent as FeaturePaymentsDesktopImage } from './assets/feature-payments-desktop.svg';
import { ReactComponent as FeaturePaymentsResponsiveImage } from './assets/feature-payments-responsive.svg';
import { ReactComponent as FeatureRequestsDesktopImage } from './assets/feature-requests-desktop.svg';
import { ReactComponent as FeatureRequestsResponsiveImage } from './assets/feature-requests-responsive.svg';

import AppDemoPreviewBlock from './AppDemoPreviewBlock';
import Header from './Header';
import WelcomeBlock from './WelcomeBlock';
import BenefitsBlock from './BenefitsBlock';
import FeatureBlock from './FeatureBlock';
import FeatureDetailsBlock from './FeatureDetailsBlock';
import RequestDemoBlock from './RequestDemoBlock';
import Footer from './Footer';
import useWindowWidth from '../../hooks/useWindowWidth';
import HowItWorksBlock from './HowItWorksBlock';

const useStyles = makeStyles()((theme: Theme) => ({
  pageMainContainer: {
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19) 3.19%, rgba(37, 157, 168, 0.00) 37.39%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#283645',
    fontFamily: 'Inter',
  },
  footerBackground: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',
  },
}));

const LandingView = () => {
  const styles = useStyles();
  const windowWidth = useWindowWidth();

  const [isRequestDemoPopupOpen, setIsRequestDemoPopupOpen] = useState<boolean>(false);

  const handleClosePopup = () => setIsRequestDemoPopupOpen(false);
  const handleOpenPopup = () => setIsRequestDemoPopupOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  return (
    <Box className={styles.classes.pageMainContainer}>
      <Header
        windowWidth={windowWidth}
        handleOpenPopup={handleOpenPopup}
        isRequestDemoPopupOpen={isRequestDemoPopupOpen}
        handleClosePopup={handleClosePopup}
      />
      <WelcomeBlock windowWidth={windowWidth} handleOpenPopup={handleOpenPopup} />
      <AppDemoPreviewBlock />
      <BenefitsBlock windowWidth={windowWidth} />
      <Box id="features">
        <FeatureBlock
          featureLabel="Faster settlements for happier clients"
          featureText="Tie up all the loose ends in a case without making it hard on your clients. Digital signatures, document scans and missing data, all in one place."
          handleOpenPopup={handleOpenPopup}
          featureIcon={<DrawGirlImage />}
          windowWidth={windowWidth}
          featureImageDesktop={<FeatureRequestsDesktopImage />}
          featureImageResponsive={<FeatureRequestsResponsiveImage />}
        />
        <FeatureBlock
          featureLabel="Zero-friction, painless payments"
          featureText="Compliant, insured and fast. Stop cutting checks and pay your clients with v."
          handleOpenPopup={handleOpenPopup}
          featureIcon={<DealImage />}
          isReversed
          windowWidth={windowWidth}
          featureImageDesktop={<FeaturePaymentsDesktopImage />}
          featureImageResponsive={<FeaturePaymentsResponsiveImage />}
        />
      </Box>

      <FeatureDetailsBlock windowWidth={windowWidth} handleOpenPopup={handleOpenPopup} />
      <HowItWorksBlock windowWidth={windowWidth} />
      <Box className={styles.classes.footerBackground}>
        <RequestDemoBlock windowWidth={windowWidth} handleOpenPopup={handleOpenPopup} />
        <Footer />
      </Box>
    </Box>
  );
};

export default LandingView;
