import { Theme, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    width: 'auto',
    maxWidth: '100%',
    height: '48px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.08)',
    color: theme.palette.text.disabled,

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
  active: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
  },
}));

interface CaseTypeButtonProps {
  readonly buttonText: string;
  readonly isActive?: boolean;
  readonly onClick?: () => void;
}

const CaseTypeButton = ({ buttonText, isActive, onClick }: CaseTypeButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button
      className={clsx({
        [classes.default]: true,
        [classes.active]: isActive,
      })}
      onClick={onClick}
    >
      <Typography className={classes.buttonText} noWrap>
        {buttonText}
      </Typography>
    </Button>
  );
};

export default CaseTypeButton;
