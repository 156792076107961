import { Typography } from '@mui/material';

import { styled } from '@mui/system';

export const FieldHeadline = styled(Typography)`
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.common.white};
`;

export const StyledForm = styled('form')`
  padding: 32px;
  flex-basis: 416px;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 16px;
`;

export const StyledEditForm = styled('form')`
  padding: 32px;
  flex-basis: 1128px;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 16px;
`;
