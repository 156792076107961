import Timeline from '../../../components/Timeline';
import { useEffect, useState } from 'react';
import { TimelineType } from '../../../types';
import { useParams } from 'react-router';
import { getDeductionTimeline } from '../../../api/paymentsApi/deductionsApi';

const DeductionsTimeline = () => {
  const { deductionId } = useParams<string>();
  const [deductionTimeline, setDeductionTimeline] = useState<TimelineType>([]);

  useEffect(() => {
    getDeductionTimeline(deductionId as string).then((res) => {
      if (res.data) {
        setDeductionTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={deductionTimeline} />;
};

export default DeductionsTimeline;
