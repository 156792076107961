export const calcShownCurrentPageRows = (
  pageNumber: number,
  rowsPerPage: number,
  totalCount: number,
) => {
  const startRange = (pageNumber - 1) * rowsPerPage + 1;
  const endRange = Math.min(pageNumber * rowsPerPage, totalCount);

  return `${startRange}-${endRange} of ${totalCount}`;
};
