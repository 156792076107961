import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { ReactComponent as ZendeskLogo } from './zendesk.svg';
import { ReactComponent as OneSignalLogo } from './onesignal.svg';

const ZENDESK_ADMIN_URL = 'https://vlaw.zendesk.com/admin/home';
const ONE_SIGNAL_URL = 'https://dashboard.onesignal.com/login';

const useStyles = makeStyles()((theme: Theme) => ({
  communicationLinkWrapper: {
    maxWidth: '180px',
    width: '100%',
    maxHeight: '160px',
    height: '160px',
  },
  communicationLinkInnerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    height: '100%',
  },
  communicationExternalLinkIconContainer: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
}));

const CommunicationsView = () => {
  const styles = useStyles();

  return (
    <Box display="flex" gap="32px">
      <Link
        className={styles.classes.communicationLinkWrapper}
        to={ZENDESK_ADMIN_URL}
        target="_blank"
      >
        <Box className={styles.classes.communicationLinkInnerContainer}>
          <Box className={styles.classes.communicationExternalLinkIconContainer}>
            <OpenInNewIcon color="primary" />
          </Box>

          <ZendeskLogo />
        </Box>
      </Link>
      <Link className={styles.classes.communicationLinkWrapper} to={ONE_SIGNAL_URL} target="_blank">
        <Box className={styles.classes.communicationLinkInnerContainer}>
          <Box className={styles.classes.communicationExternalLinkIconContainer}>
            <OpenInNewIcon color="primary" />
          </Box>

          <OneSignalLogo />
        </Box>
      </Link>
    </Box>
  );
};

export default CommunicationsView;
