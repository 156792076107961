import { FormProvider, useForm } from 'react-hook-form';
import { Link as LinkRoute } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Link, Theme, useMediaQuery } from '@mui/material';

import { SignInFormProps } from './types';
import { ReactComponent as LogoIcon } from './sign-logo.svg';

import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import useAuth from '../../hooks/useAuth';
import { SIGN_IN_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { signInFormSchema } from '../../helpers/formSchemas/formSchemas';
import FormTextField from '../../components/formFields/FormTextField';
import FormPasswordField from '../../components/formFields/FormPasswordField';
import useSnackBar from '../../hooks/useSnackBar';
import { send2FAcode } from '../../api/authApi/authApi';
import { useEffect } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
  signInBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',

    '@media (max-width: 640px)': {
      padding: '16px',
    },

    '@media (max-height: 620px)': {
      height: 'auto',
    },
  },
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '32px',
    marginTop: '60px',
    maxWidth: '480px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '64px',

    '@media (max-width: 640px)': {
      padding: '16px',
    },
  },
  signInFormHeadline: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '34px',
    letterSpacing: '0px',
    color: theme.palette.common.white,

    '@media (max-width: 640px)': {
      fontSize: '22px',
      lineHeight: '30px',
    },
  },
  signInFormText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.common.white,
  },
  signInFormLink: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  signInFormBottomLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textAlign: 'center',
    textDecoration: 'none',
  },
  signInLogoContainer: {
    '@media (max-height: 700px)': {
      marginTop: '40px',
    },
  },
}));

const SignInView = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const sm = useMediaQuery('(max-width:640px)');
  const { setAlert } = useSnackBar();
  const { checkAuth, loading, isAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInFormSchema),
    defaultValues: SIGN_IN_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = formMethods;

  const onSubmit = (values: SignInFormProps) => {
    const submitData = {
      email: values.email,
      password: values.password,
    };

    send2FAcode(submitData)
      .then((res) => {
        if (res.data) {
          navigate('/confirm-2fa-code', {
            state: {
              email: values.email,
              password: values.password,
              countdownTime: res.data.sendTimeoutSec,
            },
          });
          setAlert((prev) => ({
            ...prev,
            message: 'Verification code has been sent successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response.data.code === 'SET_PERMANENT_PASSWORD') {
            navigate('/changePassword', {
              state: {
                email: values.email,
                password: values.password,
              },
            });
          } else {
            setAlert((prev) => ({
              ...prev,
              message: err.response.data.message,
              type: 'error',
            }));
          }
        }
      });
  };

  if (loading) return null;
  if (isAuth) return <Navigate to="/cases" />;

  return (
    <Box className={styles.classes.signInBackground}>
      <Box className={styles.classes.signInLogoContainer}>
        <LinkRoute to="/">
          <LogoIcon />
        </LinkRoute>
      </Box>

      <FormProvider {...formMethods}>
        <form className={styles.classes.signInFormContainer}>
          <Typography className={styles.classes.signInFormHeadline}>Sign in to v.</Typography>

          <Box mt={sm ? '16px' : '32px'}>
            <FormTextField name="email" label="Email address" />
          </Box>
          <Box mt="24px">
            <FormPasswordField name="password" label="Password" />
          </Box>

          <Link
            mt={sm ? '18px' : '36px'}
            className={styles.classes.signInFormLink}
            onClick={() => navigate('/forgotPassword')}
          >
            Forgot Password?
          </Link>

          <Box mt={sm ? '16px' : '32px'}>
            <FormSubmitButton
              buttonText="Continue"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>

          <Typography color="white" mt={sm ? '18px' : '36px'} fontFamily="Inter" fontSize="14px">
            By signing in you agree to our{' '}
            <LinkRoute
              to="/terms-of-use"
              className={styles.classes.signInFormBottomLink}
              target="_blank"
            >
              Terms of Use
            </LinkRoute>{' '}
            and{' '}
            <LinkRoute
              to="/privacy-policy"
              className={styles.classes.signInFormBottomLink}
              target="_blank"
            >
              Privacy Policy
            </LinkRoute>
          </Typography>
        </form>
      </FormProvider>
    </Box>
  );
};

export default SignInView;
