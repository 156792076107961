import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as LogoIcon } from '../LandingView/assets/logo.svg';

import { deletePlaintiffAccount } from '../../api/clientsApi/clientsApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import Loader from '../../components/Loader';
import useSnackBar from '../../hooks/useSnackBar';
import { ErrorData } from '../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  pageBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',

    '@media (max-width: 640px)': {
      padding: '20px',
    },

    '@media (max-height: 420px)': {
      height: '100%',
      padding: '20px',
    },
  },
  signInLogoContainer: {
    marginBottom: '60px',
    svg: {
      color: theme.palette.common.white,
    },
  },
  formContentText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    marginBottom: '20px',
    color: theme.palette.common.white,
  },
  formContainer: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: '32px',
    maxWidth: '480px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    width: '100%',
  },
  formTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '40px',
  },
  supportLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '120%',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
}));

enum RequestStatus {
  Pending,
  Rejected,
  Fulfilled,
}

const DeletePlaintiffAccountView = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.Fulfilled);

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');

  const deleteAccount = () => {
    if (token?.length && email?.length) {
      setRequestStatus(RequestStatus.Pending);

      deletePlaintiffAccount(token as string, email as string)
        .then((response) => {
          if (response.status === 204) {
            setRequestStatus(RequestStatus.Fulfilled);
          }
        })
        .catch((error: AxiosError<ErrorData>) => {
          const errorMessage = error.response?.data.message || 'Error. Something went wrong...';
          setRequestStatus(RequestStatus.Rejected);
          setAlert((prev) => ({
            ...prev,
            type: 'error',
            message: errorMessage,
          }));
        });
    } else {
      navigate('/');

      return;
    }
  };

  const handleDoneButtonClick = () => {
    navigate('/');
  };

  const handleRetryButtonClick = () => {
    deleteAccount();
  };

  useEffect(() => {
    deleteAccount();
  }, [token, email]);

  return (
    <Box className={classes.pageBackground}>
      <Box className={classes.signInLogoContainer}>
        <Link to="/">
          <LogoIcon />
        </Link>
      </Box>

      {requestStatus === RequestStatus.Pending && (
        <Box mb="100px">
          <Loader colorType="warning" />
          <Typography className={classes.formContentText}>
            The account deletion in process...
          </Typography>
        </Box>
      )}

      {requestStatus === RequestStatus.Fulfilled && (
        <Box className={classes.formContainer}>
          <Typography className={classes.formTitle}>Data Safety</Typography>

          <Typography
            className={classes.formContentText}
          >{`The account associated with email address ${email} has successfully been deleted.`}</Typography>

          <Box display="flex">
            <FormSubmitButton
              onClick={handleDoneButtonClick}
              buttonText="Done"
              fontFamily="Inter"
              disabled={false}
            />
          </Box>
        </Box>
      )}

      {requestStatus === RequestStatus.Rejected && (
        <Box className={classes.formContainer}>
          <Typography className={classes.formTitle}>Data Safety</Typography>

          <Typography className={classes.formContentText}>
            Error. Something went wrong...
          </Typography>

          <Typography className={classes.formContentText}>
            You can write to v.law support team by clicking on link down below:
          </Typography>

          <Typography className={classes.formContentText}>
            <Link className={classes.supportLink} to="/support">
              https://v.law/support
            </Link>
          </Typography>

          <Box display="flex">
            <FormSubmitButton
              onClick={handleRetryButtonClick}
              buttonText="Retry"
              fontFamily="Inter"
              disabled={false}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DeletePlaintiffAccountView;
