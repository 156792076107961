import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1';

export const getDefendantsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}/defendants?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}${sortFilters}`;

  return await api.get(targetUrl);
};

export const createDefendantRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/defendants`;

  return await api.post(targetUrl, data);
};

export const updateDefendantRecord = async (defendantId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/defendants/${defendantId}`;

  return await api.put(targetUrl, data);
};

export const getDefendantRecord = async (defendantId: string) => {
  const targetUrl = `${BASE_URL}/defendants/${defendantId}`;

  return await api.get(targetUrl);
};
