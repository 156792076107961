import { Link } from 'react-router-dom';

import { Box, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface CasesAddButtonProps {
  disabled?: boolean;
  buttonText: string;
  handleClick?: () => void;
  path?: string;
}

const AddButton = ({ buttonText, handleClick, disabled, path }: CasesAddButtonProps) => {
  const theme = useTheme();

  return (
    <>
      {path ? (
        <Link
          to={path as string}
          target="_blank"
          style={{
            textDecoration: 'none',
            background: disabled ? 'rgba(255, 255, 255, 0.08)' : theme.palette.info.dark,
            maxWidth: 'fit-content',
            padding: '6px 20px',
            borderRadius: '12px',
            pointerEvents: disabled ? 'none' : 'auto',
          }}
        >
          <Box display="flex" alignItems="center" gap="4px">
            <AddIcon
              sx={{
                color: disabled ? theme.palette.text.disabled : theme.palette.common.white,
              }}
            />
            <Typography
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="500"
              lineHeight="normal"
              textTransform="none"
              color={disabled ? theme.palette.text.disabled : theme.palette.common.white}
            >
              {buttonText}
            </Typography>
          </Box>
        </Link>
      ) : (
        <Box
          onClick={handleClick}
          display="flex"
          alignItems="center"
          gap="4px"
          maxWidth="fit-content"
          borderRadius="12px"
          bgcolor={disabled ? 'rgba(255, 255, 255, 0.08)' : theme.palette.info.dark}
          height="36px"
          padding="0px 20px"
          sx={{
            pointerEvents: disabled ? 'none' : 'auto',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <AddIcon
            sx={{
              color: disabled ? theme.palette.text.disabled : theme.palette.common.white,
            }}
          />
          <Typography
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            lineHeight="normal"
            textTransform="none"
            color={disabled ? theme.palette.text.disabled : theme.palette.common.white}
          >
            {buttonText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AddButton;
