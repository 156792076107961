import Timeline from '../../../components/Timeline';
import { useEffect, useState } from 'react';
import { TimelineType } from '../../../types';
import { useParams } from 'react-router';
import { getSignatureTimeline } from '../../../api/requestsApi/signaturesApi';

const SignaturesTimeline = () => {
  const { signatureId } = useParams<string>();
  const [signatureTimeline, setSignatureTimeline] = useState<TimelineType>([]);

  useEffect(() => {
    getSignatureTimeline(signatureId as string).then((res) => {
      if (res.data) {
        setSignatureTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={signatureTimeline} />;
};

export default SignaturesTimeline;
