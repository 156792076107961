import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { handleGetDefendants, mapDefendantsQueryResults } from '../helpers';
import { DeductionCreateFormSubmitProps } from '../types';

import { createDeductionRecord } from '../../../api/paymentsApi/deductionsApi';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import FormSelectField from '../../../components/formFields/FormSelectField';
import FormTextField from '../../../components/formFields/FormTextField';
import FormAutocompleteField from '../../../components/formFields/FormAutocompleteField';
import FormToggleField from '../../../components/formFields/FormToggleField';
import { DEDUCTION_TYPES } from '../../../constants/constants';
import { DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { deductionsCreateFormSchema } from '../../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../../hooks/useSnackBar';
import SearchCasePopup from '../../../components/SearchCasePopup';
import { CaseShortData } from '../../../types';
import FindCaseButton from '../../../components/buttons/FindCaseButton';
import CaseInfoPreview from '../../../components/CaseInfoPreview';
import { getCaseRecord } from '../../../api/casesApi/casesApi';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    padding: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 850px)': {
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 850px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 850px)': {
      flexBasis: 0,
    },
  },
  formSecondInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 850px)': {
      flexBasis: 0,
      marginTop: '32px',
    },
  },
  typeSectionHeadline: {
    marginTop: '40px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',

    '@media (max-width: 850px)': {
      marginTop: '32px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const CreateDeductionFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();

  const caseId = new URLSearchParams(window.location.search).get('caseId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState<CaseShortData | null>(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(deductionsCreateFormSchema),
    defaultValues: DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const isAssignedToggleValue = watch('isAssigned');
  const defendantFieldValue = watch('defendant.id');

  const handleSetCaseValue = (item: CaseShortData) => {
    setIsPopupOpen(false);
    setCaseDetails(item);
  };

  const onSubmitForm = (values: DeductionCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      defendantId: !values.defendant.id?.length ? null : Number(values.defendant.id),
      caseId: Number(caseDetails?.id),
      amount: values.amount,
      type: values.type,
    };

    createDeductionRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/payments/deduction/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Deduction created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    reset();
    !caseId && setCaseDetails(null);
  };

  useEffect(() => {
    if (!isAssignedToggleValue) {
      setValue('defendant', { id: '', label: '' });
    }
  }, [isAssignedToggleValue, setValue]);

  useEffect(() => {
    if (!caseId) return;

    getCaseRecord(Number(caseId)).then((res) => {
      setCaseDetails(res.data);
    });
  }, [caseId]);

  return (
    <>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.formContainer}>
            <Box className={styles.classes.formInfoContainer}>
              <Box className={styles.classes.formInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Case</Typography>
                <Box mt="16px" display="flex" flexDirection="column" gap="16px">
                  {caseDetails && <CaseInfoPreview caseItem={caseDetails} />}
                  {!caseId && <FindCaseButton onButtonClick={() => setIsPopupOpen(true)} />}
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt="32px">
                  <Typography className={styles.classes.fieldHeadline}>Defendant</Typography>
                  <FormToggleField name="isAssigned" label="Assigned" disabled={!caseDetails} />
                </Box>

                <Box mt="10px">
                  <FormAutocompleteField
                    optionsQuery={handleGetDefendants}
                    mapQueryResults={mapDefendantsQueryResults}
                    name="defendant"
                    label="Defendant *"
                    filters={{ caseId: caseDetails?.id }}
                    disabled={!caseDetails || !isAssignedToggleValue}
                  />
                </Box>
              </Box>

              <Box className={styles.classes.formSecondInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Amount</Typography>
                <Box mt="16px">
                  <FormTextField name="amount" label="Amount *" />
                </Box>

                <Typography className={styles.classes.typeSectionHeadline}>Type</Typography>

                <Box mt="16px">
                  <FormSelectField
                    options={DEDUCTION_TYPES}
                    name="type"
                    label="Type *"
                    width="100%"
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" width="100%" justifyContent="center">
              <Box
                display="flex"
                mt="32px"
                maxWidth="416px"
                flexDirection="column"
                gap="16px"
                width="100%"
              >
                <FormSubmitButton
                  disabled={
                    !isValid ||
                    (isAssignedToggleValue && !defendantFieldValue) ||
                    isFormSubmitting ||
                    !caseDetails
                  }
                  buttonIcon={<SaveIcon />}
                  buttonText="Save"
                  onClick={handleSubmit(onSubmitForm)}
                />
                <FormCancelButton
                  disabled={caseId ? !isDirty : !(isDirty || caseDetails)}
                  onClick={handleCancelChanges}
                />
              </Box>
            </Box>
          </form>
        </FormProvider>
        <SearchCasePopup
          isOpen={isPopupOpen}
          onMainButtonClick={handleSetCaseValue}
          onSecondaryButtonClick={() => setIsPopupOpen(false)}
        />
      </Box>
    </>
  );
};

export default CreateDeductionFormContainer;
