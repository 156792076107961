import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import DeductionsTableDataRow from './DeductionsTableDataRow';

import { getDeductionsList } from '../../../api/paymentsApi/deductionsApi';
import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import TableContainer from '../../../components/Table/TableContainer';
import { DEDUCTIONS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, DeductionData, FilterTypes } from '../../../types';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import {
  DEDUCTIONS_STATUS_FILTER_OPTIONS,
  DEDUCTION_TYPE_OPTIONS,
} from '../../../components/TableFilters/constants';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const DeductionsView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [deductionsList, setDeductionsList] = useState<DeductionData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    caseName: {
      order: '',
      fieldName: 'caseName',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    paidAt: {
      order: '',
      fieldName: 'paidAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getDeductionsList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setDeductionsList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv('/v2/deductions/export', 'POST', 'Deductions_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Deductions exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !deductionsList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink={`/payments/create-deduction${
              params.caseId ? `/?caseId=${params.caseId}` : '/'
            }`}
            exportList={exportList}
            match={match}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            setMatch={setMatch}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              client
              defendant
              timeFilter={'ENTRY DATE'}
              typeFilter
              typeFilterOptions={DEDUCTION_TYPE_OPTIONS}
              statusFilter={DEDUCTIONS_STATUS_FILTER_OPTIONS}
              isPlaintiff
            />
          )}

          {!isDataLoading && deductionsList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={DEDUCTIONS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <DeductionsTableDataRow tableData={deductionsList} />
            </TableContainer>
          )}
        </>
      )}
      <Outlet />
    </>
  );
};

export default DeductionsView;
