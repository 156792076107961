import { useNavigate, useParams } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { SignatureData } from '../../../types';
import { getDatesDiff } from '../../../helpers/getDatesDiff';
import { parseStatusTitle } from '../../../helpers/parseStatusTitle';

interface SignaturesTableDataRowProps {
  tableData: SignatureData[];
}

const SignaturesTableDataRow = ({ tableData }: SignaturesTableDataRowProps) => {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/requests/signature-request/${id}/home`);
  };

  const formattedSignaturesList =
    tableData &&
    tableData.map((signature: SignatureData) => ({
      id: signature.id,
      rowFields: {
        ...(!caseId && {
          name: `${signature.client.firstName} ${signature.client.middleName || ''} ${
            signature.client.lastName
          }`,
        }),
        createdAt: formatDate(signature.createdAt as string),
        documentName: signature.name,
        status: parseStatusTitle(signature.status),
        completionDate: signature.completedAt ? formatDate(signature.completedAt as string) : '-',
        completionTime:
          signature.status === 'Completed'
            ? getDatesDiff(signature.createdAt, signature.completedAt)
            : '-',
        documentId: signature.documentId,
      },
    }));

  return (
    <>
      {formattedSignaturesList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SignaturesTableDataRow;
