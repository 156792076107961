import { useNavigate } from 'react-router-dom';

import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { CaseShortData } from '../../types';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';

interface CasesTableDataRowProps {
  tableData: CaseShortData[];
}

const CasesTableDataRow = ({ tableData }: CasesTableDataRowProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/cases/${String(id)}/home`);
  };

  const formattedList =
    tableData &&
    tableData.map((caseItem) => ({
      id: caseItem.id,
      rowFields: {
        caseType: (
          <Box display="flex" flexDirection="column">
            <Typography
              fontWeight="400"
              fontFamily="Inter"
              fontSize="14px"
              lineHeight="20px"
              maxWidth="200px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {caseItem.name}
            </Typography>
            <Typography
              fontWeight="400"
              fontFamily="Inter"
              fontSize="14px"
              lineHeight="20px"
              color={theme.palette.error.main}
              maxWidth="200px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {caseItem.type.name}
            </Typography>
          </Box>
        ),
        client: `${caseItem.client.firstName} ${caseItem.client.lastName}`,
        defendants: `${caseItem.defendants[0].name}`,
        createdAt: formatDate(caseItem.createdAt),
        filedAt: formatDate(caseItem.filedAt || ''),
        sol: formatDate(caseItem.sol || ''),
        status: caseItem.status,
        gross: numberToUSDCurrency(
          caseItem.defendants.reduce((acc, def) => {
            acc += def.grossSettlement || 0;

            return acc;
          }, 0),
        ),
      },
    }));

  return (
    <>
      {formattedList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default CasesTableDataRow;
