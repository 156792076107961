import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import clsx from 'clsx';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';

import { ClientUpdateSSNDobFormSubmitProps } from './types';

import { updateClientPersonalInformation } from '../../api/clientsApi/clientsApi';
import FormDateField from '../../components/formFields/FormDateField';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';
import { EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { editClientSSNDoBFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0px',
  },
  dialogContent: {
    marginTop: '16px',
    padding: '0px',
  },
  form: {
    padding: '8px 0px 16px 0px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '0px',
    marginTop: '32px',
    flexDirection: 'column',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  buttonDefault: {
    height: '48px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  createButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface ClientUpdateSSNDobFormProps {
  isOpen: boolean;
  onSecondaryButtonClick: () => void;
  formData: { ssn: string; birthdate?: Date | null };
  clientId: number;
  handleUpdateNewSSNDoBvalues: (newValues: { ssn: string; birthdate?: Date | null }) => void;
}

const ClientUpdateSSNDobForm = ({
  isOpen,
  onSecondaryButtonClick,
  formData,
  clientId,
  handleUpdateNewSSNDoBvalues,
}: ClientUpdateSSNDobFormProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const [isFormSubmitting, setIsFormSumbitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(editClientSSNDoBFormSchema),
    defaultValues: EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES,
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = formMethods;

  const onSubmit = (values: ClientUpdateSSNDobFormSubmitProps) => {
    setIsFormSumbitting(true);

    const submitData = {
      ssn: values.ssn,
      birthdate: moment(values.birthdate).toISOString(true),
    };

    updateClientPersonalInformation(clientId, submitData)
      .then((res) => {
        if (res.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'SSN and Date of Birth updated successfully.',
            type: 'success',
          }));
          setIsFormSumbitting(false);
          handleUpdateNewSSNDoBvalues(values);
          handleCancelButtonClick();
        }
      })
      .catch((err) => {
        setIsFormSumbitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelButtonClick = () => {
    onSecondaryButtonClick();
    reset(EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES);
  };

  useEffect(() => {
    reset({
      ssn: formData.ssn,
      birthdate: formData?.birthdate,
    });
  }, [formData]);

  return (
    <Dialog
      className={classes.dialogContainer}
      open={isOpen}
      onClose={handleCancelButtonClick}
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle className={classes.dialogTitle}>Edit Personal Information</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormProvider {...formMethods}>
          <form className={classes.form}>
            <Box mt="16px">
              <FormDateField name="birthdate" label="Date of Birth *" maxDate={moment()} />
            </Box>
            <Box mt="24px">
              <FormMaskedTextField name="ssn" label="Social Security Number *" mask="999-99-9999" />
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.buttonDefault, classes.createButton)}
          disabled={!isValid || isFormSubmitting}
          onClick={handleSubmit(onSubmit)}
          disableRipple
        >
          {'Submit'}
        </Button>
        <Button
          className={clsx(classes.buttonDefault, classes.cancelButton)}
          onClick={handleCancelButtonClick}
          disableRipple
        >
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientUpdateSSNDobForm;
