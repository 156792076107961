import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FormSnippetsEditSection from './FormSnippetsEditSection';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0px 16px 0px',
  },
}));

const FormSnippetsView = () => {
  const { classes } = useStyles();
  const firmId = localStorage.getItem('firmId');

  return (
    <Box className={classes.container}>{firmId && <FormSnippetsEditSection firmId={firmId} />}</Box>
  );
};

export default FormSnippetsView;
