import { makeStyles } from 'tss-react/mui';

import { Box, Theme } from '@mui/material';

const useStyles = makeStyles<{ fontSize: string }>()((theme: Theme, { fontSize }) => ({
  defaultButton: {
    padding: '10px 32px',
    background: theme.palette.info.main,
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize,
    color: theme.palette.common.white,
    maxWidth: 'max-content',
    boxSizing: 'border-box',
    height: '48px',
    alignItems: 'center',
    display: 'flex',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface RequestDemoButtonProps {
  handleOpenPopup: () => void;
  fontSize: string;
}

const RequestDemoButton = ({ handleOpenPopup, fontSize }: RequestDemoButtonProps) => {
  const styles = useStyles({ fontSize });

  return (
    <Box onClick={handleOpenPopup} className={styles.classes.defaultButton}>
      Request a Demo
    </Box>
  );
};

export default RequestDemoButton;
