import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as WelcomeHeroTwoIcon } from '../assets/welcome-hero-2.svg';

import Header from '../Header';
import Footer from '../Footer';

import useWindowWidth from '../../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  pageMainContainer: {
    height: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19) 3.19%, rgba(37, 157, 168, 0.00) 37.39%)',
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#283645',
    fontFamily: 'Inter',
  },
  footerBackground: {
    width: '100%',
    background:
      'linear-gradient(207deg, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0.00), #282B3A)',
    backgroundColor: '#282B3A',

    '@media (max-width: 768px)': {
      paddingTop: '96px',
    },
  },
  pageHeadlineBlock: {
    display: 'flex',
    maxWidth: '1200px',
    margin: '0 auto',
    marginBottom: '66px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    padding: '0 20px',

    '@media (max-width: 768px)': {
      margin: '48px 0',
    },
  },
  pageHeadlineText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: '72px',
    color: '#283645',
    textAlign: 'center',
    maxWidth: '500px',

    '@media (max-width: 900px)': {
      maxWidth: 'none',
    },

    '@media (max-width: 768px)': {
      fontSize: '36px',
      lineHeight: '120%',
    },
  },
  policyPageContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1200px',
    gap: '40px',
    margin: '0 auto',
    padding: '0 20px',
    paddingBottom: '40px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#5D6068',

    strong: {
      fontWeight: 600,
      color: '#283645',
    },

    h2: {
      fontWeight: 600,
      color: '#283645',
    },

    h3: {
      fontWeight: 600,
      color: '#283645',
    },

    a: {
      color: '#00667C',
      textDecoration: 'none',
    },
  },
}));

const DataProcessingAbbendumView = () => {
  const styles = useStyles();
  const windowWidth = useWindowWidth();

  const [isRequestDemoPopupOpen, setIsRequestDemoPopupOpen] = useState<boolean>(false);

  const handleClosePopup = () => setIsRequestDemoPopupOpen(false);
  const handleOpenPopup = () => setIsRequestDemoPopupOpen(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window]);

  return (
    <Box className={styles.classes.pageMainContainer}>
      <Header
        windowWidth={windowWidth}
        handleOpenPopup={handleOpenPopup}
        isRequestDemoPopupOpen={isRequestDemoPopupOpen}
        handleClosePopup={handleClosePopup}
      />

      <Box className={styles.classes.pageHeadlineBlock}>
        {windowWidth > 900 && <WelcomeHeroTwoIcon />}
        <Typography className={styles.classes.pageHeadlineText}>
          Data Processing Addendum (“DPA”)
        </Typography>
      </Box>

      <Box className={styles.classes.policyPageContentWrapper}>
        <main>
          <section>
            <h3>This Data Processing Addendum forms part of the Agreement.</h3>
            <ol
              type="1"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <li>
                <strong>Definitions.</strong> Unless otherwise set out in this DPA, any capitalized
                terms not defined in this DPA shall have the respective meanings given to them in
                the Agreement.
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  <strong>“Customer Personal Data”</strong> means personal data or personal
                  information (as defined in Data Protection Laws) contained within Customer Data.
                </li>
                <li>
                  <strong>“Data Protection Laws”</strong> means all laws relating to the use,
                  protection and privacy of Customer Personal Data (including, without limitation,
                  the privacy of electronic communications) which are from time to time applicable
                  to Customer, V, or the Services.
                </li>
                <li>
                  <strong>“Individual”</strong> means an individual who is the subject of Customer
                  Personal Data (or to whom the Customer Personal Data relates).
                </li>
                <li>
                  <strong>“Individual Request”</strong> means a request made by an Individual to
                  exercise a right conferred on them in relation to Customer Personal Data by Data
                  Protection Laws.
                </li>
                <li>
                  <strong>“Security Incident”</strong> means a breach of security leading to the
                  accidental, unlawful or unauthorized loss or disclosure of Customer Personal Data.
                </li>
                <li>
                  <strong>“Sub-processor”</strong> means any sub-contractor engaged by V that agrees
                  to receive from V any Customer Personal Data.
                </li>
              </ol>
              <li>
                <strong>Data Processing.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  V will only process Customer Personal Data in accordance with: (i) the Agreement,
                  to the extent necessary to provide the Services; and (ii) the Customer’s written
                  instructions, unless required by applicable laws.
                </li>
                <li>
                  The Agreement (subject to any changes to the Services agreed between the parties),
                  including this DPA, shall be the Customer’s complete and final instructions to V
                  in relation to the processing of Customer Personal Data.
                </li>
                <li>
                  Customer is responsible for ensuring that all individuals who provide written
                  instructions to V are authorized by Customer to issue instructions to V.
                </li>
                <li>
                  Customer is solely responsible for its compliance with the Data Protection Laws,
                  including without limitation the lawfulness of any transfer of personal data to V
                  and any subsequent use required to provide the Services.
                </li>
                <li>
                  Customer shall have sole responsibility for the accuracy, quality, and legality of
                  Customer Personal Data and the means by which Customer acquired or obtained the
                  Customer Personal Data, including providing any required notices, or obtaining any
                  required consents, to Individuals.
                </li>
                <li>
                  Customer takes full responsibility to keep the amount of Customer Personal Data
                  provided to V to the minimum necessary for V to administrate the contractual
                  relationship and to provide Customer with the Services.
                </li>
              </ol>
              <li>
                <strong>CCPA.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  The parties acknowledge and agree that V is a service provider for the purposes of
                  the California Consumer Privacy Act of 2018 (<strong>“CCPA”</strong>) (to the
                  extent it applies) and is receiving Customer Personal Data in order to provide the
                  Services pursuant to the Agreement, which constitutes a business purpose.
                </li>
                <li>
                  V shall not sell any Customer Personal Data and V shall not retain, use or
                  disclose any Customer Personal Data except as necessary for the purpose of
                  performing the Services for Customer pursuant to the Agreement, or otherwise as
                  set forth in the Agreement or as permitted by the CCPA.
                </li>
              </ol>
              <li>
                <strong>Sub-Processors.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  Customer agrees that V may engage Sub-processors to process Customer Personal Data
                  in accordance with this DPA in connection with providing the Services.
                </li>
                <li>
                  Customer acknowledges that a list of Sub-processors can be found at this link:{' '}
                  <a href="#">V Sub-processor List</a>. V may update such Sub-processors list from
                  time to time as required to provide the Services which Customer is encouraged to
                  review periodically.
                </li>
                <li>
                  When engaging Sub-processors, V shall enter into agreements with the
                  Sub-processors to bind them to obligations which are substantially similar to
                  those set out in this DPA.
                </li>
              </ol>
              <li>
                <strong>Data Security.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  V will implement the technical and organizational measures set out in Annex 1 to
                  ensure a level of security appropriate to the risk posed by the processing of
                  Customer Personal Data.
                </li>
                <li>
                  V may update such measures from time to time to reflect changes in operations,
                  practices and any new or increasing risks provided that the level of security
                  shall not be reduced or diminished in any way.
                </li>
                <li>
                  V shall notify Customer without undue delay upon becoming aware of a Security
                  Incident, and shall provide Customer with reasonable assistance to allow Customer
                  to notify Individuals or applicable regulatory authorities of the Security
                  Incident where required by applicable Data Protection Laws.
                </li>
              </ol>
              <li>
                <strong>Audits.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  V will provide reasonable information to help Customer to assess V’s compliance
                  with its obligations in this DPA and, save as expressly and specifically mandated
                  by Data Protection Laws, no audits are allowed within a data center for security
                  and compliance reasons.
                </li>
                <li>
                  As specifically required by applicable Data Protection Laws and subject to the
                  below, only a legally mandated entity (such as a governmental regulatory agency
                  having oversight of Customer’s operations) may conduct an on-site visit of the
                  facilities used to provide the Services.
                </li>
                <li>
                  After conducting an audit under this Section 6 or after receiving a V report under
                  this Section 6, Customer must notify V of the specific manner, if any, in which V
                  does not comply with any of the data protection obligations in this DPA, if
                  applicable.
                </li>
                <li>
                  Any information provided by V under this Section 6 will be deemed Confidential
                  Information of V.
                </li>
                <li>
                  Customer may not audit V’s Sub-processors without V’s and V’s Sub-processor’s
                  prior agreement and, in relation to its Sub-processors, V will only exercise its
                  audit rights (pursuant to meeting any applicable requirements under Data
                  Protection Laws) to the extent to what it has agreed with its Sub-processors.
                </li>
                <li>
                  Without prejudice to the foregoing, Customer agrees its requests to audit
                  Sub-processors may be satisfied by V or V’s Sub-processors presenting up-to-date
                  attestations, reports or extracts from independent bodies, including without
                  limitation external or internal auditors, V’s data protection officer or IT
                  security personnel, data protection or quality auditors, or other mutually
                  agreed-to third parties, or certification by way of an IT security or data
                  protection audit.
                </li>
                <li>
                  On-site audits at Sub-processors’ premises may be performed by V acting on behalf
                  of Customer (though V is not under an obligation to do so).
                </li>
                <li>
                  Save as otherwise required by Data Protection Laws, Customer may request a summary
                  audit report(s) or audit V no more than once annually provided that Customer
                  provides at least six (6) weeks’ prior written notice to V of a request for
                  summary audit report(s) or request to audit.
                </li>
                <li>
                  he Customer agrees that the scope of any audit will be limited to V’s policies,
                  procedures and controls relevant to the protection of Customer Personal Data.
                </li>
                <li>
                  Save as otherwise required by Data Protection Laws, all audits will be conducted
                  during normal business hours, at V’s principal place of business or other V
                  location(s) where Customer Personal Data is accessed, processed or administered,
                  and will not unreasonably interfere with V’s day-to-day operations.
                </li>
                <li>
                  The Customer agrees that any audit will be conducted at Customer’s sole cost and
                  by a mutually agreed upon third party who is engaged and paid by Customer, and is
                  under a non-disclosure agreement containing confidentiality provisions
                  substantially similar to those set forth in the Agreement, obligating it to
                  maintain the confidentiality of all V Confidential Information and all audit
                  findings.
                </li>
                <li>
                  Before the commencement of any such on-site audit, V and Customer shall mutually
                  agree upon the timing, scope, and duration of the audit and V will reasonably
                  cooperate with the audit, including providing auditor the right to review but not
                  to copy V security information or materials during normal business hours.
                </li>
                <li>
                  Customer shall, at no charge, provide to V a full copy of all findings of such
                  audit.
                </li>
              </ol>
              <li>
                <strong>V Personnel.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  V shall ensure it has in place written agreements with its personnel to maintain
                  the confidentiality of Customer Personal Data.
                </li>
                <li>
                  V shall use commercially reasonable efforts to limit access to Customer Personal
                  Data to those personnel who require such access to perform the Agreement.
                </li>
              </ol>
              <li>
                <strong>Individual Rights.</strong>
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  Customer shall respond to inquiries from Individuals and from applicable
                  regulatory authorities concerning the processing of the Customer Personal Data,
                  and will alert V of any inquiries from Individuals or from applicable regulatory
                  authorities that relate to V’s processing of the Customer Personal Data.
                </li>
                <li>
                  V shall, save as required (or where prohibited) under applicable law, promptly
                  notify Customer if it receives an Individual Request and, to the extent applicable
                  and insofar as possible, V shall provide Customer with commercially reasonable
                  cooperation and assistance as is necessary for Customer to comply with its
                  obligations under the Data Protection Laws in relation to any such Individual
                  Request.
                </li>
                <li>
                  Customer shall use its best efforts to respond to and resolve promptly all
                  Individual Requests which V provides to Customer.
                </li>
                <li>
                  To the extent legally permitted, Customer shall be responsible for any reasonable
                  costs arising from V’s provision of assistance under this Section 8.
                </li>
              </ol>
              <li>
                <strong>Data Deletion.</strong> Unless otherwise required by applicable laws to
                which V or its Sub-processors are subject, Customer Personal Data will be deleted at
                the same time and manner in which Customer Data is deleted pursuant to the
                Agreement.
              </li>
            </ol>
          </section>
          <section>
            <h2>Annex 1</h2>
            <h3>Technical and Organizational Security Measures</h3>
            <ol
              type="1"
              style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <li>
                V maintains internal policies and procedures, and procures that its Sub-processors
                do so, which are designed to:
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  secure any Customer Personal Data processed by V against accidental or unlawful
                  loss, access or disclosure;
                </li>
                <li>
                  identify reasonably foreseeable and internal risks to security and unauthorized
                  access to the Customer Personal Data processed by V; and
                </li>
                <li>
                  minimize security risks, including through risk assessment and regular testing.
                </li>
              </ol>
              <li>
                V will, and will use reasonable efforts to procure that its Sub-processors
                periodically will:
              </li>
              <ol
                type="a"
                style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <li>
                  conduct periodic reviews of the security of its network and the adequacy of its
                  information security program as measured against industry security standards and
                  its policies and procedures; and
                </li>
                <li>
                  evaluate the security of its network and associated services to determine whether
                  additional or different security measures are required to respond to new security
                  risks or findings generated by the periodic reviews.
                </li>
              </ol>
            </ol>
          </section>
        </main>
      </Box>

      <Box className={styles.classes.footerBackground}>
        <Footer />
      </Box>
    </Box>
  );
};

export default DataProcessingAbbendumView;
