import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { ROWS_PER_PAGE_OPTIONS } from '../constants/constants';

export const PaginationContext = createContext<PaginationContextProps>({
  totalCount: 0,
  totalPages: 0,
  pageNumber: 1,
  rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  onChangePageNumber: undefined,
  onChangeRowsPerPage: undefined,
  setTotalCount: undefined,
  setPageNumber: undefined,
});

interface PaginationContextProps {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  rowsPerPage: number;
  setTotalCount?: Dispatch<SetStateAction<number>>;
  onChangePageNumber?: (_: React.ChangeEvent<unknown>, value: number) => void;
  onChangeRowsPerPage?: (event: SelectChangeEvent<number>) => void;
  setPageNumber?: Dispatch<SetStateAction<number>>;
}

interface PaginationProviderProps {
  children: JSX.Element;
}

const PaginationProvider = ({ children }: PaginationProviderProps) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const onChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(Number(event.target.value));
    setPageNumber(1);
  };

  const onChangePageNumber = (_: React.ChangeEvent<unknown>, value: number) => setPageNumber(value);

  const value = {
    totalCount,
    totalPages,
    pageNumber,
    rowsPerPage,
    onChangePageNumber,
    onChangeRowsPerPage,
    setTotalCount,
    setPageNumber,
  };

  useEffect(() => {
    setTotalPages &&
      setTotalPages(totalCount < pageNumber ? 1 : Math.ceil(totalCount / rowsPerPage));
  }, [totalCount, pageNumber, rowsPerPage]);

  return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>;
};

export default PaginationProvider;
