import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { QuestionnaireSection } from '../../../types';

import SelectButton from './SelectButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
  },
}));

interface SectionListProps {
  readonly data: QuestionnaireSection[];
  readonly selectedSections: QuestionnaireSection[];
  readonly handleSelect: (item: QuestionnaireSection) => void;
  readonly handleSelectAll: () => void;
  readonly handleUnselect: (item: QuestionnaireSection) => void;
  readonly handleUnselectAll: () => void;
}

const SectionList = ({
  data,
  selectedSections,
  handleSelect,
  handleSelectAll,
  handleUnselect,
  handleUnselectAll,
}: SectionListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <SelectButton
        buttonText={'Select All'}
        checked={data.length === selectedSections.length}
        onClick={() => {
          if (data.length === selectedSections.length) handleUnselectAll();
          else handleSelectAll();
        }}
      />
      {data.map((section) => {
        const checked = selectedSections.some((el) => el.items[0].id === section.items[0].id);

        return (
          <SelectButton
            key={section.items[0].id}
            checked={checked}
            buttonText={section.title}
            onClick={() => {
              if (checked) handleUnselect(section);
              else handleSelect(section);
            }}
          />
        );
      })}
    </Box>
  );
};

export default SectionList;
