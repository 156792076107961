import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams, ResultSet } from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import {
  FirmTrustAccountQueryData,
  FirmTrustAccountQueryShortData,
} from '../../views/FirmBankingView/types';

const BASE_URL = '/v1';
const BASE_URL_V2 = '/v2';

export const getFirmTrustAccounts = async (
  { size, page, count, activeSortField }: PaginationQueryParams,
  firmId: string,
) => {
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);
  const targetUrl = `${BASE_URL_V2}/firms/${firmId}/banking/trust-accounts?size=${size}&page=${page}&count=${count}${sortFilters}`;

  return await api.get<ResultSet<FirmTrustAccountQueryShortData>>(targetUrl);
};

export const createFirmTrustAccount = async (
  firmId: number,
  data: {
    accountNumber: string;
    routingNumber: string;
    name: string;
    isVerified: boolean;
  },
) => {
  const targetUrl = `${BASE_URL_V2}/firms/${firmId}/banking/trust-accounts`;

  return await api.post<FirmTrustAccountQueryData>(targetUrl, data);
};

export const getFirmTrustAccount = async (id: number) => {
  const targetUrl = `${BASE_URL_V2}/trust-accounts/${id}`;

  return await api.get<FirmTrustAccountQueryData>(targetUrl);
};

export const updateFirmTrustAccount = async (
  id: number,
  data: {
    accountNumber?: string;
    routingNumber?: string;
    name?: string;
    isVerified?: boolean;
  },
) => {
  const targetUrl = `${BASE_URL_V2}/trust-accounts/${id}`;

  return await api.put<FirmTrustAccountQueryData>(targetUrl, data);
};

export const deleteFirmTrustAccount = async (id: number) => {
  const targetUrl = `${BASE_URL}/trust-accounts/${id}`;

  return await api.delete<void>(targetUrl);
};
