import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { DefendantFormSubmitProps } from './types';
import { createDefendantRecord } from '../../api/defendantsApi/defendantsApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormTextField from '../../components/formFields/FormTextField';

import { DEFENDANT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { defendantFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    padding: '32px',
    flexBasis: '416px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 550px)': {
      padding: '12px',
      flexBasis: '100%',
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexBasis: '416px',
    flexDirection: 'column',

    '@media (max-width: 550px)': {
      flexBasis: '100%',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const DefendantsCreateFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();
  const firmId = localStorage.getItem('firmId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(defendantFormSchema),
    defaultValues: { ...DEFENDANT_FORM_DEFAULT_VALUES, firmId: firmId || '' },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmitForm = (values: DefendantFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      referenceId: values.referenceId,
      name: values.name,
      firmId: values.firmId,
    };

    createDefendantRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/defendants/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Defendant account created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.formContainer}>
            <Box display="flex">
              <Box className={styles.classes.formInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                <Box mt="16px">
                  <FormTextField name="referenceId" label="Reference Id *" />
                </Box>
                <Box mt="24px">
                  <FormTextField name="name" label="Name *" />
                </Box>
              </Box>
            </Box>

            <Box display="flex" mt="32px" flexDirection="column" gap="16px">
              <FormSubmitButton
                disabled={!isValid || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default DefendantsCreateFormContainer;
