import { useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { accountDeletionRequestFormSchema } from '../../helpers/formSchemas/formSchemas';
import { ACCOUNT_DELETION_REQUEST_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { requestAccountDeletion } from '../../api/requestAccountDeletionApi';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: '32px',
    maxWidth: '480px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    width: '100%',
  },
  formTitle: {
    fontSize: '28px',
    lineHeight: '120%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'normal',
    padding: 0,
    marginBottom: '40px',
  },
  formContentText: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    marginBottom: '20px',
  },
  formList: {
    fontSize: '16px',
    lineHeight: '120%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}));

interface AccountDeletionRequestFormSubmitProps {
  email: string;
}

const AccountDeletionRequestForm = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const navigate = useNavigate();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(accountDeletionRequestFormSchema),
    defaultValues: ACCOUNT_DELETION_REQUEST_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit = (values: AccountDeletionRequestFormSubmitProps) => {
    setIsFormSubmitting(true);
    requestAccountDeletion(values)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          type: 'success',
          message: 'Account deletion instructions have been sent.',
        }));
        navigate('/');
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          type: 'error',
          message: err.response.data.message,
        }));
      })
      .finally(() => setIsFormSubmitting(false));
  };

  return (
    <Box className={classes.formContainer}>
      <Typography className={classes.formTitle}>Data Safety</Typography>
      <Typography className={classes.formContentText}>
        At v. we take your privacy and the security of you data very seriously. You have the right
        at any time to request deletion of your v. app account and the personally-identifiable data
        associated with that account:
      </Typography>

      <ul className={classes.formList}>
        <li>Your name</li>
        <li>Your email address</li>
        <li>Your mailing address</li>
        <li>Your mobile phone</li>
      </ul>

      <Typography className={classes.formContentText}>
        Submit your email address to verify your identity and begin the account deletion process.
        You will receive an email at this address to confirm your request.
      </Typography>

      <Typography className={classes.formContentText}>
        {
          'Alternatively, you can delete your account directly within the v. app by navigating to Account > Profile > Delete Account.'
        }
      </Typography>

      <FormProvider {...formMethods}>
        <Box display="flex" flexDirection="column" gap="30px">
          <FormTextField name="email" label="Email Address" />

          <FormSubmitButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isFormSubmitting}
            buttonText="Submit"
            fontFamily="Inter"
          />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AccountDeletionRequestForm;
