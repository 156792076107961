import { makeStyles } from 'tss-react/mui';

import { Box } from '@mui/material';

import FormTextField from '../../../components/formFields/FormTextField';
import FormMaskedTextField from '../../../components/formFields/FormMaskedTextField';
import FormCheckboxField from '../../../components/formFields/FormCheckboxField';

const useStyles = makeStyles()(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '416px',
    width: '100%',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    padding: '32px 0 32px 0',
  },
  checkboxWrapper: {
    width: '100%',

    '& label': {
      marginRight: 0,
    },
  },
}));

const FirmTrustAccountForm = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formFieldsWrapper}>
        <FormTextField name="name" label="Name" />
        <FormTextField name="accountNumber" label="Account Number" />
        <FormMaskedTextField mask="999999999" name="routingNumber" label="Routing Number (ACH)" />
        <Box className={classes.checkboxWrapper}>
          <FormCheckboxField name="isVerified" label="Verified" />
        </Box>
      </Box>
    </Box>
  );
};

export default FirmTrustAccountForm;
